import AWS from 'aws-sdk';
const OPENCAMPGROUND_DATABASE_CALL_BASE_URL = " https://lzio69unu5.execute-api.us-east-2.amazonaws.com/dafault/opcDatabaseCalls"
AWS.config.update({
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
    region: process.env.REACT_APP_AWS_REGION
});

const lambda = new AWS.Lambda();

//reservations
export const getReservations = async (serverName) => {
    const requestBody = {
        'operationName': 'getReservations',
        'payload': {
            "serverName": serverName,
        }
    };

    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody)
    };

    try {
        let response = await fetch(OPENCAMPGROUND_DATABASE_CALL_BASE_URL, options);
        let json = await response.json()

        if (json.statusCode === 200) {
            return json.body;
        }
    } catch (err) {
        console.error('Error while retrieving reservations.', err);
    }
}

export const getReservationsByReservationNumber = async (serverName, reservation_id) => {
    const requestBody = {
        'operationName': 'getReservationsByReservationNumber',
        'payload': {
            "serverName": serverName,
            "reservation_id": reservation_id
        }
    };

    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody)
    };

    try {
        let response = await fetch(OPENCAMPGROUND_DATABASE_CALL_BASE_URL, options);
        let json = await response.json()

        if (json.statusCode === 200) {
            return json.body;
        }
    } catch (err) {
        console.error('Error while retrieving reservation by id.', err);
    }
}

export const getReservationsByName = async (serverName, firstName) => {
    const requestBody = {
        'operationName': 'getReservationsByName',
        'payload': {
            "serverName": serverName,
            "firstName": firstName
        }
    };

    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody)
    };

    try {
        let response = await fetch(OPENCAMPGROUND_DATABASE_CALL_BASE_URL, options);
        let json = await response.json()

        if (json.statusCode === 200) {
            return json.body;
        }
    } catch (err) {
        console.error('Error while retrieving reservation by name.', err);
    }
}

//transactions
export const getTransactions = async (serverName) => {
    const requestBody = {
        'operationName': 'getTransactions',
        'payload': {
            "serverName": serverName,
        }
    };

    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody)
    };

    try {
        let response = await fetch(OPENCAMPGROUND_DATABASE_CALL_BASE_URL, options);
        let json = await response.json()

        if (json.statusCode === 200) {
            return json.body;
        }
    } catch (err) {
        console.error('Error while retrieving transactions.', err);
    }
}

export const getTransactionsByReservationId = async (serverName, reservation_id) => {
    const requestBody = {
        'operationName': 'getTransactionsByReservationId',
        'payload': {
            "serverName": serverName,
            "reservation_id": reservation_id
        }
    };

    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody)
    };

    try {
        let response = await fetch(OPENCAMPGROUND_DATABASE_CALL_BASE_URL, options);
        let json = await response.json()

        if (json.statusCode === 200) {
            return json.body;
        }
    } catch (err) {
        console.error('Error while retrieving transaction by id.', err);
    }
}

export const getTransactionsByLast4 = async (serverName, last4) => {
    const requestBody = {
        'operationName': 'getTransactionsByLast4',
        'payload': {
            "serverName": serverName,
            "last4": last4
        }
    };

    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody)
    };

    try {
        let response = await fetch(OPENCAMPGROUND_DATABASE_CALL_BASE_URL, options);
        let json = await response.json()

        if (json.statusCode === 200) {
            return json.body;
        }
    } catch (err) {
        console.error('Error while retrieving transaction by last4.', err);
    }
}

// Sites Rigs Discounts Extras
export const getSiteRigDiscountExtra = async (serverName) => {
    const requestBody = {
        'operationName': 'getSiteRigDiscountExtra',
        'payload': {
            "serverName": serverName,
        }
    };

    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody)
    };

    try {
        let response = await fetch(OPENCAMPGROUND_DATABASE_CALL_BASE_URL, options);
        let json = await response.json()

        if (json.statusCode === 200) {
            return json.body;
        }
    } catch (err) {
        console.error('Error while retrieving getSiteRigDiscountExtra.', err);
    }
}

export const getSpaces = async (serverName, endDate, startDate) => {
    const requestBody = {
        'operationName': 'getSpaces',
        'payload': {
            "serverName": serverName,
            "endDate": endDate,
            "startDate": startDate
        }
    };

    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody)
    };

    try {
        let response = await fetch(OPENCAMPGROUND_DATABASE_CALL_BASE_URL, options);
        let json = await response.json()

        if (json.statusCode === 200) {
            return json.body;
        }
    } catch (err) {
        console.error('Error while retrieving getSiteRigDiscountExtra.', err);
    }
}

export const getTaxes = async (servername) => {
    const requestBody = {
        'operationName': 'getTaxes',
        'payload': {
            "serverName": servername,
        }
    };

    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody)
    };

    try {
        let response = await fetch(OPENCAMPGROUND_DATABASE_CALL_BASE_URL, options);
        let json = await response.json()

        if (json.statusCode === 200) {
            return json.body;
        }
    } catch (err) {
        console.error('Error while retrieving taxes.', err);
    }
}

export const checkOut = async (serverName, reservation_id) => {
    const requestBody = {
        'operationName': 'checkOut',
        'payload': {
            "serverName": serverName,
            "reservation_id": reservation_id
        }
    };

    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody)
    };

    try {
        let response = await fetch(OPENCAMPGROUND_DATABASE_CALL_BASE_URL, options);
        let json = await response.json()

        if (json.statusCode === 200) {
            return json.body;
        }
    } catch (err) {
        console.error('Error while checking out customer.', err);
    }
}

export const checkIn = async (serverName, reservation_id) => {
    const requestBody = {
        'operationName': 'checkIn',
        'payload': {
            "serverName": serverName,
            "reservation_id": reservation_id
        }
    };

    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody)
    };

    try {
        let response = await fetch(OPENCAMPGROUND_DATABASE_CALL_BASE_URL, options);
        let json = await response.json()

        if (json.statusCode === 200) {
            return json.body;
        }
    } catch (err) {
        console.error('Error while checking In customer.', err);
    }
}

export const cancel = async (serverName, reservation_id) => {
    const requestBody = {
        'operationName': 'cancel',
        'payload': {
            "serverName": serverName,
            "reservation_id": reservation_id
        }
    };

    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody)
    };

    try {
        let response = await fetch(OPENCAMPGROUND_DATABASE_CALL_BASE_URL, options);
        let json = await response.json()

        if (json.statusCode === 200) {
            return json.body;
        }
    } catch (err) {
        console.error('Error while adding paymentType', err);
    }
}

export const makeReservation = async (serverName, lname, fname, address, city, state, zip, phone, email, rigTypeId, spaceId, discountID, startDate, endDate,
    deposit, slides, length, adults, pets, total, createdAt, taxAmount, remote, notes) => {
    const requestBody = {
        'operationName': 'createReservation',
        'payload': {
            "serverName": serverName,
            "lname": lname,
            "fname": fname,
            "address": address,
            "city": city,
            "state": state,
            "zip": zip,
            "phone": phone,
            "email": email,
            "rigTypeId": rigTypeId,
            "spaceId": spaceId,
            "discountID": discountID,
            "startDate": startDate,
            "endDate": endDate,
            "deposit": deposit,
            "slides": slides,
            "len": length,
            "adults": adults,
            "pets": pets,
            "total": total,
            "createdAt": createdAt,
            "taxAmount": taxAmount,
            "remote": remote,
            "notes": notes
        }
    };

    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody)
    };

    try {
        console.log("Payload: ", requestBody)
        let response = await fetch(OPENCAMPGROUND_DATABASE_CALL_BASE_URL, options);
        let json = await response.json()
        if (json.statusCode === 200) {
            return json.body;
        }
    } catch (err) {
        console.error('Error while making reservation.', err);
    }
}

export const getCardType = async (cardType) => {
    if (cardType === 'Visa' || cardType === 'V') {
        return (2);
    } else if (cardType === 'MasterCard' || cardType === 'M') {
        return (8);
    } else if (cardType === 'Discover' || cardType === 'D') {
        return (9);
    } else if (cardType === 'American Express' || cardType === 'A') {
        return (10);
    }
}

export const recordPayment = async (serverName, amount, reservationNumber, createdAt, updatedAt, memo, ccId, last4, nameOnCard, pmtDate, refundable) => {
    const requestBody = {
        'operationName': 'recordPayment',
        'payload': {
            "serverName": serverName,
            "amount": amount,
            "reservationNumber": reservationNumber,
            "createdAt": createdAt,
            "updatedAt": updatedAt,
            "memo": memo,
            "ccId": ccId,
            "last4": last4,
            "nameOnCard": nameOnCard,
            "pmtDate": pmtDate,
            "refundable": refundable
        }
    };

    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody)
    };

    try {
        let response = await fetch(OPENCAMPGROUND_DATABASE_CALL_BASE_URL, options);
        let json = await response.json()

        if (json.statusCode === 200) {
            return json.body;
        }
    } catch (err) {
        console.error('Error while making reservation.', err);
    }
}

export const getRemoteReservationsStatus = async (serverName) => {
    const requestBody = {
        'operationName': 'getRemoteReservationsStatus',
        'payload': {
            "serverName": serverName
        }
    };

    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody)
    };

    try {
        let response = await fetch(OPENCAMPGROUND_DATABASE_CALL_BASE_URL, options);
        let json = await response.json()

        if (json.statusCode === 200) {
            return json.body;
        }
    } catch (err) {
        console.error('Error while getting remote reservations status', err);
    }
}

export const updateRemoteReservationsStatus = async (serverName, useRemote, config) => {
    let allow, require;

    if (config === 'require') {
        allow = '0';
        require = '1';
    } else if (config === 'allow') {
        allow = '1';
        require = '0';
    } else if (config === 'doNotAllow') {
        allow = '0';
        require = '0';
    }
    const requestBody = {
        'operationName': 'updateRemoteReservationsStatus',
        'payload': {
            "serverName": serverName,
            "useRemote": useRemote,
            "allow": allow,
            "require": require
        }
    };

    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody)
    };

    try {
        let response = await fetch(OPENCAMPGROUND_DATABASE_CALL_BASE_URL, options);
        let json = await response.json()

        if (json.statusCode === 200) {
            return json.body;
        }
    } catch (err) {
        console.error('Error while updating remote reservations status', err);
    }
}

export const updateTransaction = async (serverName, id) => {
    const requestBody = {
        'operationName': 'updateTransaction',
        'payload': {
            "serverName": serverName,
            "id": id
        }
    };

    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody)
    };

    try {
        let response = await fetch(OPENCAMPGROUND_DATABASE_CALL_BASE_URL, options);
        let json = await response.json()

        if (json.statusCode === 200) {
            return json.body;
        }
    } catch (err) {
        console.error('Error while updating transaction', err);
    }
}

//cardConnect API
export const cardConnectPaymemt = async (merchantId, amount, cardNumber, expiryMonth, expiryYear, cvv, zipCode, address, name, city) => {
    const requestBody = {
        'operationName': 'cardConnectPaymemt',
        'payload': {
            "merchid": merchantId,
            "account": cardNumber,
            "expiry": expiryMonth.toString().padStart(2, '0') + expiryYear.toString(),
            "cvv2": cvv,
            "postal": zipCode,
            "ecomind": "E",
            "amount": amount,
            "name": name,
            "address": address,
            "city": city,
            "capture": "Y",
            "bin": "Y"
        }
    };

    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody)
    };

    try {
        let response = await fetch(OPENCAMPGROUND_DATABASE_CALL_BASE_URL, options);
        let json = await response.json()
        return json;
    } catch (err) {
        console.error('Error while making Card Connect Payment', err);
    }
}

export const cardConnectInquire = async (merchantId, referenceNumber) => {
    const requestBody = {
        'operationName': 'cardConnectInquire',
        'payload': {
            "merchid": merchantId,
            "referenceNumber": referenceNumber
        }
    };

    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody)
    };

    try {
        let response = await fetch(OPENCAMPGROUND_DATABASE_CALL_BASE_URL, options);
        let json = await response.json()
        return json;
    } catch (err) {
        console.error('Error while making Card Connect inquiry', err);
    }
}

export const cardConnectVoid = async (merchantId, referenceNumber) => {
    const requestBody = {
        'operationName': 'cardConnectVoid',
        'payload': {
            "merchid": merchantId,
            "retref": referenceNumber
        }
    };

    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody)
    };

    try {
        let response = await fetch(OPENCAMPGROUND_DATABASE_CALL_BASE_URL, options);
        let json = await response.json()
        return json;
    } catch (err) {
        console.error('Error while making Card Connect inquiry', err);
    }
}

export const cardConnectRefund = async (merchantId, referenceNumber, amount) => {
    const requestBody = {
        'operationName': 'cardConnectRefund',
        'payload': {
            "merchid": merchantId,
            "retref": referenceNumber,
            "amount": amount
        }
    };

    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody)
    };

    try {
        let response = await fetch(OPENCAMPGROUND_DATABASE_CALL_BASE_URL, options);
        let json = await response.json()
        return json;
    } catch (err) {
        console.error('Error while making Card Connect inquiry', err);
    }
}

export const cardConnectGetListOfTerminals = async (merchantId) => {
    const requestBody = {
        'operationName': 'cardConnectGetListOfTerminals',
        'payload': {
            "merchantId": merchantId
        }
    };

    const params = {
        FunctionName: process.env.REACT_APP_AWS_FUNCTION_NAME,
        Payload: JSON.stringify(requestBody),
    };

    try {
        let data = await lambda.invoke(params).promise();
        let json = JSON.parse(data.Payload);
        if (data.StatusCode === 200) {
            return json;
        }
    } catch (err) {
        console.error('Error while retrieving reservations.', err);
    }
}

export const cardConnectConnectToTerminal = async (merchantId, hsn) => {
    const requestBody = {
        'operationName': 'cardConnectConnectToTerminal',
        'payload': {
            "hsn": hsn,
            "merchantId": merchantId,
            "force": true
        }
    };

    const params = {
        FunctionName: process.env.REACT_APP_AWS_FUNCTION_NAME,
        Payload: JSON.stringify(requestBody),
    };

    try {
        let data = await lambda.invoke(params).promise();

        let json = JSON.parse(data.Payload);
        if (data.StatusCode === 200) {
            return json;
        }
    } catch (err) {
        console.error('Error while retrieving reservations.', err);
    }
}

export const cardConnectTerminalPayment = async (merchantId, hsn, amount, skey) => {
    const requestBody = {
        'operationName': 'cardConnectTerminalPayment',
        'payload': {
            "sessionKey": skey,
            "hsn": hsn,
            "merchantId": merchantId,
            "amount": amount
        }
    };

    const params = {
        FunctionName: process.env.REACT_APP_AWS_FUNCTION_NAME,
        Payload: JSON.stringify(requestBody),
    };

    try {
        let data = await lambda.invoke(params).promise();
        let json = JSON.parse(data.Payload);
        if (data.StatusCode === 200) {
            return json;
        }
    } catch (err) {
        console.error('Error while retrieving reservations.', err);
    }
}

//settings API
export const deleteRecord = async (serverName, database, id) => {
    const requestBody = {
        'operationName': 'deleteRecord',
        'payload': {
            "serverName": serverName,
            "database": database,
            "id": id
        }
    };

    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody)
    };

    try {
        let response = await fetch(OPENCAMPGROUND_DATABASE_CALL_BASE_URL, options);
        let json = await response.json()

        if (json.statusCode === 200) {
            return json.body;
        }
    } catch (err) {
        console.error('Error while deleting', err);
    }
}

export const getSiteTypes = async (serverName) => {
    const requestBody = {
        'operationName': 'getSiteTypes',
        'payload': {
            "serverName": serverName
        }
    };

    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody)
    };

    try {
        let response = await fetch(OPENCAMPGROUND_DATABASE_CALL_BASE_URL, options);
        let json = await response.json()

        if (json.statusCode === 200) {
            return json.body;
        }
    } catch (err) {
        console.error('Error while getting site Types', err);
    }
}

export const updateSiteType = async (serverName, name, active, id) => {
    const requestBody = {
        'operationName': 'updateSiteTypes',
        'payload': {
            "serverName": serverName,
            "name": name,
            "active": active,
            "id": id
        }
    };

    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody)
    };

    try {
        let response = await fetch(OPENCAMPGROUND_DATABASE_CALL_BASE_URL, options);
        let json = await response.json()

        if (json.statusCode === 200) {
            return json.body;
        }
    } catch (err) {
        console.error('Error while editing site Types', err);
    }
}

export const getSeasons = async (serverName) => {
    const requestBody = {
        'operationName': 'getSeasons',
        'payload': {
            "serverName": serverName
        }
    };

    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody)
    };

    try {
        let response = await fetch(OPENCAMPGROUND_DATABASE_CALL_BASE_URL, options);
        let json = await response.json()

        if (json.statusCode === 200) {
            return json.body;
        }
    } catch (err) {
        console.error('Error while getting seasons', err);
    }
}

export const updateSeason = async (serverName, name, startDate, endDate, week, month, active, id) => {
    const requestBody = {
        'operationName': 'updateSeasons',
        'payload': {
            "serverName": serverName,
            "name": name,
            "startdate": startDate,
            "enddate": endDate,
            "weekly": week,
            "monthly": month,
            "active": active,
            "id": id
        }
    };

    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody)
    };

    try {
        let response = await fetch(OPENCAMPGROUND_DATABASE_CALL_BASE_URL, options);
        let json = await response.json()

        if (json.statusCode === 200) {
            return json.body;
        }
    } catch (err) {
        console.error('Error while editing seasons', err);
    }
}

export const getPrices = async (serverName) => {
    const requestBody = {
        'operationName': 'getPrices',
        'payload': {
            "serverName": serverName
        }
    };

    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody)
    };

    try {
        let response = await fetch(OPENCAMPGROUND_DATABASE_CALL_BASE_URL, options);
        let json = await response.json()

        if (json.statusCode === 200) {
            return json.body;
        }
    } catch (err) {
        console.error('Error while getting prices', err);
    }
}

export const getSettingSpaces = async (serverName) => {
    const requestBody = {
        'operationName': 'getSettingSpaces',
        'payload': {
            "serverName": serverName
        }
    };

    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody)
    };

    try {
        let response = await fetch(OPENCAMPGROUND_DATABASE_CALL_BASE_URL, options);
        let json = await response.json()

        if (json.statusCode === 200) {
            return json.body;
        }
    } catch (err) {
        console.error('Error while getting Spaces', err);
    }
}

export const updateSpace = async (serverName, name, power_15a, power_30a, power_50a, water, sewer, length, width, sitetype_id, price_id, remote_reservable, id, special_condition, active) => {
    const requestBody = {
        'operationName': 'updateSpace',
        'payload': {
            "serverName": serverName,
            "name": name,
            "power_15a": power_15a,
            "power_30a": power_30a,
            "power_50a": power_50a,
            "water": water,
            "sewer": sewer,
            "len": length,
            "width": width,
            "sitetype_id": sitetype_id,
            "price_id": price_id,
            "remote_reservable": remote_reservable,
            "id": id,
            "special_condition": special_condition,
            "active": active
        }
    };
    console.log(requestBody)
    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody)
    };

    try {
        let response = await fetch(OPENCAMPGROUND_DATABASE_CALL_BASE_URL, options);
        let json = await response.json()

        if (json.statusCode === 200) {
            return json.body;
        }
    } catch (err) {
        console.error('Error while editing spaces', err);
    }
}

export const getDiscount = async (serverName) => {
    const requestBody = {
        'operationName': 'getDiscount',
        'payload': {
            "serverName": serverName
        }
    };

    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody)
    };

    try {
        let response = await fetch(OPENCAMPGROUND_DATABASE_CALL_BASE_URL, options);
        let json = await response.json()

        if (json.statusCode === 200) {
            return json.body;
        }
    } catch (err) {
        console.error('Error while getting dicsount', err);
    }
}

export const updateDiscount = async (serverName, editingDiscountId, discountName, duration, discountPercent, amt, amtDay, amtWeek, amtMonth, sunday,
    monday, tuesday, wednesday, thursday, friday, saturday, daily, week, month, seasonal, showRemote, active) => {
    const requestBody = {
        'operationName': 'updateDiscount',
        'payload': {
            "serverName": serverName,
            "id": editingDiscountId,
            "discountName": discountName,
            "duration": duration,
            "discountPercent": discountPercent,
            "amt": amt,
            "amtDay": amtDay,
            "amtWeek": amtWeek,
            "amtMonth": amtMonth,
            "sunday": sunday,
            "monday": monday,
            "tuesday": tuesday,
            "wednesday": wednesday,
            "thursday": thursday,
            "friday": friday,
            "saturday": saturday,
            "daily": daily,
            "week": week,
            "month": month,
            "seasonal": seasonal,
            "showRemote": showRemote,
            "active": active
        }
    };

    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody)
    };

    try {
        let response = await fetch(OPENCAMPGROUND_DATABASE_CALL_BASE_URL, options);
        console.log(response)
        let json = await response.json()
        console.log(json)
        if (json.statusCode === 200) {
            console.log(json.body)
            return json.body;
        }
    } catch (err) {
        console.error('Error while updating dicsount', err);
    }
}

export const getRigTypes = async (serverName) => {
    const requestBody = {
        'operationName': 'getRigTypes',
        'payload': {
            "serverName": serverName
        }
    };

    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody)
    };

    try {
        let response = await fetch(OPENCAMPGROUND_DATABASE_CALL_BASE_URL, options);
        let json = await response.json()

        if (json.statusCode === 200) {
            return json.body;
        }
    } catch (err) {
        console.error('Error while getting rig types', err);
    }
}

export const updateRigType = async (serverName, name, active, id) => {
    const requestBody = {
        'operationName': 'updateRigTypes',
        'payload': {
            "serverName": serverName,
            "name": name,
            "active": active,
            "id": id
        }
    };

    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody)
    };

    try {
        let response = await fetch(OPENCAMPGROUND_DATABASE_CALL_BASE_URL, options);
        let json = await response.json()

        if (json.statusCode === 200) {
            return json.body;
        }
    } catch (err) {
        console.error('Error while editing rig Types', err);
    }
}

export const getTax = async (serverName) => {
    const requestBody = {
        'operationName': 'getTax',
        'payload': {
            "serverName": serverName
        }
    };

    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody)
    };

    try {
        let response = await fetch(OPENCAMPGROUND_DATABASE_CALL_BASE_URL, options);
        let json = await response.json()

        if (json.statusCode === 200) {
            return json.body;
        }
    } catch (err) {
        console.error('Error while getting tax', err);
    }
}

export const updateTax = async (serverName, id, name, percent, amount, isPercent, daily, weekly, monthly, seasonal, active) => {
    const requestBody = {
        'operationName': 'updateTax',
        'payload': {
            "serverName": serverName,
            "id": id,
            "name": name,
            "percent": percent,
            "amount": amount,
            "isPercent": isPercent,
            "daily": daily,
            "weekly": weekly,
            "monthly": monthly,
            "seasonal": seasonal,
            "active": active
        }
    };

    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody)
    };

    try {
        let response = await fetch(OPENCAMPGROUND_DATABASE_CALL_BASE_URL, options);
        let json = await response.json()

        if (json.statusCode === 200) {
            return json.body;
        }
    } catch (err) {
        console.error('Error while updating tax', err);
    }
}

export const getExtras = async (serverName) => {
    const requestBody = {
        'operationName': 'getExtras',
        'payload': {
            "serverName": serverName
        }
    };

    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody)
    };

    try {
        let response = await fetch(OPENCAMPGROUND_DATABASE_CALL_BASE_URL, options);
        let json = await response.json()

        if (json.statusCode === 200) {
            return json.body;
        }
    } catch (err) {
        console.error('Error while getting extra', err);
    }
}

export const updateExtra = async (serverName, id, name, remote, type, daily, week, month, rate, unit, charge, active) => {
    const requestBody = {
        'operationName': 'updateExtra',
        'payload': {
            "serverName": serverName,
            "id": id,
            "name": name,
            "remote": remote,
            "type": type,
            "daily": daily,
            "weekly": week,
            "monthly": month,
            "rate": rate,
            "unit": unit,
            "charge": charge,
            "active": active
        }
    };

    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody)
    };

    try {
        let response = await fetch(OPENCAMPGROUND_DATABASE_CALL_BASE_URL, options);
        let json = await response.json()

        if (json.statusCode === 200) {
            return json.body;
        }
    } catch (err) {
        console.error('Error while updating extra', err);
    }
}

export const getPaymentTypes = async (serverName) => {
    const requestBody = {
        'operationName': 'getPaymentTypes',
        'payload': {
            "serverName": serverName
        }
    };

    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody)
    };

    try {
        let response = await fetch(OPENCAMPGROUND_DATABASE_CALL_BASE_URL, options);
        let json = await response.json()

        if (json.statusCode === 200) {
            return json.body;
        }
    } catch (err) {
        console.error('Error while getting payment types', err);
    }
}

export const updatePaymentType = async (serverName, name, active, id) => {
    const requestBody = {
        'operationName': 'updatePaymentTypes',
        'payload': {
            "serverName": serverName,
            "name": name,
            "active": active,
            "id": id
        }
    };

    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody)
    };

    try {
        let response = await fetch(OPENCAMPGROUND_DATABASE_CALL_BASE_URL, options);
        let json = await response.json()

        if (json.statusCode === 200) {
            return json.body;
        }
    } catch (err) {
        console.error('Error while getting payment types', err);
    }
}

export const getEmails = async (serverName) => {
    const requestBody = {
        'operationName': 'getEmails',
        'payload': {
            "serverName": serverName
        }
    };

    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody)
    };

    try {
        let response = await fetch(OPENCAMPGROUND_DATABASE_CALL_BASE_URL, options);
        let json = await response.json()

        if (json.statusCode === 200) {
            return json.body[0];
        }
    } catch (err) {
        console.error('Error while getting mail settings', err);
    }
}

export const getEmailTemplates = async (serverName) => {
    const requestBody = {
        'operationName': 'getEmailTemplates',
        'payload': {
            "serverName": serverName
        }
    };

    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody)
    };

    try {
        let response = await fetch(OPENCAMPGROUND_DATABASE_CALL_BASE_URL, options);
        let json = await response.json()

        if (json.statusCode === 200) {
            return json.body;
        }
    } catch (err) {
        console.error('Error while getting mail templates', err);
    }
}

export const createNewSiteType = async (serverName, siteType) => {
    const requestBody = {
        'operationName': 'createNewSiteType',
        'payload': {
            "serverName": serverName,
            "siteType": siteType
        }
    };

    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody)
    };

    try {
        let response = await fetch(OPENCAMPGROUND_DATABASE_CALL_BASE_URL, options);
        let json = await response.json()

        if (json.statusCode === 200) {
            return json.body;
        }
    } catch (err) {
        console.error('Error while adding siteType', err);
    }
}

export const createNewSeason = async (serverName, seasonName, startDate, endDate, week, month) => {
    const requestBody = {
        'operationName': 'createNewSeason',
        'payload': {
            "serverName": serverName,
            "name": seasonName,
            "startDate": startDate,
            "endDate": endDate,
            "appliesToWeek": week,
            "appliesToMonth": month
        }
    };

    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody)
    };

    try {
        let response = await fetch(OPENCAMPGROUND_DATABASE_CALL_BASE_URL, options);
        let json = await response.json()

        if (json.statusCode === 200) {
            return json.body;
        }
    } catch (err) {
        console.error('Error while adding new season', err);
    }
}

export const createNewSpace = async (serverName, name, e15a, e30a, e50a, water, sewer, len, width, sitetype_id, price_id, remote, special_condition, active) => {
    const requestBody = {
        'operationName': 'createNewSpace',
        'payload': {
            "serverName": serverName,
            "name": name,
            "power15a": e15a,
            "power30a": e30a,
            "power50a": e50a,
            "water": water,
            "sewer": sewer,
            "len": len,
            "width": width,
            "sitetype_id": sitetype_id,
            "price_id": price_id,
            "remote_reservable": remote,
            "special_condition": special_condition,
            "active": active
        }
    };

    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody)
    };

    try {
        console.log(requestBody)
        let response = await fetch(OPENCAMPGROUND_DATABASE_CALL_BASE_URL, options);
        let json = await response.json()

        if (json.statusCode === 200) {
            return json.body;
        }
    } catch (err) {
        console.error('Error while adding new space', err);
    }
}

export const getSitePrice = async (serverName) => {
    const requestBody = {
        'operationName': 'getSitePrice',
        'payload': {
            "serverName": serverName,
        }
    };

    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody)
    };

    try {
        let response = await fetch(OPENCAMPGROUND_DATABASE_CALL_BASE_URL, options);
        let json = await response.json()

        if (json.statusCode === 200) {
            return json.body;
        }
    } catch (err) {
        console.error('Error while getting getSitePrice', err);
    }
}

export const createNewDiscount = async (serverName, discountname, duration, sunday, monday, tuesday, wednesday, thursday, friday, saturday, discountPpercent, applDaily, applWeek, applMonth, applSeason, amt, amtDaily, amtWeek, amtMonth) => {
    const requestBody = {
        'operationName': 'createNewDiscount',
        'payload': {
            "serverName": serverName,
            "name": discountname,
            "duration": duration,
            "sunday": sunday,
            "monday": monday,
            "tuesday": tuesday,
            "wednesday": wednesday,
            "thursday": thursday,
            "friday": friday,
            "saturday": saturday,
            "discount_percent": discountPpercent,
            "disc_appl_daily": applDaily,
            "disc_appl_week": applWeek,
            "disc_appl_month": applMonth,
            "disc_appl_seasonal": applSeason,
            "amount": amt,
            "amount_daily": amtDaily,
            "amount_weekly": amtWeek,
            "amount_monthly": amtMonth
        }
    };

    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody)
    };

    try {
        let response = await fetch(OPENCAMPGROUND_DATABASE_CALL_BASE_URL, options);
        let json = await response.json()

        if (json.statusCode === 200) {
            return json.body;
        }
    } catch (err) {
        console.error('Error while adding new discount', err);
    }
}

export const createNewRigType = async (serverName, rigType) => {
    const requestBody = {
        'operationName': 'createNewRigType',
        'payload': {
            "serverName": serverName,
            "rigType": rigType
        }
    };

    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody)
    };

    try {
        let response = await fetch(OPENCAMPGROUND_DATABASE_CALL_BASE_URL, options);
        let json = await response.json()

        if (json.statusCode === 200) {
            return json.body;
        }
    } catch (err) {
        console.error('Error while adding rigtype', err);
    }
}

export const createNewTax = async (serverName, name, is_percent, percent, amount, apl_daily, apl_week, apl_month, apl_seasonal) => {
    const requestBody = {
        'operationName': 'createNewTax',
        'payload': {
            "serverName": serverName,
            "name": name,
            "is_percent": is_percent,
            "percent": percent,
            "amount": amount,
            "apl_daily": apl_daily,
            "apl_week": apl_week,
            "apl_month": apl_month,
            "apl_seasonal": apl_seasonal
        }
    };

    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody)
    };

    try {
        let response = await fetch(OPENCAMPGROUND_DATABASE_CALL_BASE_URL, options);
        let json = await response.json()

        if (json.statusCode === 200) {
            return json.body;
        }
    } catch (err) {
        console.error('Error while adding tax', err);
    }
}

export const createNewExtra = async (serverName, name, daily, weekly, monthly, charge, rate, unit_name, extra_type, remote_display) => {
    let extraType;
    if (extra_type === 'standard') {
        extraType = 0
    } else if (extra_type === 'counted') {
        extraType = 1
    } else if (extra_type === 'metered') {
        extraType = 2
    } else if (extra_type === 'occasional') {
        extraType = 3
    } else {
        extraType = 4
    }
    const requestBody = {
        'operationName': 'createNewExtra',
        'payload': {
            "serverName": serverName,
            "name": name,
            "daily": daily,
            "weekly": weekly,
            "monthly": monthly,
            "charge": charge,
            "rate": rate,
            "unit_name": unit_name,
            "extra_type": extraType,
            "remote_display": remote_display
        }
    };

    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody)
    };

    try {
        let response = await fetch(OPENCAMPGROUND_DATABASE_CALL_BASE_URL, options);
        let json = await response.json()

        if (json.statusCode === 200) {
            return json.body;
        }
    } catch (err) {
        console.error('Error while adding Extra', err);
    }
}

export const createNewPaymentType = async (serverName, paymentType) => {
    const requestBody = {
        'operationName': 'createNewPaymentType',
        'payload': {
            "serverName": serverName,
            "paymentType": paymentType
        }
    };

    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody)
    };

    try {
        let response = await fetch(OPENCAMPGROUND_DATABASE_CALL_BASE_URL, options);
        let json = await response.json()

        if (json.statusCode === 200) {
            return json.body;
        }
    } catch (err) {
        console.error('Error while adding paymentType', err);
    }
}

export const camperDetailsForReservation = async (serverName, reservation_id) => {
    const requestBody = {
        'operationName': 'camperDetailsForReservation',
        'payload': {
            "serverName": serverName,
            "reservation_id": reservation_id
        }
    };

    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody)
    };

    try {
        let response = await fetch(OPENCAMPGROUND_DATABASE_CALL_BASE_URL, options);
        let json = await response.json()

        if (json.statusCode === 200) {
            return json.body[0];
        }
    } catch (err) {
        console.error('Error while getting camper details', err);
    }
}

export const spaceSpecificRates = async (serverName, startDate, endDate, spaceName) => {
    const requestBody = {
        'operationName': 'spaceSpecificRates',
        'payload': {
            "serverName": serverName,
            "startDate": startDate,
            "endDate": endDate,
            "spaceName": spaceName
        }
    };

    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody)
    };

    try {
        let response = await fetch(OPENCAMPGROUND_DATABASE_CALL_BASE_URL, options);
        let json = await response.json()

        if (json.statusCode === 200) {
            return json.body[0];
        }
    } catch (err) {
        console.error('Error while getting space specific rates', err);
    }
}

export const getPayments = async (serverName, reservation_id) => {
    const requestBody = {
        'operationName': 'getPayments',
        'payload': {
            "serverName": serverName,
            "reservation_id": reservation_id
        }
    };

    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody)
    };

    try {
        let response = await fetch(OPENCAMPGROUND_DATABASE_CALL_BASE_URL, options);
        let json = await response.json()

        if (json.statusCode === 200) {
            return json.body;
        }
    } catch (err) {
        console.error('Error while getting payment', err);
    }
}

export const confirmReservation = async (serverName, reservation_id) => {
    const requestBody = {
        'operationName': 'confirmReservation',
        'payload': {
            "serverName": serverName,
            "reservation_id": reservation_id
        }
    };

    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody)
    };

    try {
        let response = await fetch(OPENCAMPGROUND_DATABASE_CALL_BASE_URL, options);
        let json = await response.json()

        if (json.statusCode === 200) {
            return json.body;
        }
    } catch (err) {
        console.error('Error while confirming remote reservation', err);
    }
}

export const getArchived = async (serverName) => {
    const requestBody = {
        'operationName': 'getArchived',
        'payload': {
            "serverName": serverName
        }
    };

    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody)
    };

    try {
        let response = await fetch(OPENCAMPGROUND_DATABASE_CALL_BASE_URL, options);
        let json = await response.json()

        if (json.statusCode === 200) {
            return json.body;
        }
    } catch (err) {
        console.error('Error while getting archived', err);
    }
}

export const addVariableCharge = async (serverName, reservation_id, detail, amount, created_at, updated_at) => {
    const requestBody = {
        'operationName': 'addVariableCharge',
        'payload': {
            "serverName": serverName,
            "reservation_id": reservation_id,
            "detail": detail,
            "amount": amount,
            "created_at": created_at,
            "updated_at": updated_at
        }
    };

    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody)
    };

    try {
        let response = await fetch(OPENCAMPGROUND_DATABASE_CALL_BASE_URL, options);
        let json = await response.json()

        if (json.statusCode === 200) {
            return json.body;
        }
    } catch (err) {
        console.error('Error while adding variable charge', err);
    }
}

export const login = async (serverName, userName, password) => {
    const requestBody = {
        'operationName': 'login',
        'payload': {
            "serverName": serverName,
            "userName": userName,
            "password": password
        }
    };

    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody)
    };

    try {
        let response = await fetch(OPENCAMPGROUND_DATABASE_CALL_BASE_URL, options);
        let json = await response.json()
        return json;

    } catch (err) {
        console.error('Error while logging in', err);
    }
}

export const getCampers = async (serverName) => {
    const requestBody = {
        'operationName': 'getCampers',
        'payload': {
            "serverName": serverName
        }
    };

    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody)
    };

    try {
        let response = await fetch(OPENCAMPGROUND_DATABASE_CALL_BASE_URL, options);
        let json = await response.json()
        if (json.statusCode === 200) {
            return json.body;
        }

    } catch (err) {
        console.error('Error while getting campers', err);
    }
}

export const getUsers = async (serverName) => {
    const requestBody = {
        'operationName': 'getUsers',
        'payload': {
            "serverName": serverName
        }
    };

    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody)
    };

    try {
        let response = await fetch(OPENCAMPGROUND_DATABASE_CALL_BASE_URL, options);
        let json = await response.json()
        if (json.statusCode === 200) {
            return json.body;
        }

    } catch (err) {
        console.error('Error while getting users', err);
    }
}

export const updateUser = async (serverName, admin, id) => {
    const requestBody = {
        'operationName': 'updateUser',
        'payload': {
            "serverName": serverName,
            "admin": admin,
            "id": id
        }
    };

    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody)
    };

    try {
        let response = await fetch(OPENCAMPGROUND_DATABASE_CALL_BASE_URL, options);
        let json = await response.json()
        if (json.statusCode === 200) {
            return json.body;
        }

    } catch (err) {
        console.error('Error while updating users', err);
    }
}

export const addUser = async (serverName, userName, password, admin) => {
    const requestBody = {
        'operationName': 'addUser',
        'payload': {
            "serverName": serverName,
            "userName": userName,
            "password": password,
            "admin": admin
        }
    };

    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody)
    };

    try {
        let response = await fetch(OPENCAMPGROUND_DATABASE_CALL_BASE_URL, options);
        let json = await response.json()
        if (json.statusCode === 200) {
            return json.body;
        }

    } catch (err) {
        console.error('Error while adding new user', err);
    }
}

export const addExtras = async (serverName, extra_id, reservation_id, number, days, charge) => {
    const requestBody = {
        'operationName': 'addExtras',
        'payload': {
            "serverName": serverName,
            "extra_id": extra_id,
            "reservation_id": reservation_id,
            "number": number,
            "days": days,
            "charge": charge
        }
    };

    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody)
    };

    try {
        let response = await fetch(OPENCAMPGROUND_DATABASE_CALL_BASE_URL, options);
        let json = await response.json()
        if (json.statusCode === 200) {
            return json.body;
        }

    } catch (err) {
        console.error('Error while adding extra', err);
    }
}

export const getIntegrations = async (serverName) => {
    const requestBody = {
        'operationName': 'getIntegrations',
        'payload': {
            "serverName": serverName,
        }
    };

    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody)
    };

    try {
        let response = await fetch(OPENCAMPGROUND_DATABASE_CALL_BASE_URL, options);
        let json = await response.json()
        if (json.statusCode === 200) {
            return json.body[0];
        }

    } catch (err) {
        console.error('Error while getting integrations', err);
    }
}

export const getExtrasAndVariableCharges = async (serverName, reservation_id) => {
    const requestBody = {
        'operationName': 'getExtrasAndVariableCharges',
        'payload': {
            "serverName": serverName,
            "reservation_id": reservation_id,
        }
    };

    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody)
    };

    try {
        let response = await fetch(OPENCAMPGROUND_DATABASE_CALL_BASE_URL, options);
        let json = await response.json()
        if (json.statusCode === 200) {
            return json.body;
        }

    } catch (err) {
        console.error('Error while getting extra and variable charges', err);
    }
}

export const changeIntegration = async (serverName, integration_name) => {
    const requestBody = {
        'operationName': 'changeIntegration',
        'payload': {
            "serverName": serverName,
            "integration_name": integration_name,
        }
    };

    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody)
    };

    try {
        let response = await fetch(OPENCAMPGROUND_DATABASE_CALL_BASE_URL, options);
        let json = await response.json()
        if (json.statusCode === 200) {
            return json.body;
        }

    } catch (err) {
        console.error('Error while changing integration', err);
    }
}

export const changeEmails = async (serverName, sender, reply, cc, bcc) => {
    const requestBody = {
        'operationName': 'changeEmails',
        'payload': {
            "serverName": serverName,
            "sender": sender,
            "reply": reply,
            "cc": cc,
            "bcc": bcc
        }
    };

    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody)
    };

    try {
        let response = await fetch(OPENCAMPGROUND_DATABASE_CALL_BASE_URL, options);
        let json = await response.json()
        if (json.statusCode === 200) {
            return json.body;
        }

    } catch (err) {
        console.error('Error while changing emails', err);
    }
}

export const sendMail = async (serverName, from, to, subject, body, reply, cc, bcc) => {
    const requestBody = {
        'operationName': 'sendMail',
        'payload': {
            "serverName": serverName,
            "from": from,
            "to": to,
            "subject": subject,
            "body": body,
            "reply": reply,
            "cc": cc,
            "bcc": bcc
        }
    };

    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody)
    };

    try {
        let response = await fetch(OPENCAMPGROUND_DATABASE_CALL_BASE_URL, options);
        let json = await response.json()
        if (json.statusCode === 200) {
            return json.body;
        }

    } catch (err) {
        console.error('Error while changing emails', err);
    }
}

export const deleteArchives = async (serverName, reservation_id) => {
    const requestBody = {
        'operationName': 'deleteArchives',
        'payload': {
            "serverName": serverName,
            "reservation_id": reservation_id
        }
    };

    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody)
    };

    try {
        let response = await fetch(OPENCAMPGROUND_DATABASE_CALL_BASE_URL, options);
        let json = await response.json()
        if (json.statusCode === 200) {
            return json.body;
        }

    } catch (err) {
        console.error('Error while deleting archived reservation', err);
    }
}

export const getSuspendedStatus = async (serverName) => {
    const requestBody = {
        'operationName': 'getSuspendedStatus',
        'payload': {
            "serverName": serverName
        }
    };

    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody)
    };

    try {
        let response = await fetch(OPENCAMPGROUND_DATABASE_CALL_BASE_URL, options);
        let json = await response.json()
        return json;

    } catch (err) {
        console.error('Error while getting suspended status', err);
    }
}

export const deleteAddedExtra = async (serverName, id) => {
    const requestBody = {
        'operationName': 'deleteAddedExtra',
        'payload': {
            "serverName": serverName,
            "id": id
        }
    };
    console.log(requestBody)
    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody)
    };

    try {
        let response = await fetch(OPENCAMPGROUND_DATABASE_CALL_BASE_URL, options);
        let json = await response.json()
        return json;

    } catch (err) {
        console.error('Error while deleting added extra', err);
    }
}

export const getSiteAvailabilityData = async (serverName, date, start, end) => {
    const requestBody = {
        'operationName': 'getSiteAvailabilityData',
        'payload': {
            "serverName": serverName,
            "date": date,
            "start": start,
            "end": end
        }
    };
    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody)
    };

    try {
        let response = await fetch(OPENCAMPGROUND_DATABASE_CALL_BASE_URL, options);
        let json = await response.json()
        return json;

    } catch (err) {
        console.error('Error while getting site availability data', err);
    }
}

export const getArrivingTodayOrTomorrow = async (serverName, date) => {
    const requestBody = {
        'operationName': 'getArrivingTodayOrTomorrow',
        'payload': {
            "serverName": serverName,
            "date": date
        }
    };
    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody)
    };

    try {
        let response = await fetch(OPENCAMPGROUND_DATABASE_CALL_BASE_URL, options);
        let json = await response.json()
        return json;

    } catch (err) {
        console.error('Error while getting  arriving today and tomorrow', err);
    }
}

export const getSceduledArrivals = async (serverName, date1, date2) => {
    const requestBody = {
        'operationName': 'getSceduledArrivals',
        'payload': {
            "serverName": serverName,
            "date1": date1,
            "date2": date2
        }
    };
    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody)
    };

    try {
        let response = await fetch(OPENCAMPGROUND_DATABASE_CALL_BASE_URL, options);
        let json = await response.json()
        return json;

    } catch (err) {
        console.error('Error while getting scheduled arrivals', err);
    }
}

export const getCheckingOutTodatOrTomorrow = async (serverName, date) => {
    const requestBody = {
        'operationName': 'getCheckingOutTodatOrTomorrow',
        'payload': {
            "serverName": serverName,
            "date": date
        }
    };
    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody)
    };

    try {
        let response = await fetch(OPENCAMPGROUND_DATABASE_CALL_BASE_URL, options);
        let json = await response.json()
        return json;

    } catch (err) {
        console.error('Error while getting checkout today and tomorrow', err);
    }
}

export const getScheduledCheckOuts = async (serverName, date1, date2) => {
    const requestBody = {
        'operationName': 'getScheduledCheckOuts',
        'payload': {
            "serverName": serverName,
            "date1": date1,
            "date2": date2
        }
    };
    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody)
    };

    try {
        let response = await fetch(OPENCAMPGROUND_DATABASE_CALL_BASE_URL, options);
        let json = await response.json()
        return json;

    } catch (err) {
        console.error('Error while getting scheduled checkouts', err);
    }
}

export const getInParkList = async (serverName) => {
    const requestBody = {
        'operationName': 'getInParkList',
        'payload': {
            "serverName": serverName
        }
    };
    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody)
    };

    try {
        let response = await fetch(OPENCAMPGROUND_DATABASE_CALL_BASE_URL, options);
        let json = await response.json()
        return json;

    } catch (err) {
        console.error('Error while getting in park list', err);
    }
}

export const getReservationsReport = async (serverName, date1, date2) => {
    const requestBody = {
        'operationName': 'getReservationsReport',
        'payload': {
            "serverName": serverName,
            "date1": date1,
            "date2": date2
        }
    };
    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody)
    };

    try {
        let response = await fetch(OPENCAMPGROUND_DATABASE_CALL_BASE_URL, options);
        let json = await response.json()
        return json;

    } catch (err) {
        console.error('Error while getting scheduled reservations', err);
    }
}

export const getPaymentsReport = async (serverName, date1, date2) => {
    const requestBody = {
        'operationName': 'getPaymentsReport',
        'payload': {
            "serverName": serverName,
            "date1": date1,
            "date2": date2,
            // "subtotal": subtotal
        }
    };
    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody)
    };

    try {
        let response = await fetch(OPENCAMPGROUND_DATABASE_CALL_BASE_URL, options);
        let json = await response.json()
        return json;

    } catch (err) {
        console.error('Error while getting scheduled reservations', err);
    }
}

export const getUserAddedStatus = async (serverName, emailId) => {
    const requestBody = {
        'operationName': 'getUserAddedStatus',
        'payload': {
            "serverName": serverName,
            "emailId": emailId,
        }
    };
    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody)
    };

    try {
        let response = await fetch(OPENCAMPGROUND_DATABASE_CALL_BASE_URL, options);
        let json = await response.json()
        return json.body[0];

    } catch (err) {
        console.error('Error while getting scheduled reservations', err);
    }
}

export const changePassword = async (serverName, userId, password) => {
    const requestBody = {
        'operationName': 'changePassword',
        'payload': {
            "serverName": serverName,
            "userId": userId,
            "password": password,
        }
    };
    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody)
    };

    try {
        let response = await fetch(OPENCAMPGROUND_DATABASE_CALL_BASE_URL, options);
        let json = await response.json()
        return json;

    } catch (err) {
        console.error('Error while getting scheduled reservations', err);
    }
}

export const getBlackoutDates = async (serverName) => {
    const requestBody = {
        'operationName': 'getBlackoutDates',
        'payload': {
            "serverName": serverName,
        }
    };
    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody)
    };

    try {
        let response = await fetch(OPENCAMPGROUND_DATABASE_CALL_BASE_URL, options);
        let json = await response.json()
        return json.body;
    } catch (err) {
        console.error('Error while getting blackout dates', err);
    }
}

export const createNewBlackout = async (serverName, name, start, end, active) => {
    const requestBody = {
        'operationName': 'createNewBlackout',
        'payload': {
            "serverName": serverName,
            "name": name,
            "start": start,
            "end": end,
            "active": active
        }
    };
    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody)
    };

    try {
        let response = await fetch(OPENCAMPGROUND_DATABASE_CALL_BASE_URL, options);
        let json = await response.json()
        return json.body;
    } catch (err) {
        console.error('Error while creating new blackout dates', err);
    }
}

export const updateBlackout = async (serverName, name, start, end, active, id) => {
    const requestBody = {
        'operationName': 'updateBlackout',
        'payload': {
            "serverName": serverName,
            "name": name,
            "start": start,
            "end": end,
            "active": active,
            "id": id
        }
    };
    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody)
    };

    try {
        let response = await fetch(OPENCAMPGROUND_DATABASE_CALL_BASE_URL, options);
        let json = await response.json()
        return json.body;
    } catch (err) {
        console.error('Error while updating blackout dates', err);
    }
}

export const getRemoteStatusIntegrationBlackout = async (serverName, name, start, end, active, id) => {
    const requestBody = {
        'operationName': 'getRemoteStatusIntegrationBlackout',
        'payload': {
            "serverName": serverName,
        }
    };
    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody)
    };

    try {
        let response = await fetch(OPENCAMPGROUND_DATABASE_CALL_BASE_URL, options);
        let json = await response.json()
        return json.body;
    } catch (err) {
        console.error('Error while getting RemoteStatus Integration Blackout', err);
    }
}

export const getRemoteExplainations = async (serverName) => {
    const requestBody = {
        'operationName': 'getRemoteExplainations',
        'payload': {
            "serverName": serverName,
        }
    };
    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody)
    };

    try {
        let response = await fetch(OPENCAMPGROUND_DATABASE_CALL_BASE_URL, options);
        let json = await response.json()
        return json.body[0];
    } catch (err) {
        console.error('Error while getting remote explainations', err);
    }
}

export const updateRemoteExplainations = async (serverName, facilities, additionalDetails, rules, changes, cancellations, addressLine1, addressLine2, addressLine3, id) => {
    const requestBody = {
        'operationName': 'updateRemoteExplainations',
        'payload': {
            "serverName": serverName,
            "facilities": facilities,
            "additionalDetails": additionalDetails,
            "rules": rules,
            "changes": changes,
            "cancellations": cancellations,
            "addressLine1": addressLine1,
            "addressLine2": addressLine2,
            "addressLine3": addressLine3,
            "id": id,
        }
    };
    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody)
    };

    try {
        console.log(requestBody)
        let response = await fetch(OPENCAMPGROUND_DATABASE_CALL_BASE_URL, options);
        let json = await response.json()
        return json.body;
    } catch (err) {
        console.error('Error while updating remote explainations', err);
    }
}

export const getGroups = async (serverName) => {
    const requestBody = {
        'operationName': 'getGroups',
        'payload': {
            "serverName": serverName
        }
    };
    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody)
    };

    try {
        let response = await fetch(OPENCAMPGROUND_DATABASE_CALL_BASE_URL, options);
        let json = await response.json()
        return json;

    } catch (err) {
        console.error('Error while getting groups', err);
    }
}

export const getGroupCampers = async (serverName, id) => {
    const requestBody = {
        'operationName': 'getGroupCampers',
        'payload': {
            "serverName": serverName,
            "id": id
        }
    };
    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody)
    };

    try {
        let response = await fetch(OPENCAMPGROUND_DATABASE_CALL_BASE_URL, options);
        let json = await response.json()
        return json;

    } catch (err) {
        console.error('Error while getting group campers', err);
    }
}

export const customerDetailChanges = async (serverName, fname, lname, emailId, phoneNumber, address, city, state, zip, camperId) => {
    const requestBody = {
        'operationName': 'customerDetailChanges',
        'payload': {
            "serverName": serverName,
            "fname": fname,
            "lname": lname,
            "email": emailId,
            "phoneNumber": phoneNumber,
            "address": address,
            "city": city,
            "state": state,
            "zip": zip,
            "camperId": camperId
        }
    };
    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody)
    };

    try {
        let response = await fetch(OPENCAMPGROUND_DATABASE_CALL_BASE_URL, options);
        let json = await response.json()
        return json;

    } catch (err) {
        console.error('Error while updating camper details', err);
    }
}

export const internalNotesChanges = async (serverName, note, reservationId) => {
    const requestBody = {
        'operationName': 'internalNotesChanges',
        'payload': {
            "serverName": serverName,
            "note": note,
            "reservationId": reservationId
        }
    };
    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody)
    };

    try {
        let response = await fetch(OPENCAMPGROUND_DATABASE_CALL_BASE_URL, options);
        let json = await response.json()
        return json;

    } catch (err) {
        console.error('Error while updating internal notes', err);
    }
}