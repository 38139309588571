import React, { Component } from 'react';
import { Grid, Button, Typography, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import '../CSS/Settings.css';
import { getRemoteReservationsStatus, updateRemoteReservationsStatus } from '../utils/OpenCampground_Database_Calls'
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { withStyles } from '@material-ui/core/styles';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
const useStyles = theme => ({
    dialogPaper: {
        padding: theme.spacing(2),
        borderRadius: '10px',
    }
});
const theme = createTheme({
    palette: {
        primary: {
            main: '#2dcd7a',
        },
    },
});

class SystemOptions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            remote: null,
            remoteReservationPaymentConfig: null,
            open: false,
            popupTitle: '',
            popupText: ''
        };
    }

    async componentDidMount() {
        let remoteActive = await getRemoteReservationsStatus(this.props.serverName)
        if (remoteActive[0].use_remote_reservations === 1) {
            let paymentConfig
            if (remoteActive[0].require_gateway === 1) {
                paymentConfig = "require"
            } else if (remoteActive[0].allow_gateway === 1) {
                paymentConfig = "allow"
            } else {
                paymentConfig = "doNotAllow"
            }
            this.setState({
                remote: true,
                remoteReservationPaymentConfig: paymentConfig
            })
        } else {
            this.setState({
                remote: false,
                remoteReservationPaymentConfig: "require"
            })
        }
    }

    saveSystemOptions = async () => {
        let saveresponse = await updateRemoteReservationsStatus(this.props.serverName, this.state.remote, this.state.remoteReservationPaymentConfig)
        this.setState({
            popupText: 'Remote Reservations Details Saved',
            open: true
        })
    }

    handleCancel = () => {
        this.setState({ open: false });
    };

    handleChange = (event) => {
        if (event.target.id === 'remote') {
            this.setState({
                [event.target.id]: event.target.checked
            })
        }
    }

    isRadioChecked = (value) => {
        return this.state.remoteReservationPaymentConfig === value;
    }

    render() {
        const { classes } = this.props;
        return (
            <>
                <ThemeProvider theme={theme}>
                    <Dialog open={this.state.open} onClose={this.handleCancel} classes={{ paper: classes.dialogPaper }}>
                        <DialogTitle>{this.state.popupTitle}</DialogTitle>
                        <DialogContent>
                            {this.state.popupText}
                        </DialogContent>
                        <DialogActions style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Button onClick={this.handleCancel} style={{ backgroundColor: '#FDECEB', color: '#DB3737', padding: '2% 10% 2% 10%', margin: '0 0 0 2%', borderRadius: '10px', marginTop: '3%' }}>
                                Cancel
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <div className='paymentConfig'>
                        <div className='row'>
                            <Typography style={{ fontSize: '1.3rem' }}>Remote Reservations Settings</Typography>
                        </div>
                        <div className='row'>
                            <FormControlLabel
                                label={"Allow Remote Reservations"}
                                control={
                                    <Checkbox
                                        id="remote"
                                        checked={this.state.remote}
                                        onChange={this.handleChange}
                                    />
                                }
                            />
                        </div>
                        {this.state.remote
                            ?
                            <>
                                <div className='row'>
                                    <label>
                                        <Radio
                                            name='option'
                                            value='require'
                                            checked={this.isRadioChecked('require')}
                                            onChange={() => { this.setState({ remoteReservationPaymentConfig: 'require' }) }}
                                        />
                                        Remote Reservations Require Payment
                                    </label>
                                    <label>
                                        <Radio
                                            name='option'
                                            value='allow'
                                            checked={this.isRadioChecked('allow')}
                                            onChange={() => { this.setState({ remoteReservationPaymentConfig: 'allow' }) }}
                                        />
                                        Remote Reservations Allow Payment
                                    </label>
                                    <label>
                                        <Radio
                                            name='option'
                                            value='doNotAllow'
                                            checked={this.isRadioChecked('doNotAllow')}
                                            onChange={() => { this.setState({ remoteReservationPaymentConfig: 'doNotAllow' }) }}
                                        />
                                        Remote Reservations do not use Payment
                                    </label>
                                </div>
                            </>
                            :
                            null
                        }
                    </div>
                    <Button style={{ backgroundColor: '#2dcd7a', color: '#000000', marginTop: '3%' }} className='viewButtons' onClick={this.saveSystemOptions}>Save</Button>
                </ThemeProvider>
            </>
        );
    };
};
export default withStyles(useStyles)(SystemOptions);