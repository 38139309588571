import React, { Component } from 'react';
import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField, Checkbox, FormControlLabel, Select, MenuItem, FormControl, InputLabel, Dialog, DialogContent } from '@mui/material';
import { deleteRecord, getExtras, createNewExtra, updateExtra } from '../utils/OpenCampground_Database_Calls';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
const theme = createTheme({
    palette: {
        primary: {
            main: '#2dcd7a',
        },
    },
});

class Extras extends Component {
    constructor(props) {
        super(props);
        this.state = {
            extras: [],
            extrasUpdate: false,
            formValid: false,
            extraName: '',
            remote: false,
            active: true,
            extraType: 'standard',
            daily: '',
            week: '',
            month: '',
            rate: '',
            unit: '',
            charge: '',
            editingExtraId: null,
            addNew: false,
        };
    }

    async componentDidMount() {
        this.loadExtras();
    }

    deleteRecord = async (id) => {
        await deleteRecord(this.props.serverName, "extras", id);
        this.setState({ extrasUpdate: true });
        this.loadExtras();
    }

    loadExtras = async () => {
        if (this.state.extrasUpdate || this.state.extras.length === 0) {
            const extras = await getExtras(this.props.serverName);
            this.setState({ extras, extrasUpdate: false });
        }
    }

    getExtraTypeDisplay = (extraType) => {
        const typeMap = {
            0: 'Standard',
            1: 'Counted',
            2: 'Measured',
            3: 'Occasional',
            4: 'Deposit'
        };
        return typeMap[extraType] || '';
    }

    handleChange = (event) => {
        const { id, name, value, checked, type } = event.target;
        this.setState({
            [id || name]: type === 'checkbox' ? checked : value
        }, this.formValidation);
    }

    formValidation = () => {
        const { extraName, daily, week, month, rate, unit, charge } = this.state;
        const isValid = extraName && daily && week && month && rate && unit && charge;
        this.setState({ formValid: isValid });
    }

    addNew = async () => {
        await createNewExtra(
            this.props.serverName,
            this.state.extraName,
            this.state.daily,
            this.state.week,
            this.state.month,
            this.state.charge,
            this.state.rate,
            this.state.unit,
            this.state.extraType,
            this.state.remote
        );
        this.resetForm()
        this.loadExtras();
    }

    startEditing = (extra) => {
        this.setState({
            editingExtraId: extra.id,
            extraName: extra.name,
            remote: extra.remote_display,
            extraType: this.getExtraTypeDisplay(extra.extra_type.toString()).toLowerCase(),
            daily: extra.daily,
            week: extra.weekly,
            month: extra.monthly,
            rate: extra.rate,
            unit: extra.unit,
            charge: extra.charge,
            active: extra.active
        });
    }

    saveEdits = async () => {
        await updateExtra(this.props.serverName, this.state.editingExtraId, this.state.extraName, this.state.remote, this.state.extraType,
            this.state.daily, this.state.week, this.state.month, this.state.rate, this.state.unit, this.state.charge, this.state.active);
        this.resetForm()
        this.loadExtras();
    }

    handleExtraTypeSelect = (event) => {
        this.setState({
            extraType: event.target.value
        })
    }

    resetForm = () => {
        this.setState({
            extrasUpdate: true,
            extraName: '',
            remote: false,
            active: true,
            extraType: 'standard',
            daily: '',
            week: '',
            month: '',
            rate: '',
            unit: '',
            charge: '',
            editingExtraId: null,
            addNew: false,
        })
    }

    render() {
        return (
            <ThemeProvider theme={theme}>
                <Button
                    className='resButton'
                    onClick={() => this.setState({ addNew: true })}
                    style={{ backgroundColor: '#2dcd7a', color: '#000000', textTransform: "capitalize", marginBottom: '2%' }}
                >
                    Add New Extra
                </Button>
                {this.state.addNew || this.state.editingExtraId ? (
                    <Dialog open={this.state.addNew || this.state.editingExtraId} onClose={() => {
                        this.resetForm();
                    }} maxWidth="sm" fullWidth>
                        <DialogContent dividers>
                            <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
                                <h2 style={{ margin: "0px", marginBottom: "10px" }}>{this.state.editingExtraId ? "Update Extra" : "Add Extra"}</h2>
                                <div style={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
                                    <TextField
                                        id="extraName"
                                        required
                                        label="Extra Name"
                                        value={this.state.extraName}
                                        onChange={this.handleChange}
                                    />
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
                                    <FormControlLabel
                                        label={"Remote Display"}
                                        control={
                                            <Checkbox
                                                id="remote"
                                                checked={this.state.remote}
                                                onChange={this.handleChange}
                                            />
                                        }
                                    />
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
                                    <RadioGroup
                                        value={this.state.extraType}
                                        row
                                        onChange={this.handleExtraTypeSelect}
                                    >
                                        <FormControlLabel value="standard" control={<Radio color="primary" />} label="Standard" />
                                        <FormControlLabel value="counted" control={<Radio color="primary" />} label="Counted" />
                                        <FormControlLabel value="metered" control={<Radio color="primary" />} label="Metered" />
                                        <FormControlLabel value="occasional" control={<Radio color="primary" />} label="Occasional" />
                                        <FormControlLabel value="deposit" control={<Radio color="primary" />} label="Deposit" />
                                    </RadioGroup>
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
                                    {this.state.extraType === 'standard' || this.state.extraType === 'counted'
                                        ?
                                        <>
                                            <TextField
                                                id="daily"
                                                required
                                                label="Daily"
                                                value={this.state.daily}
                                                onChange={this.handleChange}
                                            />
                                            <TextField
                                                id="week"
                                                required
                                                label="Weekly"
                                                value={this.state.week}
                                                onChange={this.handleChange}
                                            />
                                            <TextField
                                                id="month"
                                                required
                                                label="Monthly"
                                                value={this.state.month}
                                                onChange={this.handleChange}
                                            />
                                        </>
                                        : null}
                                    {this.state.extraType === 'metered'
                                        ?
                                        <>
                                            <TextField
                                                id="rate"
                                                required
                                                label="Rate/Unit"
                                                value={this.state.rate}
                                                onChange={this.handleChange}
                                            />
                                            <TextField
                                                id="unit"
                                                required
                                                label="Unit"
                                                value={this.state.unit}
                                                onChange={this.handleChange}
                                            />
                                        </>
                                        : null}
                                    {this.state.extraType === 'occasional' || this.state.extraType === 'deposit'
                                        ?
                                        <>
                                            <TextField
                                                id="charge"
                                                required
                                                label="Charge"
                                                value={this.state.charge}
                                                onChange={this.handleChange}
                                            />
                                        </>
                                        : null}
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '16px' }}>
                                    <Button
                                        style={{ backgroundColor: '#2dcd7a', color: '#000000' }}
                                        onClick={this.state.editingExtraId ? this.saveEdits : this.addNew}
                                        disabled={this.state.editingExtraId ? false : !this.state.formValid}
                                    >
                                        {this.state.editingExtraId ? "Update" : "Add"}
                                    </Button>
                                    <Button
                                        style={{ backgroundColor: '#FDECEB', color: '#DB3737' }}
                                        onClick={() => { this.resetForm() }}
                                    >
                                        Cancel
                                    </Button>
                                </div>
                            </div>
                        </DialogContent>
                    </Dialog>
                ) : ""}
                <TableContainer component={Paper} style={{ width: '90%', margin: '0 auto' }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell className='tableCell'>Name</TableCell>
                                <TableCell className='tableCell'>Type</TableCell>
                                <TableCell className='tableCell'>Rate Per Unit</TableCell>
                                <TableCell className='tableCell'>Unit</TableCell>
                                <TableCell className='tableCell'>Charge</TableCell>
                                <TableCell className='tableCell'>Daily</TableCell>
                                <TableCell className='tableCell'>Weekly</TableCell>
                                <TableCell className='tableCell'>Monthly</TableCell>
                                <TableCell className='tableCell'>Remote Display</TableCell>
                                <TableCell className='tableCell'>Active</TableCell>
                                <TableCell className='tableCell'>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.extras.map((extra) => (
                                <TableRow key={extra.id}>
                                    <TableCell className='tableCell'>
                                        {extra.name}
                                    </TableCell>
                                    <TableCell className='tableCell'>
                                        {this.getExtraTypeDisplay(extra.extra_type)}
                                    </TableCell>
                                    <TableCell className='tableCell'>
                                        {`$${extra.rate}`}
                                    </TableCell>
                                    <TableCell className='tableCell'>
                                        {extra.unit_name}
                                    </TableCell>
                                    <TableCell className='tableCell'>
                                        {`$${extra.charge}`}
                                    </TableCell>
                                    <TableCell className='tableCell'>
                                        {`$${extra.daily}`}
                                    </TableCell>
                                    <TableCell className='tableCell'>
                                        {`$${extra.weekly}`}
                                    </TableCell>
                                    <TableCell className='tableCell'>
                                        {`$${extra.monthly}`}
                                    </TableCell>
                                    <TableCell className='tableCell'>
                                        {(extra.remote_display ? 'Yes' : 'No')}
                                    </TableCell>
                                    <TableCell className='tableCell'>
                                        {extra.active ? 'Yes' : 'No'}
                                    </TableCell>
                                    <TableCell className='tableCell'>
                                        <div>
                                            <Button onClick={() => this.startEditing(extra)} className='viewButtons' style={{ backgroundColor: '#d4d4d4', color: 'black' }}>Edit</Button>
                                            <Button onClick={() => this.deleteRecord(extra.id)} className='viewButtons' style={{ marginTop: '10%' }}>Delete</Button>
                                        </div>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </ThemeProvider>
        );
    }
}

export default Extras;
