import React, { Component } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Table, TableBody, TableCell, TableHead, TableRow, Typography, Grid, Box } from '@mui/material';
import siteAvailability from '../assets/siteAvaibility.png';
import siteAvailabilityLock from '../assets/Hotel Upgrade.png';
import '../CSS/SiteAvailability.css';
import { getSiteAvailabilityData, getSiteRigDiscountExtra } from '../utils/OpenCampground_Database_Calls'
import { Widgets } from '@material-ui/icons';
import { format } from 'date-fns';

const theme = createTheme({
    palette: {
        primary: {
            main: '#2dcd7a',
        },
    },
});

class SiteAvailability extends Component {
    constructor(props) {
        super(props);
        this.state = {
            spaces: [],
            reservations: [],
            dates: [],
            sites: [],
            todaysDate: format(new Date(), 'yyyy-MM-dd'),
            allSpaces: [],
            availableSitesToday: ''
        };
    }

    async componentDidMount() {
        let siteRigDiscountExtra = await getSiteRigDiscountExtra(this.props.serverName);
        let startDate = new Date();
        startDate.setDate(startDate.getDate() - 3);
        let endDate = new Date();
        endDate.setFullYear(startDate.getFullYear() + 1);
        const tomorrow = new Date();
        tomorrow.setDate(tomorrow.getDate() + 1);
        const formattedTomorrow = format(tomorrow, 'yyyy-MM-dd');
        let getSiteAvailabilityDataResponse = await getSiteAvailabilityData(this.props.serverName, startDate.toISOString().split('T')[0], this.state.todaysDate, formattedTomorrow)
        const dates = [];
        for (let d = startDate; d <= endDate; d.setDate(d.getDate() + 1)) {
            dates.push(new Date(d).toISOString().split('T')[0]);
        }
        this.setState({
            spaces: getSiteAvailabilityDataResponse.body[1],
            allSpaces: getSiteAvailabilityDataResponse.body[1],
            reservations: getSiteAvailabilityDataResponse.body[0],
            dates: dates,
            sites: siteRigDiscountExtra[0],
            availableSitesToday: getSiteAvailabilityDataResponse.body[2][0].available_spaces_count
        });
    }

    isReserved(space, date) {
        const { reservations } = this.state;
        const today = new Date();
        today.setDate(today.getDate() - 3);
        const currentDate = new Date(date).toISOString().split('T')[0];

        for (let res of reservations) {
            if (
                res.space === space &&
                new Date(date) >= new Date(res.startdate) &&
                new Date(date) <= new Date(res.enddate)
            ) {
                const isFirstDay = currentDate === res.startdate.split('T')[0];
                const isLastDay = currentDate === res.enddate.split('T')[0];
                let startedReservation = currentDate === today.toISOString().split('T')[0] && new Date(res.startdate).toISOString().split('T')[0] < today.toISOString().split('T')[0];
                return { camper: res.camper, isFirstDay, isLastDay, startedReservation, id: res.id };
            }
        }
        return null;
    }

    filterChange = async (selectedSite) => {
        let filteredSpaces
        if (selectedSite === 'ALL') {
            filteredSpaces = this.state.allSpaces
        } else {
            filteredSpaces = this.state.allSpaces.filter(space => space.siteType === selectedSite)
        }
        this.setState({
            spaces: filteredSpaces
        })
    }

    render() {
        const { spaces, dates } = this.state;

        return (
            <ThemeProvider theme={theme}>
                <Grid className='siteAvailability'>
                    <Box className='infoBox'>
                        <Typography className='infoBarTypo'>Welcome: {this.props.serverName.charAt(0).toUpperCase() + this.props.serverName.slice(1)}</Typography>
                        <Typography className='infoBarTypo'>Username: {this.props.user}</Typography>
                    </Box>

                    <div className='box' style={{ alignItems: 'flex-start' }}>
                        <div className='divStyle'>
                            <img src={siteAvailability} alt="siteAvailability" className='buttonImage' />
                            <Typography className='siteAvailabilitytypography'>
                                Site Availability
                            </Typography>
                        </div>
                        <div style={{ width: "257px", background: '#2DCD7A42', borderRadius: '10px', padding: "0px 16px", display: 'flex', alignItems: 'center', marginBottom: "20px" }}>
                            <div style={{ width: "70px", height: '53px', background: "#FFF", borderRadius: "10px", display: 'flex', alignItems: 'center', justifyContent: "center" }}>
                                <img src={siteAvailabilityLock} style={{ width: "40px", height: "30px" }} />
                            </div>
                            <div style={{ marginLeft: '20px' }}>
                                <p style={{ color: '#2C2C2C', textAlign: "center" }}>Available Sites</p>
                                <h1 style={{ color: "#2C2C2C", fontSize: "32px", textAlign: "center" }}>{this.state.availableSitesToday}</h1>
                            </div>
                        </div>
                    </div>

                    <Box className='tableContainer'>
                        <div style={{ border: '1px solid #979797', margin: "40px 20px 27px", padding: "0 0 0 40px", borderRadius: "1000px", display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <p style={{ fontSize: '18px', fontWeight: "700", margin: "0px" }}>{this.state.dates[0]} - {this.state.dates[this.state.dates.length - 1]}</p>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <div>
                                    <select onChange={(e) => this.filterChange(e.target.value)}>
                                        <option value="ALL">All</option>
                                        {this.state.sites.map((site, index) => (
                                            <option key={index} value={site.name}>
                                                {site.name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                        </div>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell className="stickyFirstColumn">Site Number</TableCell>
                                    {/* <TableCell className="stickySecondColumn">Type</TableCell> */}
                                    {dates.map((date, idx) => (
                                        <TableCell key={idx} className="stickyHeader">
                                            {date.split('-')[1]}/{date.split('-')[2]}
                                            {/* {date.split('-')[0].split('0')[1]} */}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {spaces.map((spaceObj, idx) => {
                                    const space = spaceObj.name;
                                    const type = spaceObj.siteType
                                    const reservations = dates.map((date) => this.isReserved(space, date)).filter(Boolean); // Ensure no nulls

                                    // Count occurrences of each reservation
                                    const reservationCount = new Map();

                                    reservations.forEach((reservation) => {
                                        if (reservation) {
                                            const currentCount = reservationCount.get(reservation.id) || 0;
                                            reservationCount.set(reservation.id, currentCount + 1);
                                        }
                                    });

                                    // Create unique reservations with count
                                    const uniqueReservations = Array.from(reservationCount.keys()).map((id) => {
                                        const reservation = reservations.find((r) => r.id === id);
                                        return {
                                            ...reservation,
                                            count: reservationCount.get(id), // Add the count of similar reservations
                                        };
                                    });

                                    return (
                                        <TableRow key={idx}>
                                            <TableCell className='stickyFirstColumn'>{space}</TableCell>
                                            {/* <TableCell className='stickySecondColumn'>{type}</TableCell> */}
                                            {uniqueReservations.map((reservation, i) => {
                                                return (
                                                    <TableCell
                                                        key={i}
                                                        className="dateCell"
                                                        style={{
                                                            backgroundColor: '#ffffff',

                                                        }}
                                                        colSpan={reservation.count}

                                                    >
                                                        <span style={{
                                                            borderRadius: '1000px',
                                                            backgroundColor: reservation
                                                                ? idx % 2 === 1 ? "#98B2A5" : '#8BD9CC' : "#ffffff"
                                                        }}>
                                                            {reservation && (reservation.startedReservation || reservation.isFirstDay) ? `${reservation.camper} - ${reservation.id}` : ''}
                                                        </span>
                                                    </TableCell>
                                                );
                                            })}
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </Box>
                </Grid>
            </ThemeProvider>
        );
    }
}
export default SiteAvailability;