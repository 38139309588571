import React, { Component } from 'react';
import { Grid, Button, Typography, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import '../CSS/Settings.css';
import TextField from '@mui/material/TextField';
import { getRemoteExplainations, updateRemoteExplainations } from '../utils/OpenCampground_Database_Calls'
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { withStyles } from '@material-ui/core/styles';

const useStyles = theme => ({
    dialogPaper: {
        padding: theme.spacing(2),
        borderRadius: '10px',
    }
});

const theme = createTheme({
    palette: {
        primary: {
            main: '#2dcd7a',
        },
    },
});

class RemoteExplainations extends Component {
    constructor(props) {
        super(props);
        this.state = {
            explainations: [],
            open: false,
            explainationsUpdate: false,
            popupText: '',
            facilities: '',
            additionalDetails: '',
            addressLine1: '',
            addressLine2: '',
            addressLine3: '',
            rules: '',
            changes: '',
            // cancellations: '',
            id: ''
        };
    }

    async componentDidMount() {
        this.explainations()
    }

    explainations = async () => {
        if (this.state.explainationsUpdate) {
            let explainations = await getRemoteExplainations(this.props.serverName)
            this.setState({
                explainations: explainations,
                facilities: explainations.facilities,
                additionalDetails: explainations.additionalDetails,
                addressLine1: explainations.addressLine1,
                addressLine2: explainations.addressLine2,
                addressLine3: explainations.addressLine3,
                rules: explainations.rules,
                changes: explainations.changes,
                // cancellations: explainations.cancellations,
                id: explainations.id
            })
        } else {
            if (this.state.explainations.length > 0) {
            } else {
                let explainations = await getRemoteExplainations(this.props.serverName)
                this.setState({
                    explainations: explainations,
                    facilities: explainations.facilities,
                    additionalDetails: explainations.additionalDetails,
                    addressLine1: explainations.addressLine1,
                    addressLine2: explainations.addressLine2,
                    addressLine3: explainations.addressLine3,
                    rules: explainations.rules,
                    changes: explainations.changes,
                    // cancellations: explainations.cancellations,
                    id: explainations.id
                })
            }
        }
    }

    change = (event) => {
        this.setState({
            [event.target.id]: event.target.value
        })
    }

    saveExplainationsChanges = async () => {
        let explainationsUpdate = await updateRemoteExplainations(this.props.serverName, this.state.facilities, this.state.additionalDetails,
            this.state.rules, this.state.changes, "", 
            this.state.addressLine1, this.state.addressLine2, this.state.addressLine3, this.state.id)
        console.log(explainationsUpdate)
        this.setState({
            explainationsUpdate: true,
            popupText: 'Remote Explainations Changes Successful',
            open: true
        }, () => { this.explainations()})
    }

    handleCancel = () => {
        this.setState({ open: false });
    };

    render() {
        const { classes } = this.props;
        return (
            <ThemeProvider theme={theme}>
                <Dialog open={this.state.open} onClose={this.handleCancel} classes={{ paper: classes.dialogPaper }}>
                    <DialogTitle>{this.state.popupTitle}</DialogTitle>
                    <DialogContent>
                        {this.state.popupText}
                    </DialogContent>
                    <DialogActions style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Button onClick={this.handleCancel} style={{ backgroundColor: '#FDECEB', color: '#DB3737', padding: '2% 10% 2% 10%', margin: '0 0 0 2%', borderRadius: '10px', marginTop: '3%' }}>
                            Cancel
                        </Button>
                    </DialogActions>
                </Dialog>
                <div className="email-settings-section">
                    <Typography style={{ fontSize: '1.5rem' }}>Remote Explainations</Typography>
                    <div className="settings-group">
                        <div style={{ width: '100%' }}>
                            <Typography className="settingsFieldsTypo">Facilities</Typography>
                            <textarea id="facilities" value={this.state.facilities} placeholder="Facilities Details" onChange={this.change}></textarea>
                        </div>
                        <div style={{ width: '100%' }}>
                            <Typography className="settingsFieldsTypo">Additional Details</Typography>
                            <textarea id="additionalDetails" value={this.state.additionalDetails} placeholder="Additional Details" onChange={this.change}></textarea>
                        </div>

                        {/* getting here */}
                        <div className="setting-row">
                            <div>
                                <Typography className="settingsFieldsTypo">Getting Here</Typography>
                                <TextField
                                    id="addressLine1"
                                    value={this.state.addressLine1}
                                    onChange={this.change}
                                    placeholder='Address Line 1'
                                    sx={{ marginRight: "10px" }}
                                />
                                <TextField
                                    id="addressLine2"
                                    value={this.state.addressLine2}
                                    onChange={this.change}
                                    placeholder='Address Line 2'
                                    sx={{ marginRight: "10px" }}
                                />
                                <TextField
                                    id="addressLine3"
                                    value={this.state.addressLine3}
                                    onChange={this.change}
                                    placeholder='Address Line 3'
                                />
                            </div>
                        </div>
                        <div style={{ width: "100%" }}>
                            <Typography className="settingsFieldsTypo">Rules & Cancellation</Typography>
                            <textarea id="rules" value={this.state.rules} placeholder="Rules & Cancellation" onChange={this.change}></textarea>
                        </div>
                        <div style={{ width: "100%" }}>
                            <Typography className="settingsFieldsTypo">Changes</Typography>
                            <textarea id="changes" value={this.state.changes} placeholder="Changes" onChange={this.change}></textarea>
                        </div>
                        {/* <div style={{ width: "100%" }}>
                            <Typography className="settingsFieldsTypo">Cancellations</Typography>
                            <textarea id="cancellations" value={this.state.cancellations} placeholder="Cancellations" onChange={this.change}></textarea>
                        </div> */}
                        <div className="setting-row">
                            <Button className='viewButtons' style={{ whiteSpace: 'nowrap', backgroundColor: '#2dcd7a', color: '#000000' }} onClick={this.saveExplainationsChanges}>Save</Button>
                        </div>
                    </div>
                </div>
            </ThemeProvider>
        );
    };
};
export default withStyles(useStyles)(RemoteExplainations);