import React, { Component } from 'react';
import { Grid, Button, Typography, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@material-ui/core';
import '../CSS/Settings.css';
import { deleteRecord, getEmailTemplates } from '../utils/OpenCampground_Database_Calls'
class MailMessages extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mailMessages: [],
            mailMessagesUpdate: false,
        };
    }

    async componentDidMount() {
        this.mailMessages()
    }

    deleteRecord = async (data) => {
        let deleteResponse = await deleteRecord(this.props.serverName, "mail_templates", data.id)
        this.setState({
            mailMessagesUpdate: true
        })
        this.mailMessages()

    }

    mailMessages = async () => {
        if (this.state.mailMessagesUpdate) {
            let mailMessages = await getEmailTemplates(this.props.serverName)
            this.setState({
                mailMessages: mailMessages
            })
        } else {
            if (this.state.mailMessages.length > 0) {
            } else {
                let mailMessages = await getEmailTemplates(this.props.serverName)
                this.setState({
                    mailMessages: mailMessages
                })
            }
        }
    }

    render() {
        return (
            <TableContainer component={Paper} style={{ width: '60%', margin: '0 auto' }}>
                <Table >
                    <TableHead>
                        <TableRow>
                            <TableCell className='tableCell'>Name</TableCell>
                            <TableCell className='tableCell'>Body</TableCell>
                            <TableCell className='tableCell'>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {this.state.mailMessages.map((message, index) => (
                            <TableRow key={index}>
                                <TableCell className='tableCell'>{message.name}</TableCell>
                                <TableCell className='tableCell'>{message.body}</TableCell>
                                <TableCell className='tableCell'>
                                    <div>
                                        <Button className='viewButtons' style={{ backgroundColor: '#d4d4d4', color: 'black'}}>Edit</Button>
                                        {/* <Button className='viewButtons' onClick={() => this.deleteRecord(message)}>Delete</Button> */}
                                    </div>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        );
    };
};
export default MailMessages;