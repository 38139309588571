import React, { Component } from 'react';
import { Grid, Button, Typography, Box, Dialog, DialogActions, DialogContent } from '@material-ui/core';
import '../CSS/View.css';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import {
    getTaxes, getCardType, recordPayment, cardConnectPaymemt, spaceSpecificRates, getSiteRigDiscountExtra, getPayments, checkIn,
    checkOut, confirmReservation, getIntegrations, addVariableCharge, addExtras, getExtrasAndVariableCharges, getEmailTemplates,
    getEmails, sendMail, deleteAddedExtra, cardConnectGetListOfTerminals, cardConnectConnectToTerminal, cardConnectTerminalPayment,
    customerDetailChanges, internalNotesChanges
} from '../utils/OpenCampground_Database_Calls'
import { camperDetailsForReservation } from '../utils/OpenCampground_Database_Calls'
import { makePayment } from '../utils/MxMerchant_Services'
import { format } from 'date-fns';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { withStyles } from '@material-ui/core/styles';
import TrendingFlatIcon from '@mui/icons-material/TrendingFlat';
import GroupIcon from '@mui/icons-material/Group';
import PetsIcon from '@mui/icons-material/Pets';
import DateRangeIcon from '@mui/icons-material/DateRange';
import FamilyRestroomIcon from '@mui/icons-material/FamilyRestroom';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import PersonIcon from '@mui/icons-material/Person';
import KeyIcon from '@mui/icons-material/Key';
import TodayIcon from '@mui/icons-material/Today';

const useStyles = theme => ({
    dialogPaper: {
        padding: theme.spacing(2),
        borderRadius: '10px',
    }
});

const theme = createTheme({
    palette: {
        primary: {
            main: '#2dcd7a',
        },
    },
});

class View extends Component {
    constructor(props) {
        super(props);
        this.state = {
            customerDetails: {},
            days: '',
            weeks: '',
            months: '',
            subTotal: '',
            monthsTotal: '',
            weeksTotal: '',
            daysTotal: '',
            taxes: [],
            totalAmount: '',
            tax: [],
            taxPercent: [],
            taxName: [],
            convenienceCharge: null,
            convenienceFees: '2.95',
            baseTotal: 0.0,
            daily: '',
            weekly: '',
            monthly: '',
            selectedPaymentValue: 'fullPayment',
            ccPOSAmount: '',
            extras: [],
            selectedExtras: [],
            selectedExtraAmount: [],
            selectedExtraCount: [],
            extraSubTotal: 0,
            counts: [],
            extraId: [],
            start: '',
            end: '',
            type2Add: false,
            userDetailsFormValid: false,
            payments: [],
            totalPaidAmount: 0.0,
            partialTotal: 0.0,
            partialBase: '',
            cashDate: format(new Date(), 'MM/dd/yyyy'),
            cashAmount: '',
            cardNumber: '',
            cardOwner: '',
            expiryMonth: '',
            expiryYear: '',
            cvv: '',
            paymentIntegration: [],
            variableCharges: [],
            reservedExtra: [],
            checkAmount: '',
            checkNumber: '',
            memo: '',
            checkDate: format(new Date(), 'MM/dd/yyyy'),
            //email
            remoteResConfirmedMailMessage: '',
            remoteResConfirmedSubject: '',
            remoteResRejectedMailMessage: '',
            remoteResRejectedSubject: '',
            reservationCancelMailMessage: '',
            reservationCancelSubject: '',
            reservationFeedbackMailMessage: '',
            reservationFeedbackSubject: '',
            sender: '',
            reply: '',
            cc: '',
            bcc: '',
            open: false,
            popupText: '',
            open1: false,
            popupText1: '',
            disabledTextFields: true,
            disabledInternalNotes: true,
            fname: null,
            lname: null,
            emailId: null,
            phoneNumber: null,
            address: null,
            city: null,
            state: null,
            zip: null,
            internalNote: null,
            amtToPay: '',
            ccfees: '',
            totalPayableAmt: '',
            discountTotal: null,
            discountPercent: null,
        };
    }

    async componentDidMount() {
        console.log("viewItem: ", this.props.viewItem)
        let paymentIntegration = await getIntegrations(this.props.serverName)
        let mailMessages = await getEmailTemplates(this.props.serverName)
        let email = await getEmails(this.props.serverName)
        //get variable charges and extras
        let variableChargesAndExtras = await getExtrasAndVariableCharges(this.props.serverName, this.props.viewItem.id)
        let extrasTotal = 0
        variableChargesAndExtras[1].forEach(extra => {
            extrasTotal += extra.charge;
        });
        variableChargesAndExtras[1].forEach(extra => {
            this.setState(prevState => ({
                selectedExtras: [...prevState.selectedExtras, extra.name],
                selectedExtraCount: [...prevState.selectedExtraCount, extra.days],
                selectedExtraAmount: [...prevState.selectedExtraAmount, extra.charge],
                extraSubTotal: extrasTotal,
                extraId: [...prevState.extraId, extra.id]
            }));
        });
        //past transsactions
        let paymentsResponse = await getPayments(this.props.serverName, this.props.viewItem.id)

        //calculate balance
        let totalPaidAmount = paymentsResponse.reduce((acc, payment) => acc + payment.amount, 0);
        let extras = await getSiteRigDiscountExtra(this.props.serverName)
        let customerDetails = await camperDetailsForReservation(this.props.serverName, this.props.viewItem.id)
        //calculate total
        let term = this.getDuration(this.props.viewItem.startdate, this.props.viewItem.enddate);
        let subTotal = 0.0
        let monthsTotal = 0
        let weeksTotal = 0
        let daysTotal = 0
        let daily = ''
        let weekly = ''
        let monthly = ''
        let discountTotal = null
        let selectedDiscount
        //get rates for selected space before this
        let spaceSpecificRatesResponse = await spaceSpecificRates(this.props.serverName, this.props.viewItem.startdate, this.props.viewItem.enddate, this.props.viewItem.sitename)
        daily = spaceSpecificRatesResponse.daily_rate
        weekly = spaceSpecificRatesResponse.weekly_rate
        monthly = spaceSpecificRatesResponse.monthly_rate
        if (term[2] > 0) {
            if (monthly > 0) {
                monthsTotal = (term[2] * monthly);
                subTotal += monthsTotal;
            } else {
                term[1] = term[1] + (term[2] * 4);
                term[2] = 0
            }
        }
        if (term[1] > 0) {
            if (weekly > 0) {
                weeksTotal = (term[1] * weekly);
                subTotal += weeksTotal;
            } else {
                term[0] = term[0] + (term[1] * 7);
                term[1] = 0
            }
        }
        if (term[0] > 0) {
            daysTotal = (term[0] * daily);
            subTotal += daysTotal;
        }

        //calculate discount
        if (this.props.viewItem.discount_id > 0) {
            //select Applied discount
            selectedDiscount = extras[2].find(
                (discount) => discount.id === this.props.viewItem.discount_id
            );
            console.log(selectedDiscount)

            if (monthsTotal > 0 && selectedDiscount.disc_appl_month) {
                discountTotal += (monthsTotal * selectedDiscount.discount_percent) / 100
            }
            if (weeksTotal > 0 && selectedDiscount.disc_appl_week) {
                discountTotal += (weeksTotal * selectedDiscount.discount_percent) / 100
            }
            if (daysTotal > 0 && selectedDiscount.disc_appl_daily) {
                discountTotal += (daysTotal * selectedDiscount.discount_percent) / 100
            }
            subTotal -= discountTotal
        }
        console.log("Discounted Value: ", discountTotal)

        this.calculateTaxAndTotal(subTotal + extrasTotal, term);
        this.setState({
            discountTotal: discountTotal,
            discountPercent: this.props.viewItem.discount_id > 0 ? selectedDiscount.discount_percent : null,
            payments: paymentsResponse,
            customerDetails: customerDetails,
            extras: extras[3],
            discounts: [2],
            daily: daily,
            weekly: weekly,
            monthly: monthly,
            days: term[0],
            weeks: term[1],
            months: term[2],
            subTotal: subTotal + extrasTotal,
            monthsTotal: monthsTotal,
            weeksTotal: weeksTotal,
            daysTotal: daysTotal,
            totalPaidAmount: totalPaidAmount,
            variableCharges: variableChargesAndExtras[0],
            reservedExtra: variableChargesAndExtras[1],
            fname: customerDetails.first_name,
            lname: customerDetails.last_name,
            emailId: customerDetails.email,
            phoneNumber: customerDetails.phone,
            address: customerDetails.address,
            city: customerDetails.city,
            state: customerDetails.state,
            zip: customerDetails.mail_code,
            internalNote: this.props.viewItem.special_request,
            remoteResConfirmedMailMessage: mailMessages[4].body,
            remoteResConfirmedSubject: email.remote_res_confirm_subject,
            remoteResRejectedMailMessage: mailMessages[5].body,
            remoteResRejectedSubject: email.remote_res_reject_subject,
            reservationCancelMailMessage: mailMessages[6].body,
            reservationCancelSubject: email.reservation_cancel_subject,
            reservationFeedbackMailMessage: mailMessages[2].body,
            reservationFeedbackSubject: email.feedback_subject,
            sender: email.sender,
            reply: email.reply,
            cc: email.cc,
            bcc: email.bcc,
            paymentIntegration: paymentIntegration,
        })
    }

    getDuration(checkIn, checkOut) {
        const startDate = new Date(checkIn);
        const endDate = new Date(checkOut);
        let totalMonths = (endDate.getFullYear() - startDate.getFullYear()) * 12;
        totalMonths += endDate.getMonth() - startDate.getMonth();
        if (endDate.getDate() < startDate.getDate()) {
            totalMonths -= 1;
        }
        const adjustedEndDate = new Date(endDate.getFullYear(), endDate.getMonth(), startDate.getDate());
        let totalDays = (endDate - adjustedEndDate) / (1000 * 60 * 60 * 24);
        const weeks = Math.floor(totalDays / 7);
        totalDays %= 7;
        return [Math.floor(totalDays), Math.floor(weeks), Math.floor(totalMonths)];
    }

    calculateTaxAndTotal = async (subTotal, term) => {
        let taxes = await getTaxes(this.props.serverName);
        let totalAmount = 0.0;
        let tax = [];
        let taxPercent = [];
        let taxName = [];
        let convenienceFees = 0.0;
        let baseTotal = 0.0;
        let variableChargeTotal = 0.0;
        //taxes
        for (let index = 0; index < taxes.length; index++) {
            const element = taxes[index];
            if (element.is_percent === 1) {
                if (element.apl_daily === 0 && element.apl_week === 0 && element.apl_month === 0) {
                    console.log("No tax")
                } else if (element.apl_daily === 1) {
                    taxPercent[index] = element.percent;
                    tax[index] = (subTotal * (element.percent / 100)).toFixed(2);
                    taxName[index] = element.name;
                }
            } else {
                let totalDays = term[0];
                if (element.apl_month === 1) {
                    if (element.monthly_charge_daily === 1) {
                        totalDays = totalDays + (term[2] * 30)
                    } else {
                        taxPercent[index] = element.amount;
                        tax[index] = (term[2] * element.amount)
                        taxName[index] = element.name;
                    }
                } else if (element.apl_week === 1) {
                    if (element.weekly_charge_daily === 1) {
                        totalDays = totalDays + (term[1] * 7)
                    } else {
                        taxPercent[index] = element.amount;
                        tax[index] = (term[1] * element.amount)
                        taxName[index] = element.name;
                    }
                }
                taxPercent[index] = element.amount;
                tax[index] = (totalDays * element.amount)
                taxName[index] = element.name;
            }
        }

        totalAmount = subTotal;
        for (let index = 0; index < tax.length; index++) {
            totalAmount = totalAmount + parseFloat(tax[index]);
        }
        baseTotal = totalAmount
        if (this.state.variableCharges.length > 0) {
            for (let index = 0; index < this.state.variableCharges.length; index++) {
                variableChargeTotal += this.state.variableCharges[index].amount;
            }
        }
        if (this.state.paymentIntegration.name === 'CampPay' && this.state.selectedPaymentValue === 'fullPayment') {
            convenienceFees = (((parseFloat(totalAmount.toFixed(2)) + parseFloat(variableChargeTotal.toFixed(2))) - this.state.totalPaidAmount.toFixed(2)) * this.state.convenienceFees / 100);
        }
        let amountToPay = (parseFloat(totalAmount.toFixed(2)) + parseFloat(variableChargeTotal.toFixed(2))) - parseFloat(this.state.totalPaidAmount.toFixed(2))
        this.setState({
            amtToPay: amountToPay,
            totalPayableAmt: this.state.paymentIntegration.name === 'CampPay' ? (parseFloat(amountToPay) + parseFloat(convenienceFees)).toFixed(2) : parseFloat(amountToPay),
            taxes: taxes,
            totalAmount: parseFloat(totalAmount.toFixed(2)) + parseFloat(variableChargeTotal.toFixed(2)),
            tax: tax,
            taxPercent: taxPercent,
            taxName: taxName,
            convenienceCharge: this.state.paymentIntegration.name === 'CampPay' ? convenienceFees.toFixed(2) : null,
            baseTotal: baseTotal.toFixed(2)
        })
    }

    handlePaymentSelect = (value) => {
        let convenienceFees = 0.0
        let partialTotal = 0.0
        let balance = 0.0
        let totalAmount = 0.0
        if (value === 'cash' || value === 'check') {
            this.setState({
                convenienceCharge: null,
                selectedPaymentValue: value
            })
        } else if (value === 'partialPayment') {
            balance = 0.0
            convenienceFees = (this.state.amtToPay * this.state.convenienceFees / 100);
            this.setState({
                partialBase: this.state.amtToPay,
                convenienceCharge: this.state.paymentIntegration.name === 'CampPay' ? convenienceFees : null,
                partialTotal: this.state.paymentIntegration.name === 'CampPay' ? parseFloat(this.state.amtToPay) + parseFloat(convenienceFees) : parseFloat(this.state.amtToPay),
                selectedPaymentValue: value
            })
        } else if (value === 'fullPayment') {
            convenienceFees = (this.state.amtToPay * this.state.convenienceFees / 100);
            this.setState({
                convenienceCharge: this.state.paymentIntegration.name === 'CampPay' ? convenienceFees : null,
                totalPayableAmt: this.state.paymentIntegration.name === 'CampPay' ? this.state.amtToPay + convenienceFees : this.state.amtToPay,
                selectedPaymentValue: value
            })
        } else if (value === 'ccPOS') {
            convenienceFees = (this.state.baseTotal * this.state.convenienceFees / 100);
            totalAmount = parseFloat(this.state.baseTotal) + parseFloat(convenienceFees.toFixed(2))
            this.setState({
                convenienceCharge: null,
                totalAmount: parseFloat(this.state.baseTotal),
                ccPOSAmount: (parseFloat(this.state.baseTotal) - parseFloat(this.state.totalPaidAmount).toFixed(2)),
                selectedPaymentValue: value
            })
        }
    }

    amountChange = (event) => {
        if (this.state.selectedPaymentValue === 'check' || this.state.selectedPaymentValue === 'cash' || this.state.selectedPaymentValue === 'ccPOS') {
            this.setState({
                [event.target.id]: event.target.value
            })
        }
        else {
            let convenience = 0.0
            let totalPartialAmount = 0.0

            convenience = (event.target.value * this.state.convenienceFees / 100)
            totalPartialAmount = parseFloat(event.target.value) + parseFloat(convenience.toFixed(2))
            this.setState({
                partialBase: event.target.value,
                convenienceCharge: this.state.paymentIntegration.name === 'CampPay' ? convenience.toFixed(2) : null,
                partialTotal: this.state.paymentIntegration.name === 'CampPay' ? totalPartialAmount.toFixed(2) : parseFloat(event.target.value),
                // totalAmount: this.state.paymentIntegration.name === 'CampPay' ? parseFloat(this.state.baseTotal) + parseFloat(convenience.toFixed(2)) : parseFloat(this.state.baseTotal)
            })
        }
    }

    getDays = () => {
        const msPerDay = 24 * 60 * 60 * 1000;
        const startDate = new Date(this.props.viewItem.startdate);
        const endDate = new Date(this.props.viewItem.enddate);
        let totalDays = Math.floor((endDate - startDate) / msPerDay);
        return totalDays;
    }

    addExtra = async (extra, count) => {
        console.log(extra.id);
        let totalDays = this.getDays();
        let extraTotal = this.state.subTotal;
        let tempTotal = 0;
        let duration = this.getDuration(this.state.checkIn, this.state.checkOut);
        let extraSubTotal = this.state.extraSubTotal;

        if (extra.extra_type === 0) {
            duration = this.getDuration(this.props.viewItem.startdate, this.props.viewItem.enddate);
            if (duration[2] > 0) tempTotal += duration[2] * extra.monthly;
            if (duration[1] > 0) tempTotal += duration[1] * extra.weekly;
            if (duration[0]) tempTotal += duration[0] * extra.daily;
        } else if (extra.extra_type === 1) {
            tempTotal = count * totalDays * extra.daily;
        } else if (extra.extra_type === 2) {
            tempTotal = (this.state.end - this.state.start) * extra.rate;
        } else {
            tempTotal = count * extra.charge;
        }

        extraTotal += tempTotal;
        extraSubTotal += tempTotal;
        this.calculateTaxAndTotal(extraTotal, duration);
        let days = this.getDays()
        let Ccount = (extra.extra_type === 0 ? totalDays : count * (extra.extra_type === 1 ? totalDays : (extra.extra_type === 2 ? (this.state.end - this.state.start) : 1)))
        let extraResponse = await addExtras(this.props.serverName, extra.id, this.props.viewItem.id, Ccount, days, tempTotal)
        console.log("extraResponse: ", extraResponse)

        this.setState((prevState) => ({
            subTotal: extraTotal,
            selectedExtras: [...prevState.selectedExtras, extra.name],
            selectedExtraCount: [...prevState.selectedExtraCount, (extra.extra_type === 0 ? totalDays : count * (extra.extra_type === 1 ? totalDays : (extra.extra_type === 2 ? (this.state.end - this.state.start) : 1)))],
            selectedExtraAmount: [...prevState.selectedExtraAmount, tempTotal],
            extraSubTotal: extraSubTotal,
            extraId: [...this.state.extraId, extra.id]
        }));
    };

    handleCountChange = (index, event) => {
        const newCounts = { ...this.state.counts, [index]: event.target.value };
        this.setState({ counts: newCounts });
    };

    handleStartEnd = (event) => {
        this.setState({
            [event.target.id]: event.target.value
        }, () => {
            this.validateAddOns();
        })
    }

    validateAddOns = () => {
        if (this.state.start > 0 && this.state.end > 0 && this.state.end > this.state.start) {
            this.setState({
                type2Add: true
            })
        } else if (this.state.type2Add) {
            this.setState({
                type2Add: false
            })
        }
    }

    userDetailsValidation = () => {
        if (this.state.selectedPaymentValue === 'check') {
            if (this.state.checkAmount && this.state.checkNumber) {
                this.setState({
                    userDetailsFormValid: true
                })
            } else {
                this.setState({
                    userDetailsFormValid: false
                })
            }
        } else if (this.state.selectedPaymentValue === 'cash') {
            if (this.state.cashAmount) {
                this.setState({
                    userDetailsFormValid: true
                })
            } else {
                this.setState({
                    userDetailsFormValid: false
                })
            }
        } else {
            if (this.state.cardNumber.length == 16 && this.state.cardOwner && this.state.expiryMonth.length == 2 &&
                this.state.expiryYear.length == 2 && (this.state.cvv.length >= 3 && this.state.cvv.length <= 4)) {
                this.setState({
                    userDetailsFormValid: true
                })
            } else if (this.state.formValid) {
                this.setState({
                    userDetailsFormValid: false
                })
            }
        }
    }

    pay = async () => {
        let amount;
        let taxtotal = 0.0;

        //tax
        for (let index = 0; index < this.state.tax.length; index++) {
            taxtotal = taxtotal + parseFloat(this.state.tax[index]);
        }

        if (this.state.selectedPaymentValue === 'fullPayment' || this.state.selectedPaymentValue === 'partialPayment') {
            amount = this.state.totalAmount
            if (this.state.selectedPaymentValue === 'fullPayment') {
                amount = this.state.totalAmount - this.state.totalPaidAmount
            } else {
                amount = this.state.partialTotal
            }

            if (this.state.paymentIntegration.name === 'CampPay') {
                let payment = await makePayment('1000017768', amount, this.state.cardNumber, this.state.expiryMonth, this.state.expiryYear, this.state.cvv, this.state.zip, this.state.address)
                console.log("payment: ", payment)

                if (payment.status === 'Approved') {
                    let cardId = await getCardType(payment.cardAccount.cardType)
                    let paymentRecords = await recordPayment(this.props.serverName, payment.amount, this.props.viewItem.id, payment.created, payment.created, payment.reference, cardId, payment.cardAccount.last4, this.state.cardOwner, format(new Date(), 'yyyy-MM-dd'), 1)
                    let variableChargeResponse = await addVariableCharge(this.props.serverName, this.props.viewItem.id, "Service Fees", this.state.convenienceCharge, new Date(), new Date())
                    if (this.state.selectedExtras.length > 0) {
                        let days = this.getDays()
                        for (let index = 0; index < this.state.selectedExtras.length; index++) {
                            let extraResponse = await addExtras(this.props.serverName, this.state.extraId[index], this.props.viewItem.id, this.state.counts[index], days, this.state.selectedExtraAmount[index])
                            console.log("extraResponse: ", extraResponse)
                        }
                    }
                    this.setState({
                        open: true,
                        popupText: 'Payment Successful'
                    })
                } else {
                    this.setState({
                        open: true,
                        popupText: 'OpenWorldPay Payment Declined. Please try again'
                    })
                }
            } else {
                let paymentResponse = await cardConnectPaymemt('177000000077', amount, this.state.cardNumber, this.state.expiryMonth, this.state.expiryYear, this.state.cvv, this.state.zip, this.state.address, this.state.fname, this.state.city)
                console.log("payment: ", paymentResponse)
                if (paymentResponse.respstat === "A") {
                    // let cardId = await getCardType(payment.cardAccount.cardType)
                    //hard coded 2 for cardId after reference number
                    let paymentRecords = await recordPayment(this.props.serverName, amount, this.props.viewItem.id, format(new Date(), 'yyyy-MM-dd'), format(new Date(), 'yyyy-MM-dd'), paymentResponse.retref, "2", paymentResponse.account.slice(-4), this.state.cardOwner, format(new Date(), 'yyyy-MM-dd'), 1)
                    console.log("payment record: ", paymentRecords)
                    if (this.state.selectedExtras.length > 0) {
                        let days = this.getDays()
                        for (let index = 0; index < this.state.selectedExtras.length; index++) {
                            let extraResponse = await addExtras(this.props.serverName, this.state.extraId[index], this.props.viewItem.id, this.state.counts[index], days, this.state.selectedExtraAmount[index])
                            console.log("extraResponse: ", extraResponse)
                        }
                    }
                    this.setState({
                        open: true,
                        popupText: 'Payment Successful'
                    })
                } else {
                    this.setState({
                        open: true,
                        popupText: 'CardConnect Payment Declined. Please try again'
                    })
                }
            }
        } else if (this.state.selectedPaymentValue === 'cash') {
            let paymentRecords = await recordPayment(this.props.serverName, this.state.cashAmount, this.props.viewItem.id, format(new Date(), 'yyyy-MM-dd'), format(new Date(), 'yyyy-MM-dd'), "Cash Payment: ", "1", "", "", this.state.cashDate.format('YYYY-MM-DD'), 0)
            console.log("payment record: ", paymentRecords)
            this.setState({
                open: true,
                popupText: 'Cash Record Added Succesfully'
            })
        } else if (this.state.selectedPaymentValue === 'check') {
            let paymentRecords = await recordPayment(this.props.serverName, this.state.checkAmount, this.props.viewItem.id, format(new Date(), 'yyyy-MM-dd'), format(new Date(), 'yyyy-MM-dd'), "Check Payment: " + this.state.memo, "2", "", "", this.state.checkDate.format('YYYY-MM-DD'), 0)
            console.log("payment record: ", paymentRecords)
            this.setState({
                open: true,
                popupText: 'Check Record Added Succesfully'
            })
        } else if (this.state.selectedPaymentValue === 'ccPOS') {
            this.setState({
                open1: true,
                popupText1: 'POS Terminal Payment In Progress... Please Do Not Close or Refersh The Tab'
            })
            //get list of terminals
            let listOfTerminals = await cardConnectGetListOfTerminals('177000000077')

            //connect
            let connectToTerminalResponse = await cardConnectConnectToTerminal('177000000077', listOfTerminals.terminals[0])

            let popupText
            if (connectToTerminalResponse.status === 200) {
                //authcard
                let paymentResponse = await cardConnectTerminalPayment('177000000077', listOfTerminals.terminals[0], this.state.ccPOSAmount * 100, connectToTerminalResponse.SessionKey)
                if (paymentResponse.errorCode === 8) {
                    popupText = 'Payment Cancelled By User'
                } else if (paymentResponse.resptext === 'Approval') {
                    //hard coded 2 for cardId after reference number
                    await recordPayment(this.props.serverName, this.state.ccPOSAmount, this.props.viewItem.id, format(new Date(), 'yyyy-MM-dd'), format(new Date(), 'yyyy-MM-dd'), "POS Terminal: " + paymentResponse.retref, "2", "", "", format(new Date(), 'yyyy-MM-dd'), 1)
                    popupText = 'POS Terminal Payment Record Added Succesfully'
                } else {
                    popupText = 'POS Terminal Payment Declined. Please try again'
                }
            } else {
                popupText = 'Cannot Connect to POS terminal. Please try again'
            }
            this.setState({
                open1: false,
                open: true,
                popupText: popupText
            })
        }
    }

    checkIn = async () => {
        let reservationCheckIn = await checkIn(this.props.serverName, this.props.viewItem.id)
        this.props.goBack();
    }

    checkOut = async () => {
        let reservationCheckOut = await checkOut(this.props.serverName, this.props.viewItem.id)
        let mailmessage = this.replaceMailTemplatePlaceHolders("feedback")
        let mailResponse = await sendMail(this.props.serverName, this.state.sender, /*this.props.viewItem.email*/"dan@opencampground.com", this.state.reservationFeedbackSubject, mailmessage, this.state.reply, this.state.cc, this.state.bcc)
        this.props.goBack();
    }

    replaceMailTemplatePlaceHolders = (type) => {
        let mailMessage
        if (type === "feedback") {
            mailMessage = this.state.reservationFeedbackMailMessage
        } else {
            mailMessage = this.state.reservationCancelMailMessage
        }
        mailMessage = mailMessage.replace('{{camper}}', this.props.viewItem.first_name + ' ' + this.props.viewItem.last_name);
        mailMessage = mailMessage.replace('{{number}}', this.props.viewItem.id);
        mailMessage = mailMessage.replace('{{start}}', this.props.viewItem.startdate);
        mailMessage = mailMessage.replace('{{departure}}', this.props.viewItem.enddate);
        return mailMessage
    }

    replaceMessageReject = () => {
        let mailMessage = this.state.remoteResRejectedMailMessage
        mailMessage = mailMessage.replace('{{camper}}', this.props.viewItem.first_name + ' ' + this.props.viewItem.last_name);
        mailMessage = mailMessage.replace('{{start}}', this.props.viewItem.startdate);
        mailMessage = mailMessage.replace('{{departure}}', this.props.viewItem.enddate);
        console.log(mailMessage)
        return mailMessage
    }

    cancel = async () => {
        let reservationCancel = await checkOut(this.props.serverName, this.props.viewItem.id)
        if (this.props.viewItem.unconfirmed_remote === 1) {
            let mailmessage = this.replaceMessageReject()
            let mailResponse = await sendMail(this.props.serverName, this.state.sender, /*this.props.viewItem.emailId*/"dan@opencampground.com", this.state.remoteResRejectedSubject, mailmessage, this.state.reply, this.state.cc, this.state.bcc)
        } else {
            let mailmessage = this.replaceMailTemplatePlaceHolders("cancel")
            let mailResponse = await sendMail(this.props.serverName, this.state.sender, /*this.props.viewItem.email*/"dan@opencampground.com", this.state.reservationCancelSubject, mailmessage, this.state.reply, this.state.cc, this.state.bcc)
        }
        this.props.goBack();
    }

    replaceMessageConfirm = () => {
        let mailMessage = this.state.remoteResConfirmedMailMessage
        mailMessage = mailMessage.replace('{{camper}}', this.props.viewItem.first_name + ' ' + this.props.viewItem.last_name);
        mailMessage = mailMessage.replace('{{number}}', this.props.viewItem.id);
        mailMessage = mailMessage.replace('{{space_name}}', this.props.viewItem.sitename);
        mailMessage = mailMessage.replace('{{start}}', this.props.viewItem.startdate);
        mailMessage = mailMessage.replace('{{departure}}', this.props.viewItem.enddate);
        mailMessage = mailMessage.replace('{{charges}}', this.props.viewItem.total + this.props.viewItem.tax_amount);
        console.log(mailMessage)
        return mailMessage
    }

    confirm = async () => {
        let reservationCancel = await confirmReservation(this.props.serverName, this.props.viewItem.id)
        let mailmessage = this.replaceMessageConfirm()
        let mailResponse = await sendMail(this.props.serverName, this.state.sender, /*this.props.viewItem.emailId*/"dan@opencampground.com", this.state.remoteResConfirmedSubject, mailmessage, this.state.reply, this.state.cc, this.state.bcc)
        this.props.goBack();
    }

    handlePaymentDetailsChange = (event) => {
        this.setState({
            [event.target.id]: event.target.value
        }, () => {
            this.userDetailsValidation();
        })
    }

    deleteExtra = async (index) => {
        console.log("extra id: ", this.state.extraId[index])
        let term = this.getDuration(this.props.viewItem.startdate, this.props.viewItem.enddate);
        this.calculateTaxAndTotal(this.state.subTotal - this.state.selectedExtraAmount[index], term);
        let array1 = [...this.state.selectedExtras];
        let array2 = [...this.state.selectedExtraCount];
        let array3 = [...this.state.selectedExtraAmount];
        array1.splice(index, 1);
        array2.splice(index, 1);
        array3.splice(index, 1);
        this.setState({
            selectedExtras: array1,
            selectedExtraCount: array2,
            selectedExtraAmount: array3,
            subTotal: this.state.subTotal - this.state.selectedExtraAmount[index]
        });
        console.log("extraId to be deleted: ", this.state.extraId[index])
        let deleteResponse = await deleteAddedExtra(this.props.serverName, this.state.extraId[index])
    }

    popupClose = async () => {
        this.setState({
            open: false
        }, () => {
            this.props.goBack()
        })
    }

    userDetailsChange = (event) => {
        this.setState({
            [event.target.id]: event.target.value
        })
    }

    updateCustomerDetails = async () => {
        this.setState({
            disabledTextFields: true
        })
        let customerUpdateResponse = await customerDetailChanges(this.props.serverName, this.state.fname, this.state.lname, this.state.emailId, this.state.phoneNumber,
            this.state.address, this.state.city, this.state.state, this.state.zip, this.props.viewItem.camper_id);
        console.log(customerUpdateResponse)
    }

    updateInternalNotes = async () => {
        this.setState({
            disabledInternalNotes: true
        })
        let changesResponse = await internalNotesChanges(this.props.serverName, this.state.internalNote, this.props.viewItem.id)
        console.log(changesResponse)
    }

    render() {
        const { classes } = this.props;
        return (
            <>
                <Dialog open={this.state.open} onClose={() => this.setState({ open: false })} classes={{ paper: classes.dialogPaper }}>
                    <DialogContent>
                        {this.state.popupText}
                    </DialogContent>
                    <DialogActions style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button onClick={() => this.popupClose()} style={{ backgroundColor: '#FDECEB', color: '#DB3737', padding: '2% 10% 2% 10%', margin: '0 0 0 2%', borderRadius: '10px', marginTop: '3%', textTransform: "capitalize" }}>
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog open={this.state.open1} onClose={() => this.setState({ open: false })} classes={{ paper: classes.dialogPaper }}>
                    <DialogContent>
                        {this.state.popupText1}
                    </DialogContent>
                    <DialogActions style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    </DialogActions>
                </Dialog>

                <Grid className='view'>
                    <Box className='box'>
                        <Typography className='reservationstypography'>Reservation Number: {this.props.viewItem.id}</Typography>
                    </Box>

                    <Box className='actionButtons'>
                        {this.props.viewItem.unconfirmed_remote === 1
                            ?
                            <>
                                <Button
                                    className='button'
                                    onClick={this.cancel}
                                >
                                    Cancel Reservation
                                </Button>
                                <Button
                                    className='button'
                                    onClick={this.confirm}
                                >
                                    Confirm Reservation
                                </Button>
                                <Button
                                    className='button'
                                    onClick={() => { this.props.goBack() }}
                                >
                                    Back
                                </Button>
                            </>
                            :
                            <>
                                {this.props.viewItem.checked_in === 1
                                    ? <Button
                                        className='button'
                                        onClick={this.checkOut}
                                    >
                                        Check Out
                                    </Button>
                                    : <Button
                                        className='button'
                                        onClick={this.checkIn}
                                    >
                                        Check In
                                    </Button>
                                }
                                <Button
                                    className='button'
                                    onClick={this.cancel}
                                >
                                    Cancel Reservation
                                </Button>
                                <Button
                                    className='button'
                                    onClick={this.sendEmail}
                                >
                                    Send Update Email
                                </Button>
                                <Button
                                    className='button'
                                    onClick={() => { this.props.goBack() }}
                                >
                                    Back
                                </Button>
                            </>
                        }
                    </Box>

                    <Box className='main'>
                        <Box className='leftRow'>
                            <Box className='userDetails'>
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <Typography className='typo'>Guest Details</Typography>
                                    {this.state.disabledTextFields
                                        ? <Button style={{ margin: '4% 4% 0 0', height: '5%', backgroundColor: '#2dcd7a', textTransform: 'capitalize' }}
                                            onClick={() => { this.setState({ disabledTextFields: false }) }}> Edit Details</Button>
                                        :
                                        <div style={{ display: 'flex', justifyContent: 'space-between', width: '30%', alignItems: 'center', margin: '1% 3% 0 0' }}>
                                            <Button style={{ margin: '4% 4% 0 0', height: '70%', backgroundColor: '#2dcd7a', textTransform: 'capitalize' }}
                                                onClick={() => {
                                                    this.setState({
                                                        disabledTextFields: true,
                                                        fname: this.state.customerDetails.first_name,
                                                        lname: this.state.customerDetails.last_name,
                                                        emailId: this.state.customerDetails.email,
                                                        phoneNumber: this.state.customerDetails.phone,
                                                        address: this.state.customerDetails.address,
                                                        city: this.state.customerDetails.city,
                                                        state: this.state.customerDetails.state,
                                                        zip: this.state.customerDetails.mail_code
                                                    })
                                                }}> Cancel</Button>
                                            <Button style={{ margin: '4% 4% 0 0', height: '70%', backgroundColor: '#2dcd7a', textTransform: 'capitalize' }}
                                                onClick={() => { this.updateCustomerDetails() }}> Save Details</Button>
                                        </div>
                                    }
                                </div>
                                <hr className='horizontalLine' />
                                <ThemeProvider theme={theme}>
                                    <div className='form-row'>
                                        <TextField id="fname" required disabled={this.state.disabledTextFields} label="First Name" type="text"
                                            className={`field ${this.state.disabledTextFields ? 'custom-disabled' : 'field'}`}
                                            value={this.state.fname}
                                            onChange={this.userDetailsChange} InputLabelProps={{ shrink: true }} />
                                        <TextField id="lname" required disabled={this.state.disabledTextFields} label="Last Name" type="text"
                                            className={`field ${this.state.disabledTextFields ? 'custom-disabled' : 'field'}`}
                                            value={this.state.lname} onChange={this.userDetailsChange} InputLabelProps={{ shrink: true }} />
                                    </div>

                                    <div className='form-row'>
                                        <TextField id="emailId" required disabled={this.state.disabledTextFields} label="Email Id" type="email"
                                            className={`field ${this.state.disabledTextFields ? 'custom-disabled' : 'field'}`}
                                            value={this.state.emailId} onChange={this.userDetailsChange} InputLabelProps={{ shrink: true }} />
                                        <TextField id="phoneNumber" required disabled={this.state.disabledTextFields} label="Phone Number" type="number"
                                            className={`field ${this.state.disabledTextFields ? 'custom-disabled' : 'field'}`}
                                            value={this.state.phoneNumber} onChange={this.userDetailsChange} InputLabelProps={{ shrink: true }} />
                                    </div>

                                    <div className='form-row-2'>
                                        <TextField id="address" required disabled={this.state.disabledTextFields} label="Address" type="text"
                                            className={`field ${this.state.disabledTextFields ? 'custom-disabled full-width' : 'full-width'}`}
                                            value={this.state.address} onChange={this.userDetailsChange} InputLabelProps={{ shrink: true }} />
                                    </div>
                                    <div className='form-row-3'>
                                        <TextField id="city" required disabled={this.state.disabledTextFields} label="City" type="text"
                                            className={`field ${this.state.disabledTextFields ? 'custom-disabled left' : 'left'}`}
                                            value={this.state.city} onChange={this.userDetailsChange} InputLabelProps={{ shrink: true }} />
                                        <TextField id="state" required disabled={this.state.disabledTextFields} label="State" type="text"
                                            className={`field ${this.state.disabledTextFields ? 'custom-disabled middle' : 'middle'}`}
                                            value={this.state.state} onChange={this.userDetailsChange} InputLabelProps={{ shrink: true }} />
                                        <TextField id="zip" required disabled={this.state.disabledTextFields} label="Zip" type="number"
                                            className={`field ${this.state.disabledTextFields ? 'custom-disabled right' : 'right'}`}
                                            value={this.state.zip} onChange={this.userDetailsChange} InputLabelProps={{ shrink: true }} />
                                    </div>
                                </ThemeProvider>
                            </Box>

                            <Box className='internalNotes'>
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <Typography className='typo'>Internal Notes</Typography>
                                    {this.state.disabledInternalNotes
                                        ? <Button style={{ margin: '4% 4% 0 0', height: '5%', backgroundColor: '#2dcd7a', textTransform: 'capitalize' }}
                                            onClick={() => { this.setState({ disabledInternalNotes: false }) }}> Edit Notes</Button>
                                        :
                                        <div style={{ display: 'flex', justifyContent: 'space-between', width: '30%', alignItems: 'center', margin: '1% 3% 0 0' }}>
                                            <Button style={{ margin: '4% 4% 0 0', height: '70%', backgroundColor: '#2dcd7a', textTransform: 'capitalize' }}
                                                onClick={() => {
                                                    this.setState({
                                                        disabledInternalNotes: true,
                                                        internalNote: this.props.viewItem.special_request
                                                    })
                                                }}> Cancel</Button>
                                            <Button style={{ margin: '4% 4% 0 0', height: '70%', backgroundColor: '#2dcd7a', textTransform: 'capitalize' }}
                                                onClick={() => { this.updateInternalNotes() }}> Save Notes</Button>
                                        </div>
                                    }
                                </div>
                                <hr className='horizontalLine' />
                                <ThemeProvider theme={theme}>
                                    <textarea rows={10} id="internalNote" onChange={this.userDetailsChange} disabled={this.state.disabledInternalNotes} value={this.state.internalNote}
                                        style={{ width: '93%', marginBottom: '2%' }}
                                        className={this.state.disabledInternalNotes ? 'custom-disabled-textArea' : ''}
                                    />
                                </ThemeProvider>
                            </Box>

                            {this.state.selectedExtras.length > 0
                                ?
                                <Box className='addOns'>
                                    <Typography className='typo'>Extras </Typography>
                                    <hr className='horizontalLine' />
                                    <ThemeProvider theme={theme}>
                                        <Box className='addOnsForm'>
                                            <Typography>Extra Name</Typography>
                                            <Typography>Count</Typography>
                                            <Typography>Total</Typography>
                                        </Box>
                                        {this.state.selectedExtras.map((extra, index) => (
                                            <Box key={index} className='addOnsForm'>
                                                <Typography>{this.state.selectedExtras[index]}</Typography>
                                                <Typography>{this.state.selectedExtraCount[index]}</Typography>
                                                <Typography>${this.state.selectedExtraAmount[index]}</Typography>
                                                <Button
                                                    style={{ backgroundColor: '#FDECEB', color: '#DB3737', borderRadius: '5px', width: '80%', marginTop: '-2%' }}
                                                    onClick={() => this.deleteExtra(index)}
                                                >
                                                    Delete
                                                </Button>
                                            </Box>
                                        ))}
                                    </ThemeProvider>
                                </Box>
                                : null
                            }

                            <Box className='addOns'>
                                <Typography className='typo'>Add-Ons</Typography>
                                <hr className='horizontalLine' />
                                <ThemeProvider theme={theme}>
                                    {this.state.extras
                                        .map((extra, index) => (
                                            <Box key={index} className='addOnsForm'>
                                                {extra.extra_type === 0
                                                    ?
                                                    <>
                                                        <Typography className='bookingLabel'>{extra.name}</Typography>
                                                        <Button
                                                            className='ab'
                                                            onClick={() => this.addExtra(extra)}
                                                        >
                                                            Add
                                                        </Button>
                                                    </>
                                                    : null
                                                }
                                                {extra.extra_type === 1 || extra.extra_type === 3
                                                    ?
                                                    <>
                                                        <Typography className='bookingLabel'>{extra.name}</Typography>
                                                        <TextField
                                                            required
                                                            label="Count"
                                                            type="number"
                                                            className='textfields'
                                                            value={this.state.counts[index] || ''}
                                                            onChange={(event) => this.handleCountChange(index, event)}
                                                        />
                                                        <Button
                                                            className='ab'
                                                            onClick={() => this.addExtra(extra, this.state.counts[index])}
                                                            disabled={!this.state.counts[index] > 0}
                                                        >
                                                            Add
                                                        </Button>
                                                    </>
                                                    : null
                                                }
                                                {extra.extra_type === 2
                                                    ?
                                                    <>
                                                        <Typography className='bookingLabel'>{extra.name}</Typography>
                                                        <TextField
                                                            required
                                                            label="Start"
                                                            id="start"
                                                            type="number"
                                                            className='textfields'
                                                            value={this.state.start || ''}
                                                            onChange={this.handleStartEnd}
                                                        />
                                                        <TextField
                                                            required
                                                            label="End"
                                                            id="end"
                                                            type="number"
                                                            className='textfields'
                                                            value={this.state.end || ''}
                                                            onChange={this.handleStartEnd}
                                                        />
                                                        <Button
                                                            className='ab'
                                                            onClick={() => this.addExtra(extra, 0, this.state.start, this.state.end)}
                                                            disabled={!this.state.type2Add}
                                                        >
                                                            Add
                                                        </Button>
                                                    </>
                                                    : null
                                                }
                                            </Box>
                                        ))}
                                </ThemeProvider>
                            </Box>

                            <Box className='payment'>
                                <Typography className='typo'>Payment Information</Typography>
                                <hr className='horizontalLine' />
                                <ThemeProvider theme={theme}>
                                    <Box className='paymentOptionWrapper'>
                                      
                                            {this.state.paymentIntegration.name === 'None'
                                                ?
                                                <>
                                                    <Box className={`paymentOption ${this.state.selectedPaymentValue === "cash" ? "activePaymentOption" : ""}`} onClick={() => { this.handlePaymentSelect("cash") }}>
                                                        Cash
                                                    </Box>
                                                    <Box className={`paymentOption ${this.state.selectedPaymentValue === "check" ? "activePaymentOption" : ""}`} onClick={() => { this.handlePaymentSelect("check") }}>
                                                        Check
                                                    </Box>
                                                </>
                                                :
                                                <>
                                                    <Box className={`paymentOption ${this.state.selectedPaymentValue === "fullPayment" ? "activePaymentOption" : ""}`} onClick={() => { this.handlePaymentSelect("fullPayment") }}>
                                                            Full Payment
                                                        </Box>
                                                        <Box className={`paymentOption ${this.state.selectedPaymentValue === "partialPayment" ? "activePaymentOption" : ""}`} onClick={() => { this.handlePaymentSelect("partialPayment") }}>
                                                            Partial Payment
                                                        </Box>
                                                        <Box className={`paymentOption ${this.state.selectedPaymentValue === "cash" ? "activePaymentOption" : ""}`} onClick={() => { this.handlePaymentSelect("cash") }}>
                                                            Cash
                                                        </Box>
                                                        <Box className={`paymentOption ${this.state.selectedPaymentValue === "check" ? "activePaymentOption" : ""}`} onClick={() => { this.handlePaymentSelect("check") }}>
                                                            Check
                                                        </Box>
                                                        {this.state.paymentIntegration.name === 'CardConnect' || this.state.paymentIntegration.name === 'CardConnect_o'
                                                            ?
                                                            <Box className={`paymentOption ${this.state.selectedPaymentValue === "ccPOS" ? "activePaymentOption" : ""}`} onClick={() => { this.handlePaymentSelect("ccPOS") }}>
                                                                Terminal
                                                            </Box>
                                                            : null
                                                        }
                                                </>
                                            }
                                    </Box>
                                    {this.state.selectedPaymentValue === 'fullPayment' || this.state.selectedPaymentValue === 'partialPayment'
                                        ? (
                                            <>
                                                {this.state.selectedPaymentValue === 'fullPayment'
                                                    ? (
                                                        <>
                                                            <div className='rows'>
                                                                <Typography className='headings'>Amount to be paid</Typography>
                                                                <TextField id="paymentAmt" required label="Amount" type="number"
                                                                    className='resRight' value={parseFloat(this.state.totalPayableAmt)} disabled={true} variant='standard' />
                                                            </div>
                                                        </>
                                                    )
                                                    : (
                                                        <>
                                                            {this.state.paymentIntegration.name === 'CampPay'
                                                                ?
                                                                <>
                                                                    <div className='rows'>
                                                                        <Typography className='headings'>Amount to be paid</Typography>
                                                                        <TextField id="paymentAmt" required label="Amount" type="number"
                                                                            className='resRight' value={this.state.partialBase} onChange={this.amountChange} />
                                                                    </div>
                                                                    <div className='rows'>
                                                                        <Typography className='headings'>Convenience fees</Typography>
                                                                        <TextField id="paymentAmt" type="number"
                                                                            className='resRight' value={this.state.convenienceCharge} disabled={true} variant='standard' />
                                                                    </div>
                                                                    <div className='rows'>
                                                                        <Typography className='headings'>Total</Typography>
                                                                        <TextField id="paymentAmt" type="number"
                                                                            className='resRight' value={this.state.partialTotal} disabled={true} variant='standard' />
                                                                    </div>
                                                                </>
                                                                :
                                                                <>
                                                                    <div className='rows'>
                                                                        <Typography className='headings'>Amount to be paid</Typography>
                                                                        <TextField id="paymentAmt" required label="Amount" type="number"
                                                                            className='resRight' value={this.state.partialTotal} onChange={this.amountChange} />
                                                                    </div>
                                                                </>
                                                            }
                                                        </>
                                                    )
                                                }
                                                <Typography className='typo'>Card Details</Typography>
                                                <hr className='horizontalLine' />
                                                <Box className='cardDetailsBox' style={{ marginBottom: "50px" }}>
                                                            <Box style={{ display: 'flex', width: "85%" }}>
                                                                <div className='row'>
                                                                    <Typography className='typo'>Card Number</Typography>
                                                                    <TextField
                                                                        InputProps={{
                                                                            startAdornment: <CreditCardIcon />
                                                                        }}
                                                                        id="cardNumber" required label="Card Number" type="number"
                                                                        className='resRight' value={this.state.cardNumber} onChange={this.handlePaymentDetailsChange} />
                                                                </div>

                                                                <div className='row'>
                                                                    <Typography className='typo'>Card Owner</Typography>
                                                                    <TextField InputProps={{
                                                                        startAdornment: <PersonIcon />
                                                                    }} id="cardOwner" required label="Card Owner" type="text"
                                                                        className='resRight' value={this.state.cardOwner} onChange={this.handlePaymentDetailsChange} />
                                                                </div>
                                                            </Box>
                                                            <Box style={{ display: 'flex', marginTop: "30px" }}>
                                                                <div className='expiryInputRow'>
                                                                    <Typography className='typo'>Expiration Date</Typography>
                                                                    <Box style={{ display: 'flex' }}>
                                                                        <TextField style={{ width: "150px" }} InputProps={{
                                                                            startAdornment: <TodayIcon />
                                                                        }} id="expiryMonth" required label="MM" type="number" placeholder="MM"
                                                                            className='expiryInput' value={this.state.expiryMonth} onChange={this.handlePaymentDetailsChange} />
                                                                        <TextField style={{ width: "150px" }} InputProps={{
                                                                            startAdornment: <DateRangeIcon />
                                                                        }} id="expiryYear" required label="YY" type="number" placeholder="YY"
                                                                            className='expiryInputYY' value={this.state.expiryYear} onChange={this.handlePaymentDetailsChange} />
                                                                    </Box>
                                                                </div>
                                                                <div className='row' style={{ marginLeft: "50px" }}>
                                                                    <Typography className='typo'>CVV</Typography>
                                                                    <TextField style={{ width: "150px" }} InputProps={{
                                                                        startAdornment: <KeyIcon />
                                                                    }} id="cvv" required label="CVV" type="number"
                                                                        className='cvvInput' value={this.state.cvv} onChange={this.handlePaymentDetailsChange} />
                                                                </div>
                                                            </Box>
                                                        </Box>
                                                <Button
                                                    className='ab'
                                                    onClick={this.pay}
                                                    disabled={!this.state.userDetailsFormValid}
                                                >
                                                    Pay
                                                </Button>
                                            </>
                                        )
                                        : null
                                    }
                                    {this.state.selectedPaymentValue === 'cash'
                                        ?
                                        <>
                                            <div className='rows'>
                                                <Typography className='headings'>Cash Amount</Typography>
                                                <TextField id="cashAmount" required label="Amount" type="number"
                                                    className='resRight' onChange={this.amountChange} />
                                            </div>
                                            <div className='rows'>
                                                <Typography className='headings'>Date</Typography>
                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <DatePicker
                                                        id='cashDate'
                                                        label='Cash Date*'
                                                        className='date'
                                                        onChange={(date) => {
                                                            this.setState({ cashDate: date }, () => {
                                                                this.userDetailsValidation();
                                                            })
                                                        }}
                                                    />
                                                </LocalizationProvider>
                                            </div>
                                            <div className='rows'>
                                                <Button
                                                    className='ab'
                                                    onClick={this.pay}
                                                    disabled={!this.state.userDetailsFormValid}
                                                >
                                                    Pay
                                                </Button>
                                            </div>
                                        </>
                                        : null
                                    }
                                    {this.state.selectedPaymentValue === 'check'
                                        ?
                                        <>
                                            <div className='rows'>
                                                <Typography className='headings'>Check Amount</Typography>
                                                <TextField id="checkAmount" required label="Amount" type="number"
                                                    className='resRight' onChange={this.amountChange} />
                                            </div>
                                            <div className='rows'>
                                                <Typography className='headings'>Check Number</Typography>
                                                <TextField id="checkNumber" required label="Number" type="number"
                                                    className='resRight' onChange={this.amountChange} />
                                            </div>
                                            <div className='rows'>
                                                <Typography className='headings'>Memo</Typography>
                                                <TextField id="memo" required label="Memo" type="text"
                                                    className='resRight' onChange={this.amountChange} />
                                            </div>
                                            <div className='rows'>
                                                <Typography className='headings'>Date</Typography>
                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <DatePicker
                                                        id='checkDate'
                                                        label='Check Date*'
                                                        className='date'
                                                        onChange={(date) => {
                                                            this.setState({ checkDate: date }, () => {
                                                                this.userDetailsValidation();
                                                            })
                                                        }}
                                                    />
                                                </LocalizationProvider>
                                            </div>
                                            <div className='rows'>
                                                <Button
                                                    className='ab'
                                                    onClick={this.pay}
                                                    disabled={!this.state.userDetailsFormValid}
                                                >
                                                    Pay
                                                </Button>
                                            </div>
                                        </>
                                        : null
                                    }
                                    {this.state.selectedPaymentValue === 'ccPOS'
                                        ?
                                        <>
                                            <div className='rows'>
                                                <Typography className='headings'>Amount to be paid</Typography>
                                                <TextField id="ccPOSAmount" required label="Amount" type="number"
                                                    className='resRight' value={this.state.ccPOSAmount} onChange={this.amountChange} />
                                            </div>
                                            <div className='rows'>
                                                <Button
                                                    className='ab'
                                                    onClick={this.pay}
                                                >
                                                    Pay
                                                </Button>
                                            </div>
                                        </>
                                        : null
                                    }
                                </ThemeProvider>
                            </Box>
                        </Box>

                        <Box className='rightRow'>
                            <Box className='bookingDetails'>
                                <Typography className='bookingTypo'>Booking Details</Typography>
                                <hr className='horizontalLine' />
                                <Box className='bookingRow'>
                                            <Box>
                                                <DateRangeIcon />
                                                <Typography className='bookingLabel'>{this.props.viewItem.startdate}</Typography>
                                            </Box>
                                            <TrendingFlatIcon />
                                            <Box className='checkout'>
                                                <DateRangeIcon />
                                                <Typography className='bookingValue'>{this.props.viewItem.enddate}</Typography>
                                            </Box>
                                        </Box>
                                        <Box className='bookingRow'>
                                            <Box>
                                                <GroupIcon />
                                                <Typography className='bookingLabel'>Guests</Typography>
                                            </Box>
                                            <Typography className='bookingValue'>{this.props.viewItem.adults}</Typography>
                                        </Box>
                                        <Box className='bookingRow'>
                                            <Box>
                                                <FamilyRestroomIcon />
                                                <Typography className='bookingLabel'>Kids</Typography>
                                            </Box>
                                            <Typography className='bookingValue'>{this.props.viewItem.kids}</Typography>
                                        </Box>
                                        <Box className='bookingRow'>
                                            <Box>
                                                <PetsIcon />
                                                <Typography className='bookingLabel'>Pets</Typography>
                                            </Box>
                                            <Typography className='bookingValue'>{this.props.viewItem.pets}</Typography>
                                        </Box>
                                <hr className='horizontalLine' />
                                <Box className='bookingRow'>
                                    <Typography className='bookingLabel'>Site Name</Typography>
                                    <Typography className='bookingValue'>{this.props.viewItem.sitename}</Typography>
                                </Box>
                                <Box className='bookingRow'>
                                    <Typography className='bookingLabel'>Site Type</Typography>
                                    <Typography className='bookingValue'>{this.props.viewItem.sitetype}</Typography>
                                </Box>
                                {this.state.months > 0
                                    ? <Box className='bookingRow'>
                                        <Typography className='bookingLabel'>Months Total ({this.state.months})</Typography>
                                        <Typography className='bookingValue'>${this.state.monthsTotal}</Typography>
                                    </Box>
                                    : null
                                }
                                {this.state.weeks > 0
                                    ? <Box className='bookingRow'>
                                        <Typography className='bookingLabel'>Weeks Total ({this.state.weeks})</Typography>
                                        <Typography className='bookingValue'>${this.state.weeksTotal}</Typography>
                                    </Box>
                                    : null
                                }
                                {this.state.days > 0
                                    ? <Box className='bookingRow'>
                                        <Typography className='bookingLabel'>Days Total ({this.state.days})</Typography>
                                        <Typography className='bookingValue'>${this.state.daysTotal}</Typography>
                                    </Box>
                                    : null
                                }
                                {this.state.discountTotal != null
                                    ? <Box className='bookingRow'>
                                        <Typography className='bookingLabel'>Discount ({this.state.discountPercent}%)</Typography>
                                        <Typography className='bookingValue'>${this.state.discountTotal}</Typography>
                                    </Box>
                                    : null
                                }
                                {Array.isArray(this.state.selectedExtras) && this.state.selectedExtras.length > 0
                                    ? <>
                                        {this.state.selectedExtras.map((extraName, index) => (
                                            <Box className='bookingRow' key={index}>
                                                <Typography className='bookingLabel'>{extraName} ({this.state.selectedExtraCount[index]})</Typography>
                                                <Typography className='bookingValue'>${this.state.selectedExtraAmount[index]}</Typography>
                                            </Box>
                                        ))}
                                    </>
                                    : null
                                }
                                <hr className='horizontalLine' />
                                <Box className='bookingRow'>
                                    <Typography className='bookingLabel' style={{ fontWeight: 'bold' }}>Sub Total</Typography>
                                    <Typography className='bookingValue' style={{ fontWeight: 'bold' }}>${this.state.subTotal}</Typography>
                                </Box>
                                <hr className='horizontalLine' />
                                {this.state.taxName.map((taxName, index) => (
                                    <Box className='bookingRow' key={index}>
                                        <Typography className='bookingLabel'>{taxName} ({this.state.taxPercent[index]}%)</Typography>
                                        <Typography className='bookingValue'>${this.state.tax[index]}</Typography>
                                    </Box>
                                ))}
                                {this.state.variableCharges.length > 0
                                    ?
                                    <>
                                        <hr className='horizontalLine' />
                                        {this.state.variableCharges.map((charges, index) => (
                                            <Box className='bookingRow' key={index}>
                                                <Typography className='bookingLabel'>{this.state.variableCharges[index].detail}</Typography>
                                                <Typography className='bookingValue'>${this.state.variableCharges[index].amount}</Typography>
                                            </Box>
                                        ))}
                                    </>
                                    : null
                                }
                                <hr className='horizontalLine' />

                                <Box className='bookingRow'>
                                    <Typography className='bookingLabel' style={{ fontWeight: 'bold' }}>Total</Typography>
                                    <Typography className='bookingValue' style={{ fontWeight: 'bold' }}>${this.state.totalAmount}</Typography>
                                </Box>

                                <hr className='horizontalLine' />
                                <Box className='bookingRow'>
                                    <Typography className='bookingLabel'>Payments</Typography>
                                </Box>
                                {/* <hr className='horizontalLine' /> */}
                                {this.state.payments.length > 0
                                    ? <>
                                        {this.state.payments.map((payment, index) => (
                                            <Box className='bookingRow' key={index}>
                                                <Typography className='bookingLabel'>{payment.memo}</Typography>
                                                <Typography className='bookingValue'>${payment.amount}</Typography>
                                            </Box>
                                        ))}
                                    </>
                                    : null
                                }

                                <hr className='horizontalLine' />
                                {this.state.totalPaidAmount > 0.0
                                    ?
                                    <>
                                        <Box className='bookingRow' >
                                            <Typography className='bookingLabel' style={{ fontWeight: 'bold', marginBottom: '4%' }}>Amount Due</Typography>
                                            <Typography className='bookingValue' style={{ fontWeight: 'bold' }}>${(this.state.totalAmount - this.state.totalPaidAmount).toFixed(2)}</Typography>
                                        </Box>
                                    </>
                                    : null}
                            </Box>
                        </Box>
                    </Box>
                </Grid>
            </>
        )
    };
};
export default withStyles(useStyles)(View);