import { Box, Button, Checkbox, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import UserIcon from '../assets/send_email.png'
import PhoneIcon from '../assets/phone.png'
import EmailIcon from '../assets/email_icon.png'
import LocationIcon from '../assets/location.png'
import VehicleIcon from '../assets/vehicle.png'
import AddItem from '../assets/+ Add Item.png'
import NotesIcon from '../assets/Notes.png'
import ReservationIcon from '../assets/reservation.png'
import CamperIcon from '../assets/Camper.png'
import SpaceIcon from '../assets/space.png'
import GroupsIcon from '../assets/groups.png'
import AdultsIcon from '../assets/adults.png'
import ChildrenIcon from '../assets/children.png'
import PetsIcon from '../assets/Pets.png'
import BillIcon from '../assets/Bill.png'
import AVGIcon from '../assets/avg available.png'
import BalanceIcon from '../assets/balance_due.png'

import '../CSS/GuestDetail.css';

const GuestDetail = ({ backToGuest }) => {
    return (
        <div className='guest-detail'>

            <div style={{ display: 'flex', justifyContent: 'flex-end', marginRight: "4%", marginTop: "-35px" }}>
                <Button startIcon={<ArrowBackIcon />} style={{ marginRight: '2%', backgroundColor: "rgb(153, 153, 153)", color: "#FFF" }} onClick={() => {
                    backToGuest()
                }}>Guests</Button>
                <Button className='resButton' style={{ marginRight: '2%' }} onClick={() => { }}>Add New Customer</Button>
                <Button className='resButton' style={{ marginRight: '-2%' }} onClick={() => { }}>Print</Button>
            </div>
            <div style={{ marginTop: "20px" }}>
                <div class="container">
                    <div style={{ width: "70%" }}>
                        <div class="reservation-details current-reservation">
                            <h2>Current Reservations</h2>
                            <div class="reservation-info">
                                <p style={{ fontSize: "18px" }}><strong>Space # 35 </strong><span class="reserved">Reserved</span></p>
                                <p style={{ marginLeft: "40px", fontSize: "18px", color: "#28a745" }}>Reservation # RX3091146</p>
                            </div>
                            <div class="reservation-dates">
                                <p><span><img src={ReservationIcon} />Reservation Date:</span> March 5th - 12th/2024</p>
                                <p><span><img src={CamperIcon} />Rig Type:</span> Class A</p>
                                <p><span><img src={SpaceIcon} />Space:</span> 35</p>
                                <p><span><img src={GroupsIcon} />Group:</span> N/A</p>
                                <p><span><img src={AdultsIcon} />Special:</span> N/A</p>
                                <p><span><img src={ChildrenIcon} />Adults:</span> 2</p>
                                <p><span><img src={PetsIcon} />Children:</span> 2</p>
                                <p><span><img src={ReservationIcon} />Pets:</span> 1</p>
                            </div>

                            <div style={{ margin: "20px 0px" }}>
                                <FormControl>
                                    <InputLabel >Discount</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="siteSelect"
                                        label="Discount"
                                        sx={{ width: "200px", marginRight: "20px" }}
                                    >
                                        <MenuItem >
                                            5%
                                        </MenuItem>
                                        <MenuItem >
                                            10%
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                                <FormControl>
                                    <InputLabel >Recommended By</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="siteSelect"
                                        label="Recommended By"
                                        sx={{ width: "200px" }}
                                    >
                                        <MenuItem >
                                            Test
                                        </MenuItem>
                                        <MenuItem >
                                            Test 2
                                        </MenuItem>
                                    </Select>
                                </FormControl>


                            </div>
                            <div class="extras">

                                <p style={{ marginRight: "30px" }}>Wifi-Use: <Checkbox /></p>
                                <p style={{ marginRight: "30px" }}>Extra 1: <Checkbox /></p>
                                <p style={{ marginRight: "30px" }}>Extra 2: <Checkbox /></p>
                                <p>Extra 4: <Checkbox /></p>
                            </div>

                            <div class="payment-info">
                                <div style={{ display: "flex", alignItems: "center", padding: "10px", minWidth: "200px", background: "#E6F5ED", borderRadius: "8px" }}>
                                    <div>
                                        <div style={{ marginRight: "20px", borderRadius: "12px", padding: "10px", background: "#FFF", display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                            <img src={BillIcon} />
                                        </div>
                                    </div>
                                    <div>
                                        <div>
                                            Total:
                                            <h2>
                                                $69.99
                                            </h2>
                                        </div>
                                        <div>
                                            <span class="discount">(after 20% Discount)</span>
                                        </div>
                                    </div>
                                </div>
                                <div style={{ display: "flex", alignItems: "center", padding: "10px", minWidth: "200px", background: "#EBF0FE",  borderRadius: "8px" }}>
                                    <div>
                                        <div style={{ marginRight: "20px", borderRadius: "12px", padding: "10px", background: "#FFF", display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                            <img src={AVGIcon} />
                                        </div>
                                    </div>
                                    <div>
                                        <div>
                                            Paid:
                                        </div>
                                        <h2>
                                            $20
                                        </h2>
                                    </div>
                                </div>
                                <div style={{ display: "flex", alignItems: "center", padding: "10px", minWidth: "200px", background: "#F0DDDE",  borderRadius: "8px" }}>
                                    <div>
                                        <div style={{ marginRight: "20px", borderRadius: "12px", padding: "10px", background: "#FFF", display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                            <img src={BalanceIcon} />
                                        </div>
                                    </div>
                                    <div>
                                        <div>
                                            Balance Due:
                                        </div>
                                        <h2>
                                            $49.99
                                        </h2>
                                    </div>
                                </div>
                            </div>

                            <Button className='resButton' style={{ marginTop: "20px" }} onClick={() => { }}>Record a Payment</Button>
                        </div>

                        <div class="reservation-details past-reservations">
                            <div style={{ display: "flex", justifyContent: 'space-between', marginBottom: '20px' }}>
                                <h2 style={{ margin: "0px" }}>Past Reservations</h2>
                                <div style={{ display: 'flex' }}>
                                    <Button className='resButton' style={{ marginRight: '40px', whiteSpace: "nowrap", backgroundColor: "rgb(153, 153, 153)" }} onClick={() => { }}>1 Feb - 5 Mar</Button>
                                    <Button className='resButton' style={{ marginRight: '2%', whiteSpace: "nowrap" }} onClick={() => { }}>View All</Button>
                                </div>
                            </div>
                            <Grid>
                                <Box className='reservationTableHeadingBox'>
                                    <Typography className='headerTypo'>Start Date</Typography>
                                    <Typography className='headerTypo'>End Date</Typography>
                                    <Typography className='headerTypo'>Site #</Typography>
                                    <Typography className='headerTypo'>Reservation #</Typography>
                                    <Typography className='headerTypo'>Rig Type</Typography>
                                    <Typography className='headerTypo'>Amount Paid</Typography>
                                    <Typography className='headerTypo'>Status</Typography>
                                    <Typography className='headerTypo'>Action</Typography>
                                </Box>
                                <Box className='tableBox'>
                                    <table>
                                        <tbody>
                                            <tr className='reservationRows'>
                                                <td>02/04/2024</td>
                                                <td>02/06/2024</td>
                                                <td>35</td>
                                                <td>RX3091146</td>
                                                <td>Class A</td>
                                                <td>$79.95</td>
                                                <td><span class="reserved">Reserved</span></td>
                                                <td>...</td>
                                            </tr>
                                            <tr className='reservationRows'>
                                                <td>02/04/2024</td>
                                                <td>02/06/2024</td>
                                                <td>35</td>
                                                <td>RX3091146</td>
                                                <td>Class A</td>
                                                <td>$79.95</td>
                                                <td><span class="reserved">Reserved</span></td>
                                                <td>...</td>
                                            </tr>
                                            <tr className='reservationRows'>
                                                <td>02/04/2024</td>
                                                <td>02/06/2024</td>
                                                <td>35</td>
                                                <td>RX3091146</td>
                                                <td>Class A</td>
                                                <td>$79.95</td>
                                                <td><span class="reserved">Reserved</span></td>
                                                <td>...</td>
                                            </tr>
                                            <tr className='reservationRows'>
                                                <td>02/04/2024</td>
                                                <td>02/06/2024</td>
                                                <td>35</td>
                                                <td>RX3091146</td>
                                                <td>Class A</td>
                                                <td>$79.95</td>
                                                <td><span class="reserved">Reserved</span></td>
                                                <td>...</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </Box>
                            </Grid>
                        </div>
                    </div>

                    <aside>
                        <div class="customer-info">
                            <h3 className='imageFlexBoxBetween'>Amy Cross <img src={UserIcon} width="35px" /></h3>
                            <p className='imageFlexBoxStart' style={{ fontSize: "14px" }}> <img width="40px" src={PhoneIcon} /> +1 383 383 3333</p>
                            <p className='imageFlexBoxStart' style={{ fontSize: "14px" }}> <img width="40px" src={EmailIcon} /> anaana@gmail.com</p>
                            <p className='imageFlexBoxStart' style={{ fontSize: "14px" }}> <img width="40px" src={LocationIcon} /> New Avenue Street, Toronto, Canada M2 121231</p>
                            <Button style={{ textTransform: 'capitalize', padding: "0px 15px", height: "35px", fontSize: '14px', marginRight: '20px', backgroundColor: "rgb(153, 153, 153)", color: "#FFF" }} onClick={() => {
                                backToGuest()
                            }}>Edit Profile</Button>
                            <Button style={{ textTransform: 'capitalize', padding: "0px 15px", height: "35px", fontSize: '14px', backgroundColor: "#db3737", color: "#FFF" }} onClick={() => {
                                backToGuest()
                            }}>Archive</Button>

                        </div>

                        <div class="vehicle-details">
                            <h3 className='imageFlexBoxBetween'> <img src={VehicleIcon} width="40px" /> Vehicle Details  <img src={AddItem} /></h3>
                            <div className='fields'>
                                <div>
                                    <p>Vehicle State: </p>
                                    <input style={{ textAlign: 'center' }} value="Texas" />
                                </div>
                                <div>
                                    <p>Vehicle License #: </p>
                                    <input style={{ textAlign: 'center' }} value="TX 5455" />
                                </div>
                                <div>
                                    <p>Length: </p>
                                    <input style={{ textAlign: 'center' }} value="125 ft" />
                                </div>
                                <div>
                                    <p>Slides: </p>
                                    <input style={{ textAlign: 'center' }} value="3" />
                                </div>
                                <div>
                                    <p>Vehicle Color: </p>
                                    <input style={{ textAlign: 'center' }} value="White" />
                                </div>
                                <div>
                                    <p>Vehicle Type:</p>
                                    <input style={{ textAlign: 'center' }} value="Class C" />
                                </div>
                            </div>
                        </div>

                        <div class="private-notes">
                            <h3 className='imageFlexBoxBetween'><img src={NotesIcon} width="40px" /> Private Notes <img src={AddItem} /></h3>
                            <p style={{ minHeight: "100px" }}>Lorem Ipsum lorem ipsum lorem ipsum</p>
                        </div>
                    </aside>
                </div>
            </div>
        </div>

    )
}

export default GuestDetail