import React, { Component } from 'react';
import { Grid, Button, Typography, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@material-ui/core';
import '../CSS/Settings.css';
import { deleteRecord, getDiscount, createNewDiscount, updateDiscount } from '../utils/OpenCampground_Database_Calls'
import TextField from '@mui/material/TextField';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Dialog, DialogContent } from '@mui/material';

const theme = createTheme({
    palette: {
        primary: {
            main: '#2dcd7a',
        },
    },
});

class Discounts extends Component {
    constructor(props) {
        super(props);
        this.state = {
            discounts: [],
            discountsUpdate: false,
            discountName: '',
            duration: '',
            discountPercent: '',
            amt: '',
            amtDay: '',
            amtWeek: '',
            amtMonth: '',
            sunday: true,
            monday: true,
            tuesday: true,
            wednesday: true,
            thursday: true,
            friday: true,
            saturday: true,
            daily: true,
            week: false,
            month: false,
            seasonal: false,
            showRemote: false,
            active: true,
            formValid: false,
            editingDiscountId: null,
        };
    }

    async componentDidMount() {
        this.discounts()
    }

    handleChange = (event) => {
        if (event.target.id === "daily" || event.target.id === "week" || event.target.id === "month" || event.target.id === "seasonal" || event.target.id === "active" || event.target.id === "showRemote") {
            this.setState({
                [event.target.id]: event.target.checked
            })
        } else if (event.target.id === "sunday" || event.target.id === "monday" || event.target.id === "tuesday" || event.target.id === "wednesday" || event.target.id === "thursday" || event.target.id === "friday" || event.target.id === "saturday") {
            this.setState({
                [event.target.id]: event.target.checked
            })
        } else {
            this.setState({
                [event.target.id]: event.target.value
            }, () => {
                this.formValidation();
            })
        }
    }

    formValidation = () => {
        if (this.state.discountName && this.state.duration && this.state.discountPercent && this.state.amt && this.state.amtDay && this.state.amtWeek && this.state.amtMonth) {
            this.setState({
                formValid: true
            })
        } else if (this.state.formValid) {
            this.setState({
                formValid: false
            })
        }
    }

    deleteRecord = async (data) => {

        let deleteResponse = await deleteRecord(this.props.serverName, "discounts", data.id)
        this.setState({
            discountsUpdate: true
        })
        this.discounts()

    }

    discounts = async () => {
        if (this.state.discountsUpdate) {
            let getDiscountData = await getDiscount(this.props.serverName)
            this.setState({
                discounts: getDiscountData
            })
        } else {
            if (this.state.discounts.length > 0) {
            } else {
                let getDiscountData = await getDiscount(this.props.serverName)
                this.setState({
                    discounts: getDiscountData
                })
            }
        }
    }

    addNew = async () => {
        let createNewDiscountResponse = await createNewDiscount(this.props.serverName, this.state.discountName, this.state.duration, this.state.sunday ? "1" : "0",
            this.state.monday ? "1" : "0", this.state.tuesday ? "1" : "0", this.state.wednesday ? "1" : "0", this.state.thursday ? "1" : "0",
            this.state.friday ? "1" : "0", this.state.saturday ? "1" : "0", this.state.discountPercent, this.state.daily ? "1" : "0",
            this.state.week ? "1" : "0", this.state.month ? "1" : "0", this.state.seasonal ? "1" : "0", this.state.amt,
            this.state.amtDay, this.state.amtWeek, this.state.amtMonth)
        this.setState({
            discountsUpdate: true,
            addNew: false
        })
        this.discounts()
    }

    startEditing = (discount) => {
        this.setState({
            editingDiscountId: discount.id,
            discountName: discount.name,
            duration: discount.duration,
            discountPercent: discount.discount_percent,
            amt: discount.amount,
            amtDay: discount.amount_daily,
            amtWeek: discount.amount_weekly,
            amtMonth: discount.amount_monthly,
            sunday: discount.sunday ? 'Yes' : 'No',
            monday: discount.monday ? 'Yes' : 'No',
            tuesday: discount.tuesday ? 'Yes' : 'No',
            wednesday: discount.wednesday ? 'Yes' : 'No',
            thursday: discount.thursday ? 'Yes' : 'No',
            friday: discount.friday ? 'Yes' : 'No',
            saturday: discount.saturday ? 'Yes' : 'No',
            daily: discount.disc_appl_daily ? 'Yes' : 'No',
            week: discount.disc_appl_week ? 'Yes' : 'No',
            month: discount.disc_appl_month ? 'Yes' : 'No',
            seasonal: discount.disc_appl_seasonal ? 'Yes' : 'No',
            showRemote: discount.show_on_remote ? 'Yes' : 'No',
            active: discount.active ? 'Yes' : 'No',
        });
    }

    saveEdits = async () => {
        console.log("active: ",this.state.active ? '1' : '0')
        let discountResponse = await updateDiscount(this.props.serverName, this.state.editingDiscountId, this.state.discountName, this.state.duration, this.state.discountPercent,
            this.state.amt, this.state.amtDay, this.state.amtWeek, this.state.amtMonth, this.state.sunday? '1' : '0', this.state.monday? '1' : '0', 
            this.state.tuesday? '1' : '0'? '1' : '0', this.state.wednesday? '1' : '0', this.state.thursday? '1' : '0', this.state.friday? '1' : '0', this.state.saturday? '1' : '0', 
            this.state.daily? '1' : '0', this.state.week? '1' : '0', this.state.month? '1' : '0', this.state.seasonal? '1' : '0', this.state.showRemote? '1' : '0', this.state.active? '1' : '0');
        this.setState({
            discountName: '',
            duration: '',
            discountPercent: '',
            amt: '',
            amtDay: '',
            amtWeek: '',
            amtMonth: '',
            sunday: true,
            monday: true,
            tuesday: true,
            wednesday: true,
            thursday: true,
            friday: true,
            saturday: true,
            daily: true,
            week: false,
            month: false,
            seasonal: false,
            showRemote: false,
            active: true,
            editingDiscountId: null,
            discountsUpdate: true,
         });
        this.discounts();
    }

    render() {
        return (
            <ThemeProvider theme={theme}>
                <Button className='resButton' onClick={() => { this.setState({ addNew: true }) }} style={{ backgroundColor: '#2dcd7a', color: '#000000', marginBottom: '2%', textTransform: "capitalize" }}>Add New Discount</Button>
                {this.state.addNew || this.state.editingDiscountId
                    ?
                    <Dialog open={this.state.addNew || this.state.editingDiscountId} onClose={()=>{this.setState({ addNew: false,editingDiscountId:null });}} maxWidth="sm" fullWidth>
                        <DialogContent dividers>
                            <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
                            <h2 style={{margin:"0px",marginBottom:"10px"}}>{this.state.editingDiscountId?"Update Discount":"Add Discount"}</h2>
                        <div style={{ display: 'flex', alignItems: 'center', gap: '16px', marginLeft: '2%' }}>
                            <TextField
                            style={{width:"50%"}}
                                id="discountName"
                                required
                                label="Name"
                                value={this.state.discountName}
                                onChange={this.handleChange}
                            />
                            <TextField
                            style={{width:"50%"}}
                                id="duration"
                                required
                                label="Duration"
                                value={this.state.duration}
                                onChange={this.handleChange}
                            />
                           
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', gap: '16px', marginLeft: '2%' }}>
                        <TextField
                        style={{width:"50%"}}
                                id="discountPercent"
                                required
                                label="Percentage"
                                value={this.state.discountPercent}
                                onChange={this.handleChange}
                            />
                             <TextField
                             style={{width:"50%"}}
                                id="amt"
                                required
                                label="Amount"
                                value={this.state.amt}
                                onChange={this.handleChange}
                            />
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', gap: '16px', marginLeft: '2%' }}>
                           
                            <TextField
                                id="amtDay"
                                required
                                label="Amount Daily"
                                value={this.state.amtDay}
                                onChange={this.handleChange}
                            />
                            <TextField
                                id="amtWeek"
                                required
                                label="Amount Weekely"
                                value={this.state.amtWeek}
                                onChange={this.handleChange}
                            />
                            <TextField
                                id="amtMonth"
                                required
                                label="Amount Monthly"
                                value={this.state.amtMonth}
                                onChange={this.handleChange}
                            />
                        </div>
                        <div style={{ display: 'grid', gridTemplateColumns:"1fr 1fr", gap: '0px 16px', marginLeft: '2%' }}>
                            <FormControlLabel
                                label={"Applies to Daily"}
                                control={
                                    <Checkbox
                                        id="daily"
                                        checked={this.state.daily=="0" || this.state.daily=="No"?false:true}
                                        onChange={this.handleChange}
                                    />
                                }
                            />
                            <FormControlLabel
                                label={"Applies to Weekly"}
                                control={
                                    <Checkbox
                                        id="week"
                                        checked={this.state.week=="0" || this.state.week=="No"?false:true}
                                        onChange={this.handleChange}
                                    />
                                }
                            />
                                                    <FormControlLabel
                                label={"Applies to Monthly"}
                                control={
                                    <Checkbox
                                        id="month"
                                        checked={this.state.month=="0" || this.state.month=="No"?false:true}
                                        onChange={this.handleChange}
                                    />
                                }
                            />
                            <FormControlLabel
                                label={"Applies to Seasonal"}
                                control={
                                    <Checkbox
                                        id="seasonal"
                                        checked={this.state.seasonal=="0" || this.state.seasonal=="No"?false:true}
                                        onChange={this.handleChange}
                                    />
                                }
                            />
                         
                        </div>
                      
                        <div style={{ display: 'grid', gridTemplateColumns:"1fr 1fr 1fr", gap: '0px 16px', marginLeft: '2%' }}>
                            <FormControlLabel
                                label={"Sunday"}
                                control={
                                    <Checkbox
                                        id="sunday"
                                        checked={this.state.sunday=="0" || this.state.sunday=="No"?false:true}
                                        onChange={this.handleChange}
                                    />
                                }
                            />
                            <FormControlLabel
                                label={"Monday"}
                                control={
                                    <Checkbox
                                        id="monday"
                                        checked={this.state.monday=="0" || this.state.monday=="No"?false:true}
                                        onChange={this.handleChange}
                                    />
                                }
                            />
                            <FormControlLabel
                                label={"Tuesday"}
                                control={
                                    <Checkbox
                                        id="tuesday"
                                        checked={this.state.tuesday=="0" || this.state.tuesday=="No"?false:true}
                                        onChange={this.handleChange}
                                    />
                                }
                            />
                            <FormControlLabel
                                label={"Wednesday"}
                                control={
                                    <Checkbox
                                        id="wednesday"
                                        checked={this.state.wednesday=="0" || this.state.wednesday=="No"?false:true}
                                        onChange={this.handleChange}
                                    />
                                }
                            />
                            <FormControlLabel
                                label={"Thursday"}
                                control={
                                    <Checkbox
                                        id="thursday"
                                        checked={this.state.thursday=="0" || this.state.thursday=="No"?false:true}
                                        onChange={this.handleChange}
                                    />
                                }
                            />
                            <FormControlLabel
                                label={"Friday"}
                                control={
                                    <Checkbox
                                        id="friday"
                                        checked={this.state.friday=="0" || this.state.friday=="No"?false:true}
                                        onChange={this.handleChange}
                                    />
                                }
                            />
                            <FormControlLabel
                                label={"Saturday"}
                                control={
                                    <Checkbox
                                        id="saturday"
                                        checked={this.state.saturday=="0" || this.state.saturday=="No"?false:true}
                                        onChange={this.handleChange}
                                    />
                                }
                            />
                        </div>
                        <div style={{ display: 'flex',justifyContent:"center", alignItems: 'center', gap: '16px', marginLeft: '2%' }}>
                            <Button
                                style={{ backgroundColor: '#2dcd7a', color: '#000000' }}
                                onClick={this.state.addNew?this.addNew:this.saveEdits}
                                disabled={this.state.addNew?!this.state.formValid:false}
                            >
                                {this.state.addNew?"Add":"Update"}
                            </Button>
                            <Button
                                style={{backgroundColor: '#FDECEB', color: '#DB3737' }}
                                onClick={() => { this.setState({ addNew: false,editingDiscountId:null });
                                this.setState({
                                    discountName: '',
                                    duration: '',
                                    discountPercent: '',
                                    amt: '',
                                    amtDay: '',
                                    amtWeek: '',
                                    amtMonth: '',
                                    sunday: true,
                                    monday: true,
                                    tuesday: true,
                                    wednesday: true,
                                    thursday: true,
                                    friday: true,
                                    saturday: true,
                                    daily: true,
                                    week: false,
                                    month: false,
                                    seasonal: false,
                                    showRemote: false,
                                    active: true,
                                    editingDiscountId: null,
                                 }); }}
                            >
                                Cancel
                            </Button>
                        </div>
                            </div>
                        </DialogContent>
                    </Dialog>:""
    }
                    <TableContainer component={Paper} style={{ width: '90%', margin: '0 auto' }}>
                        <Table >
                            <TableHead>
                                <TableRow>
                                    <TableCell className='tableCell'>Name</TableCell>
                                    <TableCell className='tableCell'>Duration</TableCell>
                                    <TableCell className='tableCell'>Percent</TableCell>
                                    <TableCell className='tableCell'>Daily</TableCell>
                                    <TableCell className='tableCell'>Weekly</TableCell>
                                    <TableCell className='tableCell'>Monthly</TableCell>
                                    <TableCell className='tableCell'>Seasonal</TableCell>
                                    <TableCell className='tableCell'>Amount Once</TableCell>
                                    <TableCell className='tableCell'>Day</TableCell>
                                    <TableCell className='tableCell'>Week</TableCell>
                                    <TableCell className='tableCell'>Month</TableCell>
                                    <TableCell className='tableCell'>S</TableCell>
                                    <TableCell className='tableCell'>M</TableCell>
                                    <TableCell className='tableCell'>T</TableCell>
                                    <TableCell className='tableCell'>W</TableCell>
                                    <TableCell className='tableCell'>T</TableCell>
                                    <TableCell className='tableCell'>F</TableCell>
                                    <TableCell className='tableCell'>S</TableCell>
                                    <TableCell className='tableCell'>Show on Remote</TableCell>
                                    <TableCell className='tableCell'>Active</TableCell>
                                    <TableCell className='tableCell'>Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.discounts.map((discount, index) => (
                                    <TableRow key={index}>
                                        <TableCell className='tableCell'>
                                            
                                                {discount.name}
                                        </TableCell>
                                        <TableCell className='tableCell'>
                                            {discount.duration}
                                        </TableCell>
                                        <TableCell className='tableCell'>
                                            
                                                {discount.discount_percent}%
                                        </TableCell>
                                        <TableCell className='tableCell'>
                                            {discount.disc_appl_daily ? 'Yes' : 'No'}
                                        </TableCell>
                                        <TableCell className='tableCell'>
                                            
                                                {discount.disc_appl_week ? 'Yes' : 'No'}
                                        </TableCell>
                                        <TableCell className='tableCell'>
                                            
                                               {discount.disc_appl_month ? 'Yes' : 'No'}
                                        </TableCell>
                                        <TableCell className='tableCell'>
                                            
                                               {discount.disc_appl_seasonal ? 'Yes' : 'No'}
                                        </TableCell>
                                        <TableCell className='tableCell'>
                                            $
                                                {discount.amount}
                                        </TableCell>
                                        <TableCell className='tableCell'>
                                            
                                               {discount.amount_daily}
                                        </TableCell>
                                        <TableCell className='tableCell'>
                                            
                                                {discount.amount_weekly}
                                        </TableCell>
                                        <TableCell className='tableCell'>
                                            
                                                {discount.amount_monthly}
                                        </TableCell>
                                        <TableCell className='tableCell'>
                                            
                                                {(discount.sunday ? 'Yes' : 'No')}
                                        </TableCell>
                                        <TableCell className='tableCell'>
                                            
                                               {(discount.monday ? 'Yes' : 'No')}
                                        </TableCell>
                                        <TableCell className='tableCell'>
                                            
                                               {(discount.tuesday ? 'Yes' : 'No')}
                                        </TableCell>
                                        <TableCell className='tableCell'>
                                            
                                               {(discount.wednesday ? 'Yes' : 'No')}
                                        </TableCell>
                                        <TableCell className='tableCell'>
                                            
                                                {(discount.thursday ? 'Yes' : 'No')}
                                        </TableCell>
                                        <TableCell className='tableCell'>
                                            
                                               {(discount.friday ? 'Yes' : 'No')}
                                        </TableCell>
                                        <TableCell className='tableCell'>
                                            
                                                {(discount.saturday ? 'Yes' : 'No')}
                                        </TableCell>
                                        <TableCell className='tableCell'>
                                            
                                                {(discount.show_on_remote ? 'Yes' : 'No')}
                                        </TableCell>
                                        <TableCell className='tableCell'>
                                            
                                               {(discount.active ? 'Yes' : 'No')}
                                        </TableCell>
                                        <TableCell className='tableCell'>
                                                 <div>
                                                    <Button onClick={() => this.startEditing(discount)} className='viewButtons' style={{ backgroundColor: '#d4d4d4', color: 'black'}}>Edit</Button>
                                                    <Button onClick={() => this.deleteRecord(discount)} className='viewButtons' style={{ marginTop: '10%' }}>Delete</Button>
                                                </div>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
            </ThemeProvider>

        );
    };
};
export default Discounts;