import React, { useState } from "react";
import { Grid, ThemeProvider } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import { SingleInputDateRangeField } from "@mui/x-date-pickers-pro/SingleInputDateRangeField";
import { createTheme, InputLabel, Select, TextField } from "@material-ui/core";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from "@material-ui/core";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { getSpaces } from "../utils/OpenCampground_Database_Calls";
import { Login } from "@mui/icons-material";
import dayjs from "dayjs";
import { DatePicker } from "@mui/x-date-pickers";

function SearchForm({ checkIn, checkOut, guests, siteType: siteTypes, rigType: rigT, pets: pet, slides: slide, children: childrens, rigLength: rigLengths, sites, rigs, serverName, updateSpace }) {
  console.log(siteTypes);

  const [inOutDate, setInOutDate] = useState([dayjs(new Date(checkIn)), dayjs(new Date(checkOut))])
  const [anchorEl, setAnchorEl] = useState(null);
  const [adults, setAdults] = useState(Number(guests));
  const [children, setChildren] = useState(Number(childrens));
  const [pets, setPets] = useState(pet);
  const [summary, setSummary] = useState(`${guests} Adults | ${childrens} Children`);
  const [filterAnchorEl, setFilterAnchorEl] = useState(null);
  const [siteType, setSiteType] = useState(siteTypes);
  const [rigType, setRigType] = useState(rigT);
  const [rigLength, setRigLength] = useState(rigLengths);
  const [slides, setSlides] = useState(slide);
  const [discount, setDiscount] = useState("");
  const [recommendedBy, setRecommendedBy] = useState("");
  const [wifi, setWifi] = useState(false);
  const [extraPerson, setExtraPerson] = useState(false);
  const [boatSlip, setBoatSlip] = useState(false);

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleDone = () => {
    setSummary(`${adults} Adults | ${children} Children`);
    handleCloseMenu();
  };

  const handleTogglePet = () => {
    setPets(!pets);
  };

  const handleOpenFilterMenu = (event) => {
    setFilterAnchorEl(event.currentTarget);
  };

  const handleCloseFilterMenu = () => {
    setFilterAnchorEl(null);
  };

  const formatDate = (date) => {
    return dayjs(date).format('YYYY-MM-DD');
  };



  const handleSearch = async () => {
    const newSpaceData = await getSpaces(serverName, inOutDate[0] ? formatDate(inOutDate[0]) : "", inOutDate[1] ? formatDate(inOutDate[1]) : "")
    console.log(newSpaceData)
    let filteredSpacesData
    if (siteType === 'Any') {
      filteredSpacesData = newSpaceData
    } else {
      filteredSpacesData = newSpaceData.filter(space => space.sitetype_name === siteType);
    }
    console.log("Filtered Space Date: ", filteredSpacesData)
    updateSpace(filteredSpacesData)
  };


  const style = {
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "#2dcd7a", // Border color on focus
      },
    },
    "& .MuiInputLabel-root": {
      "&.Mui-focused": {
        color: "#2dcd7a", // Label color on focus
      },
    },
  }


  return (
    <div className="searchForm" style={{ backgroundColor: "rgba(0,0,0,0.03)", padding: "30px 0px" }}>
      <Grid
        container
        sx={{ width: "90%", margin: "0px auto", }}
        gap={8}
      >
        <Grid item xs={12} sm={4}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  id='checkIn'
                  label='Check In*'
                  className='remoteResLeft'
                  disablePast
                  sx={style}
                  value={inOutDate[0]}
                  onChange={(newValue) => {
                    setInOutDate((prev) => ([newValue, prev[0]]))
                  }}
                />
              </LocalizationProvider></Grid>
            <Grid item xs={12} sm={6}> <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                id='checkOut'
                label='Check Out*'
                sx={style}
                value={inOutDate[1]}
                className='remoteResMiddle'
                minDate={dayjs(inOutDate[0]).add(1, 'day')}
                onChange={(newValue) => {
                  setInOutDate((prev) => ([prev[0], newValue]))
                }}
              />
            </LocalizationProvider></Grid>
          </Grid>

        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            label="Guests"
            value={summary}
            onClick={handleOpenMenu}
            readOnly
            variant="outlined"
            style={{ width: "100%" }}
            className="guestInFOrm"
            sx={{ cursor: "pointer" }}

          />
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleCloseMenu}
            className="searchForm"
          >
            <MenuItem disableRipple >
              <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
                <Typography style={{ minWidth: "70px" }}> Adults:</Typography>
                <IconButton onClick={() => setAdults(Math.max(0, adults - 1))}>
                  <RemoveIcon />
                </IconButton>
                <Typography sx={{ mx: 1 }}>{adults}</Typography>
                <IconButton onClick={() => setAdults(adults + 1)}>
                  <AddIcon />
                </IconButton>
              </Box>
            </MenuItem>
            <MenuItem disableRipple >
              <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
                <Typography style={{ minWidth: "70px" }}>Children:</Typography>
                <IconButton
                  onClick={() => setChildren(Math.max(0, children - 1))}
                >
                  <RemoveIcon />
                </IconButton>
                <Typography sx={{ mx: 1 }}>{children}</Typography>
                <IconButton onClick={() => setChildren(children + 1)}>
                  <AddIcon />
                </IconButton>
              </Box>
            </MenuItem>
            <MenuItem disableRipple >
              <FormControlLabel
                control={<Checkbox checked={pets} onChange={handleTogglePet} />}
                label="Any Pets?"
              />
            </MenuItem>
            <MenuItem disableRipple className="remoteReservationList">
              <Button variant="contained" className="remoteResButton" onClick={handleDone}>
                Done
              </Button>
            </MenuItem>
          </Menu>
        </Grid>
        <Grid item xs={12} sm={1}>
          <Button
            className="moreFilter"
            disableRipple
            onClick={handleOpenFilterMenu}
            style={{ marginTop: "7px", fontWeight: "bold", width: '100%', whiteSpace: "nowrap", }}
          >
            More Filters
          </Button>
          <Menu
            anchorEl={filterAnchorEl}
            open={Boolean(filterAnchorEl)}
            onClose={handleCloseFilterMenu}
            className="searchForm"

          >
            <div style={{ padding: "20px" }}>
              <Box sx={{ display: "flex", flexWrap: "wrap", width: "800px", marginBottom: "20px" }}>
                <div style={{ width: "100%", display: 'grid', gridTemplateColumns: "1fr 1fr 1fr", gap: "20px", marginBottom: "20px" }}>
                  <FormControl fullWidth style={{ width: "100%" }}>
                    <Typography>Site Type</Typography>
                    <Select
                      value={siteType}
                      onChange={(e) => setSiteType(e.target.value)}
                      displayEmpty
                      variant="outlined"
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left"
                        },
                        getContentAnchorEl: null
                      }}
                    >
                      <MenuItem value="Any">Any</MenuItem>
                      {sites.map((site, index) => (
                        <MenuItem key={index} value={site.name}>
                          {site.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl fullWidth style={{ width: "100%" }}>
                    <Typography>Rig Type</Typography>
                    <Select
                      value={rigType}
                      onChange={(e) => setRigType(e.target.value)}
                      displayEmpty
                      variant="outlined"
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left"
                        },
                        getContentAnchorEl: null
                      }}
                    >
                      {rigs.map((rig, index) => (
                        <MenuItem key={index} value={rig.name}>
                          {rig.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <TextField
                    label="Rig Length"
                    type="number"
                    value={rigLength}
                    onChange={(e) => setRigLength(e.target.value)}
                    fullWidth
                    variant="outlined"
                    style={{ marginTop: "22px", width: "100%" }}
                  />
                </div>
                <div style={{ width: "100%", display: 'grid', gridTemplateColumns: "1fr 1fr 1fr", gap: "20px" }} >
                  <TextField
                    label="# of Slides"
                    type="number"
                    value={slides}
                    onChange={(e) => setSlides(e.target.value)}
                    fullWidth
                    variant="outlined"
                  />
                  <TextField
                    label="Discount"
                    value={discount}
                    onChange={(e) => setDiscount(e.target.value)}
                    fullWidth
                    variant="outlined"
                  />
                  <TextField
                    label="Recommended By"
                    value={recommendedBy}
                    onChange={(e) => setRecommendedBy(e.target.value)}
                    fullWidth
                    variant="outlined"
                  />
                </div>
              </Box>
              <Box sx={{ display: "flex", flexWrap: "wrap", width: "800px", marginBottom: "20px" }} className="searchForm">
                <FormControlLabel
                  control={
                    <Checkbox checked={wifi} onChange={() => setWifi(!wifi)} />
                  }
                  label="Wifi"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={extraPerson}
                      onChange={() => setExtraPerson(!extraPerson)}
                    />
                  }
                  label="Extra Person"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={boatSlip}
                      onChange={() => setBoatSlip(!boatSlip)}
                    />
                  }
                  label="Boat Slip"
                />
                <FormControlLabel control={<Checkbox />} label={`Extra`} />
              </Box>
              <div style={{ display: 'flex', justifyContent: 'flex-end' }} className="remoteReservationList">
                <Button className="remoteResButton" style={{ fontWeight: "bold", marginTop: "7px", width: "150px" }} onClick={handleCloseFilterMenu}>
                  Apply Filters
                </Button>
              </div>
            </div>
          </Menu>
        </Grid>
        <Grid item xs={12} sm={1}>
          <Button
            onClick={handleSearch}
            className="remoteResButton"
            style={{ fontWeight: "bold", marginTop: "7px", width: "100%", }}
          >
            Search
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}

export default SearchForm;
