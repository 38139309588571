import React, { Component } from 'react';
import { Grid, Button, Typography, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@material-ui/core';
import '../CSS/Settings.css';
import { deleteRecord, getSeasons, createNewSeason, updateSeason } from '../utils/OpenCampground_Database_Calls';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { Dialog, DialogContent } from '@mui/material';
const theme = createTheme({
    palette: {
        primary: {
            main: '#2dcd7a',
        },
    },
});

class Seasons extends Component {
    constructor(props) {
        super(props);
        this.state = {
            seasons: [],
            seasonsUpdate: false,
            addNew: false,
            seasonName: '',
            startDate: null,
            endDate: null,
            appliesToWeek: true,
            appliesToMonth: true,
            active: true,
            formValid: false,
            editMode: null,
        };
    }

    handleChange = (event) => {
        if (event.target.id === "appliesToWeek" || event.target.id === "appliesToMonth") {
            this.setState({
                [event.target.id]: event.target.checked
            });
        } else {
            this.setState({
                [event.target.id]: event.target.value
            }, () => {
                this.formValidation();
            });
        }
    }

    formValidation = () => {
        if (this.state.seasonName && this.state.startDate && this.state.endDate) {
            this.setState({
                formValid: true
            });
        } else if (this.state.formValid) {
            this.setState({
                formValid: false
            });
        }
    }

    async componentDidMount() {
        this.seasons();
    }

    formatDate = (dateString) => {
        const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
        return new Date(dateString).toISOString().split('T')[0];
    }

    deleteRecord = async (data) => {
        await deleteRecord(this.props.serverName, "seasons", data.id);
        this.setState({
            seasonsUpdate: true
        });
        this.seasons();
    }

    seasons = async () => {
        if (this.state.seasonsUpdate) {
            let getSeasonsData = await getSeasons(this.props.serverName);
            this.setState({
                seasons: getSeasonsData
            });
        } else {
            if (this.state.seasons.length === 0) {
                let getSeasonsData = await getSeasons(this.props.serverName);
                this.setState({
                    seasons: getSeasonsData
                });
            }
        }
    }

    addNew = async () => {
        await createNewSeason(
            this.props.serverName,
            this.state.seasonName,
            this.state.startDate.format('YYYY-MM-DD'),
            this.state.endDate.format('YYYY-MM-DD'),
            this.state.appliesToWeek ? "1" : "0",
            this.state.appliesToMonth ? "1" : "0",
            this.state.active ? "1" : "0"
        );
        this.setState({
            seasonsUpdate: true,
            addNew: false
        });
        this.seasons();
    }

    handleEdit = (season) => {
        this.setState({
            editMode: season.id,
            seasonName: season.name,
            startDate: dayjs(season.startdate),
            endDate: dayjs(season.enddate),
            appliesToWeek: season.applies_to_weekly,
            appliesToMonth: season.applies_to_monthly,
            active: season.active,
        });
    }

    handleSave = async () => {
        await updateSeason(
            this.props.serverName,
            this.state.seasonName,
            this.state.startDate.format('YYYY-MM-DD'),
            this.state.endDate.format('YYYY-MM-DD'),
            this.state.appliesToWeek ? "1" : "0",
            this.state.appliesToMonth ? "1" : "0",
            this.state.active ? "1" : "0",
            this.state.editMode
        );
        this.setState({
            seasonName: '',
            startDate: null,
            endDate: null,
            appliesToWeek: true,
            appliesToMonth: true,
            active: true,
            editMode: null,
            seasonsUpdate: true,
        });
        this.seasons();
    }

    render() {
        return (
            <ThemeProvider theme={theme}>
                <Button className='resButton' onClick={() => { this.setState({ addNew: true }) }} style={{ backgroundColor: '#2dcd7a', color: '#000000', marginBottom: '2%', textTransform: "capitalize" }}>Add New Season</Button>
                {this.state.addNew || this.state.editMode
                    ?
                    <Dialog open={this.state.addNew || this.state.editMode} onClose={() => {
                        this.setState({
                            addNew: false,
                            seasonName: '',
                            startDate: null,
                            endDate: null,
                            appliesToWeek: true,
                            appliesToMonth: true,
                            active: true,
                            editMode: null,
                        });
                    }} maxWidth="sm" fullWidth>
                        <DialogContent dividers>
                            <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
                                <h2 style={{ margin: "0px", marginBottom: "10px" }}>{this.state.editMode ? "Update Season" : "Add Season"}</h2>
                                <div style={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
                                    <TextField
                                        id="seasonName"
                                        required
                                        label="Season Name"
                                        value={this.state.seasonName}
                                        onChange={this.handleChange}
                                    />
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                            id='startDate'
                                            label='Start Date'
                                            value={this.state.startDate}
                                            onChange={(date) => {
                                                this.setState({ startDate: date });
                                            }}
                                        />
                                    </LocalizationProvider>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                            id='endDate'
                                            label='End Date'
                                            value={this.state.endDate}
                                            onChange={(date) => {
                                                this.setState({ endDate: date });
                                            }}
                                        />
                                    </LocalizationProvider>
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
                                    <FormControlLabel
                                        label={"Applies to weekly"}
                                        control={
                                            <Checkbox
                                                id="appliesToWeek"
                                                checked={this.state.appliesToWeek}
                                                onChange={this.handleChange}
                                            />
                                        }
                                    />
                                    <FormControlLabel
                                        label={"Applies to monthly"}
                                        control={
                                            <Checkbox
                                                id="appliesToMonth"
                                                checked={this.state.appliesToMonth}
                                                onChange={this.handleChange}
                                            />
                                        }
                                    />
                                    <FormControlLabel
                                        label={"Active"}
                                        control={
                                            <Checkbox
                                                id="active"
                                                checked={this.state.active}
                                                onChange={(event) => this.setState({ active: event.target.checked })}
                                            />
                                        }
                                    />
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '16px' }}>
                                    <Button
                                        style={{ backgroundColor: '#2dcd7a', color: '#000000' }}
                                        onClick={this.state.editMode ? this.handleSave : this.addNew}
                                        disabled={this.state.editMode ? false : !this.state.formValid}
                                    >
                                        {this.state.editMode ? "Update" : "Add"}
                                    </Button>
                                    <Button
                                        style={{ backgroundColor: '#FDECEB', color: '#DB3737' }}
                                        onClick={() => {
                                            this.setState({
                                                addNew: false, editMode: null,
                                                seasonName: '',
                                                startDate: null,
                                                endDate: null,
                                                appliesToWeek: true,
                                                appliesToMonth: true,
                                                active: true,
                                            })
                                        }}
                                    >
                                        Cancel
                                    </Button>
                                </div>
                            </div>
                        </DialogContent>
                    </Dialog>
                    : ""}
                <TableContainer component={Paper} style={{ width: '90%', margin: '0 auto' }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell className='tableCell'>Name</TableCell>
                                <TableCell className='tableCell'>Start Date</TableCell>
                                <TableCell className='tableCell'>End Date</TableCell>
                                <TableCell className='tableCell'>Applies to Weekly</TableCell>
                                <TableCell className='tableCell'>Applies to Monthly</TableCell>
                                <TableCell className='tableCell'>Active</TableCell>
                                <TableCell className='tableCell'>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.seasons.map((season) => (
                                <TableRow key={season.id}>
                                    <TableCell className='tableCell'>
                                        {
                                            season.name
                                        }
                                    </TableCell>
                                    <TableCell className='tableCell'>
                                        {
                                            season.startdate
                                        }
                                    </TableCell>
                                    <TableCell className='tableCell'>
                                        {
                                            season.enddate
                                        }
                                    </TableCell>
                                    <TableCell className='tableCell'>
                                        {
                                            season.applies_to_weekly ? 'Yes' : 'No'
                                        }
                                    </TableCell>
                                    <TableCell className='tableCell'>
                                        {
                                            season.applies_to_monthly ? 'Yes' : 'No'
                                        }
                                    </TableCell>
                                    <TableCell className='tableCell'>
                                        {
                                            season.active ? 'Yes' : 'No'
                                        }
                                    </TableCell>
                                    <TableCell className='tableCell'>
                                        <div>
                                            <Button
                                                className='viewButtons'
                                                onClick={() => this.handleEdit(season)}
                                                style={{ backgroundColor: '#d4d4d4', color: 'black' }}
                                            >
                                                Edit
                                            </Button>
                                            <Button
                                                className='viewButtons'
                                                onClick={() => this.deleteRecord(season)}
                                            >
                                                Delete
                                            </Button>
                                        </div>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </ThemeProvider>
        );
    }
}

export default Seasons;
