import React, { Component } from 'react';
import { Grid, Button, Typography, Box } from '@material-ui/core';
import '../CSS/NewReservation.css';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import siteSelect from '../assets/site_select.png'
import NewReservationUserDetails from './NewReservationUserDetails'
const theme = createTheme({
    palette: {
        primary: {
            main: '#2dcd7a',
        },
    },
});

class NewReservationSpaceSelect extends Component {
    constructor(props) {
        super(props);
        this.state = {
            //view changing variables
            selectSpace: true,
            userDetails: false,

            //selected space
            spaceName: '',
            siteType: '',
            siteId: '',
            daily: '',
            weekly: '',
            monthly: '',
        };
    }

    async componentDidMount() {
    }

    handleSelect = async (space) => {
        console.log("Booking space:", space);
        this.setState({
            spaceName: space.space_name,
            siteType: space.sitetype_name,
            daily: space.daily_rate,
            weekly: space.weekly_rate,
            monthly: space.monthly_rate,
            siteId: space.id,
            selectSpace: false,
            userDetails: true,
        })
    }
    backToSelectSpace = () => {
        this.setState({
            selectSpace: true,
            userDetails: false,
        })
    }

    render() {
        const spaceRows = this.props.spaceData.map((space, index) => (
            <tr key={index} className='rows'>
                <td className='headerTypo'>{space.space_name}</td>
                <td className='headerTypo'>{space.sitetype_name}</td>
                {/* <td className='headerTypo'>{space.electric ? 'Electric' : 'No Electric'}</td> */}
                <td className='headerTypo'>
                    {space.power_50a ? 'Electric (50A)' : space.power_30a ? 'Electric (30A)' : space.power_15a ? 'Electric (15A)' : 'No Electric'}
                </td>
                <td className='headerTypo'>{space.water ? 'Water' : 'No Water'}</td>
                <td className='headerTypo'>{space.sewer ? 'Sewer' : 'No Sewer'}</td>
                <td className='headerTypo'>${space.daily_rate}</td>
                <td className='headerTypo'>${space.weekly_rate}</td>
                <td className='headerTypo'>${space.monthly_rate}</td>
                <td>
                    <Button
                        className='selectButton'
                        onClick={() => this.handleSelect(space)}
                        style={{ backgroundColor: '#2dcd7a', color: '#000000', padding: '2% 10% 2% 10%', borderRadius: '10px', textTransform: "capitalize" }}
                    >
                        Select
                    </Button>
                </td>
            </tr>
        ));
        return (
            <Grid className='reservationList' style={{ width: '100%' }}>
                {this.state.selectSpace
                    ?
                    <>
                        <Box className='box'>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <img src={siteSelect} alt="Reservations" style={{ height: '40px', marginRight: '8px' }} />
                                <Typography className='reservationstypography'>Select Site</Typography>
                            </div>
                            <Button className='cancel' onClick={() => { this.props.history.replace(`/manager?serverName=${this.props.serverName}&view=reservations`) }}>Cancel Reservation</Button>
                        </Box>

                        <Box className='numberofresultsbox'>
                            <Typography className='typography'>{this.props.spaceData.length} Spaces Available</Typography>
                        </Box>


                        <Box className='spaceHeadingBox'>
                            <Typography className='headerTypo'>Space Name</Typography>
                            <Typography className='headerTypo'>Site Type</Typography>
                            <Typography className='headerTypo'>Electric</Typography>
                            <Typography className='headerTypo'>Water</Typography>
                            <Typography className='headerTypo'>Sewer</Typography>
                            <Typography className='headerTypo'>Daily</Typography>
                            <Typography className='headerTypo'>Weekly</Typography>
                            <Typography className='headerTypo'>Monthly</Typography>
                        </Box>
                        <Box className='scrollContainer'>
                            <Box className='spacetableBox'>
                                <table>
                                    <tbody>
                                        {spaceRows}
                                    </tbody>
                                </table>
                            </Box>
                        </Box>
                    </>
                    : <NewReservationUserDetails sites={this.props.sites} rigs={this.props.rigs} extras={this.props.extras}
                        checkIn={this.props.checkIn} checkOut={this.props.checkOut} cancelReservation={this.props.cancelReservation}
                        guests={this.props.guests} rigType={this.props.rigType} pets={this.props.pets} rigLength={this.props.rigLength}
                        slides={this.props.slides} children={this.props.children} serverName={this.props.serverName} spaceData={this.props.spaceData}
                        discount={this.props.discount} dailyDiscount={this.props.dailyDiscount} weeklyDiscount={this.props.weeklyDiscount}
                        monthlyDiscount={this.props.monthlyDiscount} seasonalDiscount={this.props.seasonalDiscount} discountPercent={this.props.discountPercent}
                        discountId={this.props.discountId} spaceName={this.state.spaceName} siteId={this.state.siteId} daily={this.state.daily} weekly={this.state.weekly}
                        monthly={this.state.monthly} siteType={this.state.siteType} backToSelectSpace={this.backToSelectSpace} />

                }
            </Grid>
        )
    };
};

export default withRouter(NewReservationSpaceSelect);