import React, { Component } from 'react';
import { Grid, Button, Typography, Dialog, DialogActions, DialogContent, DialogTitle, Box } from '@material-ui/core';
import TextField from '@mui/material/TextField';
import LandingPage from './LandingPage';
import * as QueryString from "query-string";
import '../CSS/Login.css';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { login, getSuspendedStatus, getUserAddedStatus, sendMail, changePassword } from '../utils/OpenCampground_Database_Calls'
import Graphic_Side from '../assets/Graphic_Side.png'
import logo from '../assets/Logo.png'
import { withStyles } from '@material-ui/core/styles';
import { CircularProgress } from '@material-ui/core';

const useStyles = theme => ({
    circularProgress: {
        position: 'absolute',
        left: '55%',
        top: '50%',
        color: '#2dcd7a'
    },
    dialogPaper: {
        padding: theme.spacing(2),
        borderRadius: '10px',
    }
});

const theme = createTheme({
    palette: {
        primary: {
            main: '#2dcd7a',
        },
    }
});

class Login extends Component {

    constructor(props) {
        super(props);
        this.state = {

            serverName: '',
            userName: '',
            password: '',
            admin: '',
            user: '',

            //forgot password
            forgotPasswordEmail: '',
            verificationCode: '',
            masterCode: '',
            confirmNewPassword: '',
            newPassword: '',
            userId: '',

            //form validations
            formValid: false,
            forgotPasswordForm1Valid: false,
            forgotPasswordForm2Valid: false,
            forgotPasswordForm3Valid: false,

            //ui changes
            login: false,
            forgotPassword: false,
            verificationCodeSent: false,
            resetPassword: false,
            status: null,

            //poput
            open: false,
            popupText: '',
            open1: false,
            popupText1: '',
        };
    }

    async componentDidMount() {
        const params = QueryString.parse(this.props.location.search);
        let suspendedResponse = await getSuspendedStatus(params.serverName)
        if (suspendedResponse.body[0].suspended == 1) {
            console.log("suspended")
            this.setState({
                serverName: params.serverName,
                status: "suspended"
            })
        } else {
            this.setState({
                serverName: params.serverName,
                status: "operational"
            })
        }
    }

    handleChange = (event) => {
        this.setState({
            [event.target.id]: event.target.value
        }, () => {
            this.formValidation();
        })
    }

    formValidation = () => {
        if (this.state.forgotPassword) {
            if (this.state.resetPassword) {
                const specialCharacters = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
                if ((this.state.newPassword === this.state.confirmNewPassword) && /\d/.test(this.state.newPassword) &&
                    specialCharacters.test(this.state.newPassword) && this.state.newPassword.length > 7 && /[A-Z]/.test(this.state.newPassword)) {
                    this.setState({
                        forgotPasswordForm3Valid: true
                    })
                } else if (this.state.formValid) {
                    this.setState({
                        forgotPasswordForm3Valid: false
                    })
                }
            } else if (this.state.verificationCodeSent) {
                //step 2
                if (this.state.verificationCode) {
                    this.setState({
                        forgotPasswordForm2Valid: true
                    })
                } else if (this.state.formValid) {
                    this.setState({
                        forgotPasswordForm2Valid: false
                    })
                }
            } else {
                //step 1
                if (this.state.forgotPasswordEmail) {
                    this.setState({
                        forgotPasswordForm1Valid: true
                    })
                } else if (this.state.formValid) {
                    this.setState({
                        forgotPasswordForm1Valid: false
                    })
                }
            }
        } else {
            if (this.state.userName && this.state.password) {
                this.setState({
                    formValid: true
                })
            } else if (this.state.formValid) {
                this.setState({
                    formValid: false
                })
            }
        }
    }

    login = async () => {
        this.setState({
            formValid: false
        })
        let loginResponse = await login(this.state.serverName, this.state.userName, this.state.password)
        console.log("response: ", loginResponse)
        if (loginResponse.statusCode === 404) {
            this.setState({
                open: true,
                popupText: 'User not Found'
            })
        } else if (loginResponse.statusCode === 401) {
            this.setState({
                open: true,
                popupText: 'Invalid User Name or Password'
            })
        } else {
            this.setState({
                admin: loginResponse.body[0].admin,
                user: loginResponse.body[0].name,
                login: true
            })
        }
    }

    goBack = () => {
        this.setState({
            login: false,
            userName: '',
            password: '',
        });
    }

    sendVerificationCode = async () => {
        this.setState({
            verificationCodeSent: true
        })
        let getUserAddedStatusResponse = await getUserAddedStatus(this.state.serverName, this.state.forgotPasswordEmail)
        if (getUserAddedStatusResponse.user_id > 0) {
            const pin = Math.floor(100000 + Math.random() * 900000);
            let mailmessage = pin + " is the verification code to change your password for " + this.state.forgotPasswordEmail + "."
            let mailResponse = await sendMail(this.props.serverName, 'support@opencampground.com', this.state.forgotPasswordEmail, "Verification Pin", mailmessage, "", "", "")
            this.setState({
                masterCode: pin,
                userId: getUserAddedStatusResponse.user_id
            })
        }
    }

    verifyCode = async () => {
        if (parseInt(this.state.verificationCode) === parseInt(this.state.masterCode)) {
            this.setState({
                verificationCodeSent: false,
                resetPassword: true,
                open1: true,
                popupText1: 'OTP Verification Successful'
            })
        } else {
            this.setState({
                open1: true,
                popupText1: 'OTP Verification Failed!'
            })
        }

    }

    changePassword = async () => {
        let changePasswordResponse = await changePassword(this.state.serverName, this.state.userId, this.state.newPassword)
        if (changePasswordResponse.statusCode === 200) {
            this.setState({
                forgotPassword: false,
                resetPassword: false,
                open1: true,
                popupText1: 'Password Changed Successfully'
            })
        } else {
            this.setState({
                open1: true,
                popupText1: 'Password Cannot Be Changed. Please Try again'
            })
        }
    }

    render() {
        const { classes } = this.props;
        const params = QueryString.parse(this.props.location.search);
        if (params.serverName) {
            if (this.state.login) {
                return (
                    <LandingPage serverName={params.serverName} admin={this.state.admin} goBack={this.goBack} user={this.state.user} />
                );
            } else if (this.state.status === "operational") {
                return (
                    <ThemeProvider theme={theme}>
                        <Dialog open={this.state.open} onClose={() => { this.setState({ open: false }) }} classes={{ paper: classes.dialogPaper }}>
                            <DialogTitle>Popup Title</DialogTitle>
                            <DialogContent>
                                {this.state.popupText}
                            </DialogContent>
                            <DialogActions style={{ display: 'flex', justifyContent: 'space-around' }}>
                                <Button onClick={() => { this.setState({ open: false }) }} style={{ backgroundColor: '#2dcd7a', color: '#ffffff', padding: '2% 10% 2% 10%', margin: '0 0 0 2%', borderRadius: '10px', marginTop: '3%' }}>
                                    Retry
                                </Button>
                            </DialogActions>
                        </Dialog>
                        <Dialog open={this.state.open1} onClose={() => { this.setState({ open: false }) }} classes={{ paper: classes.dialogPaper }}>
                            <DialogTitle>Popup Title</DialogTitle>
                            <DialogContent>
                                {this.state.popupText1}
                            </DialogContent>
                            <DialogActions style={{ display: 'flex', justifyContent: 'space-around' }}>
                                <Button onClick={() => { this.setState({ open1: false }) }} style={{ backgroundColor: '#2dcd7a', color: '#ffffff', padding: '2% 10% 2% 10%', margin: '0 0 0 2%', borderRadius: '10px', marginTop: '3%' }}>
                                    Cancel
                                </Button>
                            </DialogActions>
                        </Dialog>
                        <Grid container className='loginRoot'>
                            <Grid item className='leftPane'>
                                <div className='loginContent'>
                                    <img src={logo} alt="Logo" className='logo' />
                                    {this.state.forgotPassword
                                        ?
                                        <>
                                            <Typography className='signInTypo'>Reset Password</Typography>
                                            {!this.state.verificationCodeSent && !this.state.resetPassword
                                                ?
                                                <>
                                                    <div className='inputField'>
                                                        <Typography className='typo'>Email Id</Typography>
                                                        <TextField id="forgotPasswordEmail" fullWidth onChange={this.handleChange} />
                                                    </div>
                                                    <div className='buttonField'>
                                                        <Button  style={{whiteSpace:'nowrap'}} className='signInButton' fullWidth disabled={!this.state.forgotPasswordForm1Valid} onClick={this.sendVerificationCode}>Send Verification Code</Button>
                                                    </div>
                                                </>
                                                : null
                                            }
                                            {this.state.verificationCodeSent
                                                ?
                                                <>
                                                    <div className='inputField'>
                                                        <Typography className='typo'>Verification Code</Typography>
                                                        <TextField id="verificationCode" fullWidth onChange={this.handleChange} />
                                                    </div>
                                                    <div className='buttonField'>
                                                        <Button className='signInButton' fullWidth disabled={!this.state.forgotPasswordForm2Valid} onClick={this.verifyCode}>Verify Code</Button>
                                                    </div>
                                                </>
                                                : null
                                            }
                                            {this.state.resetPassword
                                                ?
                                                <>
                                                    <div className='inputField'>
                                                        <Typography className='typo'>Password</Typography>
                                                        <TextField id="newPassword" type="password" fullWidth onChange={this.handleChange} />
                                                    </div>
                                                    <div className='inputField'>
                                                        <Typography className='typo'>Confirm Password</Typography>
                                                        <TextField id="confirmNewPassword" type="password" fullWidth onChange={this.handleChange} />
                                                    </div>
                                                    <div className='passwordRules'>
                                                        <Typography>Password Rules:</Typography>
                                                        <Typography>- Password length should be greater than 8</Typography>
                                                        <Typography>- Password should contain at least 1 uppercase letter</Typography>
                                                        <Typography>- Password should contain at least 1 lowercase letter</Typography>
                                                        <Typography>- Password should contain at least 1 number</Typography>
                                                        <Typography>- Password should contain at least 1 special character</Typography>
                                                    </div>
                                                    <div className='buttonField'>
                                                        <Button className='signInButton' fullWidth disabled={!this.state.forgotPasswordForm3Valid} onClick={this.changePassword}>Change Password</Button>
                                                    </div>
                                                </>
                                                : null
                                            }
                                            <div className='signIn'  style={{whiteSpace:'nowrap'}} onClick={() => { this.setState({ forgotPassword: false, verificationCodeSent: false, resetPassword: false }) }}>
                                                <Typography className='forgotPasswordTypo'> Back to Sign In</Typography>
                                            </div>
                                            <div className='signUp'>
                                                <Typography  style={{whiteSpace:'nowrap'}}>Don't have an account,&nbsp;
                                                    <a href="https://opencampground.com/pricing/" target="_blank">
                                                        sign up
                                                    </a>
                                                    &nbsp;here
                                                </Typography>
                                            </div>
                                        </>
                                        :
                                        <>
                                            <Typography className='signInTypo'>Sign In</Typography>
                                            <div className='inputField'>
                                                <Typography className='typo'>Email Id</Typography>
                                                <TextField id="userName" fullWidth onChange={this.handleChange} />
                                            </div>
                                            <div className='inputField'>
                                                <Typography className='typo'>Password</Typography>
                                                <TextField id="password" type="password" fullWidth onChange={this.handleChange}
                                                    onKeyDown={(e) => {
                                                        if (e.key === 'Enter') {
                                                            this.login();
                                                        }
                                                    }}
                                                />
                                            </div>
                                            <div className='inputField forgotPasswordDiv' onClick={() => { this.setState({ forgotPassword: true }) }}>
                                                <Typography  style={{whiteSpace:'nowrap'}} className='typo forgotPasswordTypo'>Forgot Password</Typography>
                                            </div>
                                            <div className='buttonField'>
                                                <Button className='signInButton' fullWidth disabled={!this.state.formValid} onClick={this.login}>Sign In</Button>
                                            </div>
                                            <div className='signUp'>
                                                <Typography  style={{whiteSpace:'nowrap'}} >Don't have an account,&nbsp;
                                                    <a href="https://opencampground.com/pricing/" target="_blank">
                                                        sign up
                                                    </a>
                                                    &nbsp;here
                                                </Typography>
                                            </div>
                                        </>
                                    }

                                </div>
                            </Grid>
                            <Grid item className='rightPane'>
                                <img src={Graphic_Side} alt="Login Image" className='loginImage' />
                            </Grid>
                        </Grid>
                    </ThemeProvider>
                );
            } else if (this.state.status === "suspended") {
                return (
                    <Box style={{ backgroundColor: '#f5f5f5', minHeight: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Box style={{ backgroundColor: '#ffffff', padding: '2%', borderRadius: '8px', boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)' }}>
                            <Typography style={{ color: 'red', fontSize: '2rem', marginBottom: '10px' }}>
                                Your account has been temporarily suspended due to nonpayment.
                            </Typography>
                            <Typography style={{ color: 'red', fontSize: '1.5rem' }}>
                                Please ensure that your subscription is up to date. If you have any questions, please contact billing@opencampground.com
                            </Typography>
                        </Box>
                    </Box>
                );
            } else {
                return (
                    <CircularProgress className={classes.circularProgress} />
                );
            }
        }
    };
};

export default withStyles(useStyles)(Login);