import React, { Component, createRef } from 'react';
import { Button, Typography, Box, Grid } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import '../CSS/Transactions.css';

const theme = createTheme({
    palette: {
        primary: {
            main: '#2dcd7a',
        },
    },
});

class Print extends Component {
    constructor(props) {
        super(props);
        this.printRef = createRef();
    }

    handlePrint = () => {
        const printContent = this.printRef.current.innerHTML;
        const printWindow = window.open('', '', 'width=1200,height=650');

        printWindow.document.write(`
        <html>
            <head>
                <title>Print Receipt</title>
                <link rel="stylesheet" type="text/css" href="${window.location.origin}/CSS/Transactions.css">
                <style>
                    body {
                        font-family: Arial, sans-serif;
                    }
                    .detailsGrid {
                        display: flex;
                        flex-wrap: wrap;
                    }
                    .detailsItemXs4, .detailsItemXs8 {
                        box-sizing: border-box;
                    }
                    .detailsItemXs4 {
                        flex: 0 0 33.3333%; 
                        max-width: 33.3333%;
                    }
                    .detailsItemXs8 {
                        flex: 0 0 66.6667%; 
                        max-width: 66.6667%;
                    }
                    .detailsTypo {
                        margin: 2% 0 2% 27%;
                    }
                    .detailsHeading {
                        margin: 2% 0 2% 5%;
                    }
                    .mainHeading {
                        margin: 2% 0 2% 5%;
                        align: center
                    }
                    .printLine {
                        width: 90%;
                    }
                </style>
            </head>
            <body>${printContent}</body>
        </html>
    `);

        printWindow.document.close();
        printWindow.focus();
        printWindow.print();
        printWindow.close();
    }

    render() {
        const { printItem } = this.props;

        return (
            <ThemeProvider theme={theme}>
                <Grid className='transactionsList'>
                    <Box className='infoBox'>
                        <Typography className='infoBarTypo'>
                            Welcome: {this.props.serverName.charAt(0).toUpperCase() + this.props.serverName.slice(1)}
                        </Typography>
                        <Typography className='infoBarTypo'>
                            Username: {this.props.user}
                        </Typography>
                    </Box>

                    <Grid container direction="row" justifyContent="center">
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={this.props.goBack}
                            style={{ marginTop: '3%' }}
                        >
                            Go Back
                        </Button>

                        <Button
                            variant="contained"
                            color="primary"
                            onClick={this.handlePrint}
                            style={{ marginTop: '3%', marginLeft: '54%' }}
                        >
                            Print
                        </Button>
                    </Grid>

                    <Grid container direction="column" alignItems="center" ref={this.printRef}>
                        <Box
                            ref={this.printRef}
                            className="printBox"
                        >
                            <Typography className='mainHeading' align="center">
                                Receipt for Reservation Number {printItem.reservation_id}
                            </Typography>
                            <hr className='printLine' />

                            <Typography className='detailsHeading'>
                                Your Details
                            </Typography>
                            <Grid container spacing={2} className='detailsGrid'>
                                <Grid item className='detailsItemXs4'>
                                    <Typography className='detailsTypo'>Name</Typography>
                                </Grid>
                                <Grid item className='detailsItemXs8'>
                                    <Typography className='detailsTypo'>
                                        {printItem.first_name.charAt(0).toUpperCase() + printItem.first_name.slice(1)}{' '}
                                        {printItem.last_name.charAt(0).toUpperCase() + printItem.last_name.slice(1)}
                                    </Typography>
                                </Grid>
                                <Grid item className='detailsItemXs4'>
                                    <Typography className='detailsTypo'>Email Address</Typography>
                                </Grid>
                                <Grid item className='detailsItemXs8'>
                                    <Typography className='detailsTypo'>{printItem.email}</Typography>
                                </Grid>
                            </Grid>

                            <hr className='printLine' />

                            <Typography className='detailsHeading'>
                                Reservation Details
                            </Typography>
                            <Grid container spacing={2} className='detailsGrid'>
                                <Grid item className='detailsItemXs4'>
                                    <Typography className='detailsTypo'>Reservation Number</Typography>
                                </Grid>
                                <Grid item className='detailsItemXs8'>
                                    <Typography className='detailsTypo'>{printItem.reservation_id}</Typography>
                                </Grid>
                                <Grid item className='detailsItemXs4'>
                                    <Typography className='detailsTypo'>Date</Typography>
                                </Grid>
                                <Grid item className='detailsItemXs8'>
                                    <Typography className='detailsTypo'>{printItem.startDate}</Typography>
                                </Grid>
                                <Grid item className='detailsItemXs4'>
                                    <Typography className='detailsTypo'>Space/ Site Name</Typography>
                                </Grid>
                                <Grid item className='detailsItemXs8'>
                                    <Typography className='detailsTypo'>{printItem.startDate}</Typography> 
                                </Grid>
                                <Grid item className='detailsItemXs4'>
                                    <Typography className='detailsTypo'>Payment Method</Typography>
                                </Grid>
                                <Grid item className='detailsItemXs8'>
                                    <Typography className='detailsTypo'>{printItem.card_type}</Typography>
                                </Grid>
                                <Grid item className='detailsItemXs4'>
                                    <Typography className='detailsTypo'>Card Number</Typography>
                                </Grid>
                                <Grid item className='detailsItemXs8'>
                                    <Typography className='detailsTypo'>**** {printItem.credit_card_no}</Typography>
                                </Grid>
                                <Grid item className='detailsItemXs4'>
                                    <Typography className='detailsTypo'>Memo</Typography>
                                </Grid>
                                <Grid item className='detailsItemXs8'>
                                    <Typography className='detailsTypo'>{printItem.memo}</Typography>
                                </Grid>
                                <Grid item className='detailsItemXs4'>
                                    <Typography className='detailsTypo'>Payment Date</Typography>
                                </Grid>
                                <Grid item className='detailsItemXs8'>
                                    <Typography className='detailsTypo'>{printItem.pmt_date}</Typography>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>
            </ThemeProvider>
        );
    }
}

export default Print;