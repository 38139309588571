import React, { Component } from 'react';
import { Container, Grid, Button, Typography, Box } from '@material-ui/core';
import '../CSS/RemoteSpaceSelect.css';
import SearchForm from './SearchForm';
import ReservationTabs from './ReservationTabs';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import RemoteUserDetails from './RemoteUserDetails';
import { Diversity1Sharp } from '@mui/icons-material';
const theme = createTheme({
    palette: {
        primary: {
            main: '#2dcd7a',
        },
    },
});

class RemoteSpaceSelect extends Component {
    constructor(props) {
        super(props);
        this.state = {

            //view changing variables
            selectSpace: true,
            userDetails: false,

            //selected space
            spaceName: '',
            siteType: '',
            siteId: '',
            daily: '',
            weekly: '',
            monthly: '',
        };
    }

    handleSelect = async (space) => {
        console.log("Booking space:", space);
        this.setState({
            spaceName: space.space_name,
            siteType: space.sitetype_name,
            daily: space.daily_rate,
            weekly: space.weekly_rate,
            monthly: space.monthly_rate,
            siteId: space.id,
            selectSpace: false,
            userDetails: true,
        })
    }

    backToSelectSpace = () => {
        this.setState({
            selectSpace: true,
            userDetails: false,
        })
    }

    render() {
        return (
            <Grid className='remoteSpaceSelect'>
                {this.state.selectSpace
                    ?
                    <>
                        <Box>
                            <SearchForm checkIn={this.props.checkIn} checkOut={this.props.checkOut}
                                guests={this.props.guests} rigType={this.props.rigType} pets={this.props.pets}
                                slides={this.props.slides} children={this.props.children} rigLength={this.props.rigLength}
                                sites={this.props.sites} rigs={this.props.rigs} serverName={this.props.serverName}
                                updateSpace={this.props.updateSpace} siteType={this.props.siteType} />
                        </Box>
                        <Box style={{ width: "90%", margin: "0px auto" }}>
                            <ReservationTabs spaceData={this.props.spaceData} bookSpace={this.handleSelect} facilities={this.props.facilities}
                                additionalDetails={this.props.additionalDetails} addressLine1={this.props.addressLine1} addressLine2={this.props.addressLine2}
                                addressLine3={this.props.addressLine3} rules={this.props.rules} changes={this.props.changes} cancellations={this.props.cancellations} />
                        </Box>
                    </>
                    : <RemoteUserDetails sites={this.props.sites} rigs={this.props.rigs} extras={this.props.extras}
                        checkIn={this.props.checkIn} checkOut={this.props.checkOut} guests={this.props.guests}
                        rigType={this.props.rigType} pets={this.props.pets} rigLength={this.props.rigLength}
                        slides={this.props.slides} children={this.props.children} spaceData={this.props.spaceData}
                        spaceName={this.state.spaceName} siteId={this.state.siteId} daily={this.state.daily}
                        weekly={this.state.weekly} monthly={this.state.monthly} siteType={this.state.siteType}
                        serverName={this.props.serverName} backToSelectSpace={this.backToSelectSpace}
                        allow={this.props.allow} require={this.props.require} />
                }
            </Grid >
        )
    };
};

export default RemoteSpaceSelect;